import Colors from '@/helpers/colors.js';

const SET_USER_PROFILE_COLOR = 'SET_USER_PROFILE_COLOR';
const SET_USER_SETTINGS = 'SET_USER_SETTINGS';
const SET_USER_SETTING = 'SET_USER_SETTING';

// state
const state = {
  userSettings: [],
  userProfileColor: Colors.getRandomColor(),
};

// getters
const getters = {
  userSettings: (state) => state.userSettings,
  userProfileColor: (state) => state.userProfileColor,
};

// actions
const actions = {
  updateUserSettings({ commit }, settings) {
    commit(SET_USER_SETTINGS, settings);
  },
  updateUserSetting({ commit }, { id, data }) {
    commit(SET_USER_SETTING, { id, data });
  },
  updateUserProfileColor({ commit }, { color }) {
    commit(SET_USER_PROFILE_COLOR, { color });
  },
};

// mutations
const mutations = {
  [SET_USER_SETTINGS](state, settings) {
    state.userSettings = settings;
  },
  [SET_USER_SETTING](state, { id, data }) {
    const index = state.userSettings.findIndex((setting) => setting.id === id);
    if (index !== -1) {
      state.userSettings[index].data = data;
    } else {
      state.userSettings.push({ id: id, data: data });
    }
  },
  [SET_USER_PROFILE_COLOR](state, { color }) {
    state.userProfileColor = color;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
