const PermissionsView = () => import('@/views/permissions/PermissionsView.vue');

const permissionRoutes = [
  {
    path: '/permissions/:roleCode',
    name: 'Permissions',
    components: { fullscreen: PermissionsView },
    meta: {
      breadcrumbs: ({ route, translate }) => {
        return [
          {
            name: translate('sidebar.users'),
            route: { name: 'UserView' },
          },
          {
            name:
              route.params.roleCode === 'new'
                ? translate('users.headerActions.addRole')
                : translate('users.editRole'),
          },
        ];
      },
    },
  },
];
export default permissionRoutes;
