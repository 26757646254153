const SET_SEARCH_DATA = 'SET_SEARCH_DATA';
const SET_BROWSER_STATE = 'SET_BROWSER_STATE';
const CLEAR_SEARCH_DATA = 'CLEAR_SEARCH_DATA';

// state
const state = {
  searchData: {},
  browserState: {
    back: false,
  },
};

// getters
const getters = {
  searchData: (state) => state.searchData,
  browserState: (state) => state.browserState,
};

// actions
const actions = {
  clearSearchData({ commit }, { view }) {
    commit(CLEAR_SEARCH_DATA, { view });
  },
  updateSearchData({ commit }, { searchData, view }) {
    commit(SET_SEARCH_DATA, { searchData, view });
  },
  updateBrowserState({ commit }, { data, key }) {
    commit(SET_BROWSER_STATE, { data, key });
  },
};

// mutations
const mutations = {
  [CLEAR_SEARCH_DATA](state, { view }) {
    state.searchData[view] = {};
  },
  [SET_SEARCH_DATA](state, { searchData, view }) {
    state.searchData[view] = { ...state.searchData[view], ...searchData };
  },
  [SET_BROWSER_STATE](state, { data, key }) {
    state.browserState[key] = data;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
