<script setup lang="ts">
import { onBeforeUnmount } from 'vue';
import { VueDraggableNext as draggable } from 'vue-draggable-next';

const handleDragStart = () => {
  document.body.style.userSelect = 'none';
};

const handleDragEnd = () => {
  document.body.style.userSelect = '';
};

onBeforeUnmount(() => {
  document.body.style.userSelect = '';
});
</script>

<template>
  <draggable @start="handleDragStart" @end="handleDragEnd">
    <slot></slot>
  </draggable>
</template>

<style scoped lang="scss"></style>
