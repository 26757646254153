<template>
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icons-sp"
        transform="translate(-983.000000, -701.000000)"
        fill="currentColor"
      >
        <g id="Group-Copy-35" transform="translate(961.000000, 679.000000)">
          <g id="grid" transform="translate(22.000000, 22.000000)">
            <g>
              <rect
                id="Rectangle"
                x="0"
                y="0"
                width="4.25"
                height="4.25"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-10"
                x="0"
                y="6.375"
                width="4.25"
                height="4.25"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-8"
                x="6.375"
                y="0"
                width="4.25"
                height="4.25"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-11"
                x="6.375"
                y="6.375"
                width="4.25"
                height="4.25"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-9"
                x="12.75"
                y="0"
                width="4.25"
                height="4.25"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-12"
                x="12.75"
                y="6.375"
                width="4.25"
                height="4.25"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-15"
                x="0"
                y="12.75"
                width="4.25"
                height="4.25"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-14"
                x="6.375"
                y="12.75"
                width="4.25"
                height="4.25"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-13"
                x="12.75"
                y="12.75"
                width="4.25"
                height="4.25"
                rx="1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
