<template>
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="left-align-icon" fill="#BABABA">
        <g id="Group-7">
          <rect id="Rectangle-Copy-3" x="0" y="0" width="17" height="2.5" />
          <rect id="Rectangle-Copy-3" x="0" y="7" width="11" height="2.5" />
          <rect id="Rectangle-Copy-3" x="0" y="14" width="6" height="2.5" />
        </g>
      </g>
    </g>
  </svg>
</template>
