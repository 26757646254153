<template>
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="start-hover-screens"
        transform="translate(-378.000000, -425.000000)"
        fill="currentColor"
      >
        <g id="Group" transform="translate(378.000000, 425.000000)">
          <circle id="Oval-Copy-8" cx="13.5" cy="1.5" r="1.5" />
          <circle id="Oval-Copy-11" cx="7.5" cy="1.5" r="1.5" />
          <circle id="Oval-Copy-9" cx="13.5" cy="7.5" r="1.5" />
          <circle id="Oval-Copy-12" cx="7.5" cy="7.5" r="1.5" />
          <circle id="Oval-Copy-10" cx="13.5" cy="13.5" r="1.5" />
          <circle id="Oval-Copy-13" cx="7.5" cy="13.5" r="1.5" />
          <circle id="Oval-Copy-16" cx="1.5" cy="1.5" r="1.5" />
          <circle id="Oval-Copy-15" cx="1.5" cy="7.5" r="1.5" />
          <circle id="Oval-Copy-14" cx="1.5" cy="13.5" r="1.5" />
        </g>
      </g>
    </g>
  </svg>
</template>
