export const waitForCondition = (
  conditionFn: () => unknown,
  timeout = 5000,
) => {
  return new Promise<void>((resolve, reject) => {
    const interval = setInterval(() => {
      if (conditionFn()) {
        clearInterval(interval);
        clearTimeout(timeoutId);
        resolve();
      }
    }, 100); // Check the condition every 100ms

    const timeoutId = setTimeout(() => {
      clearInterval(interval);
      reject(new Error('Condition not met within the specified time'));
    }, timeout); // Maximum waiting time
  });
};
