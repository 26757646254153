import { withAsync } from '@/helpers/withAsync';
import { removeEmptyNodes } from '@/helpers/filterHelper/filterHelper';

export const search = {
  beforeRouteLeave(to, from, next) {
    if (this.listElement) {
      this.$store.dispatch('updateSearchData', {
        searchData: {
          scrollTop: this.listElement.scrollTop,
        },
        view: this.$route.path,
      });
    }
    next();
  },
  data() {
    return {
      searchText: '',
      order: 'asc',
      orderBy: '',
      maxItemsInResult: 50,
      skipItemsInResult: 0,
      searchResult: null,
      condition: null,
      allItemsLoaded: false,
      loadingItems: false,
      searchFunction: () => {},
      itemsToGet: null,
      listElement: '',
    };
  },
  computed: {
    searchParameters() {
      return {};
    },
  },
  created() {
    const data = this.$store?.getters.searchData?.[this.$route.path];
    if (data) {
      this.order = data.order || this.order;
      this.orderBy = data.orderBy || this.orderBy;
    }
  },
  methods: {
    onSearch(searchText) {
      this.searchText = searchText;
      this.skipItemsInResult = 0;
      this.getItems();
    },
    onSavedSearch(data) {
      this.searchText = data?.searchText || this.searchText;
      this.order = data?.order || this.order;
      this.orderBy = data?.orderBy || this.orderBy;
      this.condition = data?.condition || this.condition;
      this.getItems();
    },
    loadMoreItems() {
      this.skipItemsInResult += this.maxItemsInResult;
      this.getItems();
    },
    onSort(orderBy) {
      this.skipItemsInResult = 0;
      if (this.orderBy === orderBy) {
        this.order = this.order == 'asc' ? 'desc' : 'asc';
      }
      this.orderBy = orderBy;
      this.getItems();
      this.$store.dispatch('updateSearchData', {
        searchData: {
          order: this.order,
          orderBy: this.orderBy,
        },
        view: this.$route.path,
      });
    },
    handleScroll() {
      if (this.$store?.getters.browserState?.back && this.listElement) {
        this.$nextTick(() => {
          this.listElement.scrollTop =
            this.$store?.getters.searchData?.[this.$route.path]?.scrollTop || 0;
          // Resets scrollTop
          this.$store.dispatch('updateSearchData', {
            searchData: {
              scrollTop: 0,
            },
            view: this.$route.path,
          });
        });
      }
    },
    async getItems() {
      this.allItemsLoaded = false;
      this.loadingItems = true;

      this.$options.abort?.();
      const { response } = await withAsync(
        this.searchFunction,
        {
          searchText: this.searchText,
          orderBy: this.orderBy,
          order: this.order,
          maxItemsInResult: this.maxItemsInResult,
          skipItemsInResult: this.skipItemsInResult,
          condition: removeEmptyNodes(this.condition),
          ...this.searchParameters,
        },
        {
          abort: (abort) => (this.$options.abort = abort),
        },
      );
      if (response) {
        const searchResult = this.itemsToGet
          ? response.data[this.itemsToGet]
          : response;
        if (this.skipItemsInResult === 0) {
          this.searchResult = searchResult;
        } else {
          searchResult.forEach((item) => {
            this.searchResult.push(item);
          });
        }
        if (searchResult.length < this.maxItemsInResult) {
          this.allItemsLoaded = true;
        }
        this.handleScroll();
      }
      this.loadingItems = false;
    },
  },
};
