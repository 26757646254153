<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_117_1497)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.2158 6.68676C9.82791 6.62022 9.42895 6.69312 9.08961 6.89255C8.75028 7.09198 8.49247 7.40507 8.36186 7.77636C8.20913 8.21052 7.73337 8.43866 7.29921 8.28594C6.86505 8.13321 6.63691 7.65745 6.78964 7.22329C7.05086 6.4807 7.56647 5.85452 8.24514 5.45566C8.92381 5.0568 9.72174 4.911 10.4976 5.04408C11.2735 5.17716 11.9772 5.58054 12.4842 6.18276C12.9909 6.78477 13.2684 7.54665 13.2674 8.33355C13.2672 9.60905 12.3216 10.4514 11.6463 10.9015C11.2833 11.1436 10.9262 11.3215 10.6632 11.4384C10.5304 11.4974 10.4187 11.5422 10.3382 11.5728C10.2978 11.5882 10.2651 11.6001 10.2413 11.6086L10.2124 11.6187L10.2032 11.6219L10.1999 11.623L10.1986 11.6234C10.1984 11.6235 10.1976 11.6237 9.93408 10.8332L10.1976 11.6237C9.76098 11.7693 9.28905 11.5333 9.14351 11.0967C8.99807 10.6604 9.23362 10.1888 9.66968 10.0429C9.66963 10.0429 9.66973 10.0429 9.66968 10.0429L9.66861 10.0432C9.66855 10.0433 9.66862 10.0432 9.66968 10.0429L9.68298 10.0382C9.6958 10.0337 9.71681 10.026 9.74484 10.0154C9.80103 9.99395 9.88459 9.96058 9.98626 9.9154C10.192 9.82398 10.4599 9.68943 10.7218 9.51478C11.2965 9.13167 11.6007 8.7241 11.6007 8.33316L11.6007 8.33192C11.6013 7.93832 11.4626 7.55721 11.2091 7.2561C10.9556 6.95499 10.6038 6.7533 10.2158 6.68676ZM13.2674 8.33355C13.2674 8.33383 13.2674 8.33412 13.2674 8.3344L12.4341 8.33316L13.2674 8.33355C13.2674 8.33368 13.2674 8.33342 13.2674 8.33355Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.16797 14.1673C9.16797 13.7071 9.54106 13.334 10.0013 13.334H10.0096C10.4699 13.334 10.843 13.7071 10.843 14.1673C10.843 14.6276 10.4699 15.0007 10.0096 15.0007H10.0013C9.54106 15.0007 9.16797 14.6276 9.16797 14.1673Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_117_1497">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
