import axios from 'axios';
import Env from '@/utils/env';

export default {
  getUsers(
    searchText,
    orderBy,
    order,
    maxItemsInResult,
    skipItemsInResult,
    role,
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/users', {
          params: {
            SearchText: searchText,
            OrderBy: orderBy,
            Order: order,
            MaxItemsInResult: maxItemsInResult,
            SkipItemsInResult: skipItemsInResult,
            Role: role,
          },
        })
        .then((response) => {
          resolve(response.data.users);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occurred when fetching your users.');
        });
    });
  },

  confirmUser(password) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/users/userEmailConfirmation/',
          password,
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occurred when choosing password');
        });
    });
  },

  confirmNewPassword(newPassword) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/users/confirmNewPassword',
          newPassword,
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occurred when choosing new password');
        });
    });
  },

  resetPasswordOrInvitation(userId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/users/resetPasswordOrInvitation',
          { UserId: userId },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occurred when resetting password or invitation');
        });
    });
  },

  modifyOwner(fromUserId, toUserId) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/users/modifyOwner',
          { FromUserId: fromUserId, ToUserId: toUserId },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occurred when modifying owner');
        });
    });
  },

  getOwner() {
    return new Promise((resolve, reject) => {
      axios
        .get(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/users/owner')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occurred when getting owner');
        });
    });
  },
};
