<template>
  <svg
    width="17px"
    height="17px"
    viewBox="0 0 17 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 64 (101010) - https://sketch.com -->
    <title>1B302EA3-E478-435C-B8AE-D3487DE15218</title>
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-3"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="A-accounts-players"
        transform="translate(-977.000000, -122.000000)"
        fill="currentColor"
      >
        <g id="Group-20" transform="translate(921.000000, 110.000000)">
          <g id="Group-18" transform="translate(43.000000, 0.000000)">
            <g
              id="account-grid-copy"
              transform="translate(13.000000, 12.000000)"
            >
              <g id="grid">
                <g>
                  <rect
                    id="Rectangle"
                    x="0"
                    y="0"
                    width="4.25"
                    height="4.25"
                    rx="1"
                  />
                  <rect
                    id="Rectangle-Copy-10"
                    x="0"
                    y="6.875"
                    width="17"
                    height="3.25"
                    rx="1"
                  />
                  <rect
                    id="Rectangle-Copy-8"
                    x="6.375"
                    y="0"
                    width="4.25"
                    height="4.25"
                    rx="1"
                  />
                  <rect
                    id="Rectangle-Copy-9"
                    x="12.75"
                    y="0"
                    width="4.25"
                    height="4.25"
                    rx="1"
                  />
                  <rect
                    id="Rectangle-Copy-15"
                    x="0"
                    y="12.75"
                    width="4.25"
                    height="4.25"
                    rx="1"
                  />
                  <rect
                    id="Rectangle-Copy-14"
                    x="6.375"
                    y="12.75"
                    width="4.25"
                    height="4.25"
                    rx="1"
                  />
                  <rect
                    id="Rectangle-Copy-14"
                    x="12.675"
                    y="12.75"
                    width="4.25"
                    height="4.25"
                    rx="1"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
