import axios from 'axios';
import store from '@/store/index';
import Env from '@/utils/env';
import TranslationHelper from '@/helpers/translationHelper';

export default {
  // TODO Remove when updated in broadcastStore.js
  getMetadata(mediaCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/media/' +
            mediaCode +
            '/metadata',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when fetching your media metadata.');
        });
    });
  },

  // TODO Remove when updated in broadcastStore.js
  uploadUnsplashMedia({ unsplashId }) {
    return new Promise((resolve, reject) => {
      let addMessage = true;
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/externalmedia/Unsplash/' +
            unsplashId,
          {},
          {
            onUploadProgress: (progressEvent) => {
              const totalLength = progressEvent.total;
              let uploadProgress = Math.round(
                (progressEvent.loaded * 100) / totalLength,
              );

              if (addMessage) {
                addMessage = false;
                store.dispatch('addMessage', {
                  message: {
                    text:
                      TranslationHelper.translate('media.uploading') +
                      ' ' +
                      unsplashId,
                    type: 'info',
                    progress: uploadProgress,
                  },
                  id: unsplashId,
                });
              } else {
                store.dispatch('editMessage', {
                  id: unsplashId,
                  message: { progress: uploadProgress },
                });
              }
            },
          },
        )
        .then((response) => {
          if (unsplashId) {
            store.dispatch('editMessage', {
              id: unsplashId,
              message: {
                text:
                  TranslationHelper.translate('media.uploaded') +
                  ' ' +
                  unsplashId,
                type: 'success',
              },
            });
            setTimeout(function () {
              store.dispatch('removeMessage', { id: unsplashId });
            }, 6000);
          }
          resolve(response);
        })
        .catch((error) => {
          reject('An error has occured during upload of Unsplash media', error);
        });
    });
  },
};
