import sv_SE from '@/components/lang/sv_SE.json';
import en_US from '@/components/lang/en_US.json'; // Should lazy load this
import de_DE from '@/components/lang/de_DE.json'; // Should lazy load this
import es_ES from '@/components/lang/es_ES.json';

const TranslationPlugin = {
  languageFiles: {
    en_US,
    sv_SE,
    de_DE,
    es_ES,
  },
  install(app, { store, getterName }) {
    if (!store || !getterName) {
      console.error('Please provide a VueX store');
    }

    function parsePath(inputString) {
      if (!inputString) {
        return [];
      }

      let pathRegexp = new RegExp(/([a-zA-Z0-9-_+$]+)/, 'g');

      let returnArray = [];
      let match = '';
      do {
        match = pathRegexp.exec(inputString);
        if (match) {
          returnArray.push(match[1]);
        }
      } while (match);

      return returnArray;
    }

    // inject a globally available $translate() method
    app.config.globalProperties.$translate = (...args) => {
      const language = store.getters[getterName] || 'en_US';
      if (!args) {
        return '';
      }

      let inputString = args[0];

      let objectToSearchThrough = this.languageFiles[language];
      if (!objectToSearchThrough) {
        return '';
      }

      let paths = parsePath(inputString);

      // This for-loop takes the language object and then dives into it
      for (let i = 0; i < paths.length; i++) {
        objectToSearchThrough = objectToSearchThrough[paths[i]];

        // If language is not english check english before returning translation not found.
        if (!objectToSearchThrough && language != 'en_US') {
          objectToSearchThrough = this.languageFiles.en_US;
          for (let i2 = 0; i2 < paths.length; i2++) {
            objectToSearchThrough = objectToSearchThrough[paths[i2]];
          }

          if (!objectToSearchThrough) {
            return `Translation not found (${inputString})`;
          }
        } else if (!objectToSearchThrough) {
          return `Translation not found (${inputString})`;
        }
      }

      if (~objectToSearchThrough.indexOf('{}')) {
        for (let i = 1; i < args.length; i++) {
          objectToSearchThrough = objectToSearchThrough.replace('{}', args[i]);
        }
      }

      return objectToSearchThrough;
    };
    app.config.globalProperties.$getUserlanguage = () => {
      return store.getters[getterName] || 'en_US';
    };
  },
};

export default TranslationPlugin;
