const TriggerView = () => import('@/views/triggers/TriggersView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const triggerRoutes = [
  {
    path: '/triggers',
    name: 'TriggerView',
    components: { default: TriggerView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Trigger', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('triggers.breadcrumbs.triggers'),
          },
        ];
      },
    },
  },
  {
    path: '/triggers/locations/:triggerCode/:triggerName?',
    name: 'LocationsTriggerView',
    components: { default: TriggerView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Trigger', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ route, translate }) => {
        return [
          {
            name: translate('triggers.breadcrumbs.triggers'),
            route: { name: 'TriggerView' },
          },
          {
            name: route.params.triggerName,
          },
          {
            name: translate('triggers.breadcrumbs.locations'),
          },
        ];
      },
    },
  },
  {
    path: '/triggers/players/:locationName/:locationCode/:triggerCode',
    name: 'PlayersTriggerView',
    components: { default: TriggerView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Trigger', action: 'ViewOnly' }],
      },
    },
  },
];
export default triggerRoutes;
