<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <circle fill="currentColor" class="a" cx="10" cy="10" r="10" />
    <circle
      fill="#181e24"
      class="b"
      cx="3"
      cy="3"
      r="3"
      transform="translate(7 7)"
    />
  </svg>
</template>
