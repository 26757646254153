<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.5149 6.54557L13.4568 1.48904C13.3019 1.33409 13.1181 1.21116 12.9158 1.12729C12.7134 1.04341 12.4965 1.00024 12.2774 1.00024C12.0584 1.00024 11.8415 1.04341 11.6391 1.12729C11.4368 1.21116 11.2529 1.33409 11.0981 1.48904L6.76761 5.83393C5.73165 5.55643 3.71058 5.35727 1.62199 7.04223C1.4396 7.18895 1.29017 7.37243 1.18341 7.58073C1.07666 7.78903 1.01499 8.01748 1.00241 8.25119C0.989829 8.48491 1.02662 8.71865 1.1104 8.93721C1.19418 9.15576 1.32305 9.35422 1.48864 9.51966L5.27743 13.3087L1.96036 16.6253C1.77248 16.8132 1.66693 17.068 1.66693 17.3336C1.66693 17.5993 1.77248 17.8541 1.96036 18.0419C2.14825 18.2298 2.40307 18.3353 2.66878 18.3353C2.93449 18.3353 3.18932 18.2298 3.3772 18.0419L6.69427 14.7245L10.4839 18.5128C10.7961 18.8246 11.2194 18.9999 11.6607 19.0002C11.699 19.0002 11.7382 19.0002 11.7774 18.9961C12.0153 18.9789 12.2467 18.9109 12.4561 18.7966C12.6654 18.6823 12.8478 18.5244 12.9909 18.3336C13.3817 17.8144 13.9076 16.9895 14.1943 16.0003C14.481 15.0112 14.4819 14.0937 14.2077 13.2171L18.5165 8.89468C18.8265 8.58223 19.0003 8.15988 19 7.71978C18.9997 7.27969 18.8253 6.85758 18.5149 6.54557Z"
      fill="currentColor"
    />
  </svg>
</template>
