<template>
  <div class="va-field va-multiple-disk-media">
    <va-multiple-media
      :data="data"
      :field-model="fieldModel"
      type="disk"
      @updatedData="$emit('updatedData')"
    />
  </div>
</template>

<script>
import VaMultipleMedia from './va-multiple-media.vue';

export default {
  components: {
    VaMultipleMedia,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
};
</script>
