<template>
  <div ref="va-list" class="va-list">
    <div
      v-if="newSelectedItems.length && checkboxActions && listView"
      class="checkbox-actions"
    >
      <span v-if="newSelectedItems.length" class="selected-items"
        >{{ newSelectedItems.length }}
        {{ $translate('media.itemsSelected') }}</span
      >
      <span
        v-for="action in checkboxActions"
        :key="action.emitAction"
        class="checkbox-action"
        :data-testid="`va-list-checkbox-action-${action.emitAction}`"
        @click="onCheckboxActionClick(action)"
        >{{ action.label }}</span
      >
    </div>
    <table
      ref="list-table"
      class="list-table"
      :class="{
        'items-selected': newSelectedItems.length && checkboxActions,
        grid: gridView,
      }"
    >
      <thead v-if="listView" class="table-head">
        <tr class="table-row-header">
          <th
            v-for="column in selectedColumns"
            :key="column.id"
            class="table-column header-column"
            :style="{ width: getColumnWidth(column) }"
            :data-testid="`list-th-${column.id}`"
          >
            <div
              class="header-column-wrapper"
              :class="{ selected: newSelectedItems.length && checkboxActions }"
            >
              <div v-if="column.options" class="options-column">
                <va-dropdown
                  :dropdown-actions="availableColumns"
                  :checkboxes="true"
                  @DROPDOWN_SELECTED="onColumnFilterClick($event)"
                >
                  <svg-icon
                    icon="options"
                    class="options rotated"
                    title="Options"
                  />
                </va-dropdown>
              </div>
              <div v-else-if="column.checkbox" class="checkbox-column">
                <va-checkbox
                  v-model="selectAll"
                  :some-selected="newSelectedItems.length < items.length"
                  @change="onCheckboxChange"
                />
              </div>
              <div
                v-else
                class="sortable-column"
                :class="[{ clickable: column.orderBy }, column.id]"
                @click="onSort(column)"
                @mouseover="mouseOver = column.orderBy"
                @mouseleave="mouseOver = ''"
              >
                <svg-icon
                  v-if="
                    column.orderBy &&
                    (orderBy === column.orderBy || mouseOver === column.orderBy)
                  "
                  icon="Sort"
                  :class="[{ desc: order === 'desc' }, 'sort-icon']"
                />
                <span v-if="column.name" class="column-text">{{
                  column.name
                }}</span>
                <svg-icon
                  v-if="
                    column.orderBy &&
                    orderBy !== column.orderBy &&
                    mouseOver !== column.orderBy
                  "
                  icon="Sort"
                  class="sort-icon hidden"
                />
              </div>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="table-body">
        <tr
          v-for="(item, index) in items"
          :key="item[itemId] || index"
          :data-testid="`table-row-${item[itemId] || index}`"
          class="table-row"
          :class="{ selected: itemId ? isItemSelected(item[itemId]) : false }"
        >
          <template v-for="column in selectedColumns">
            <td
              v-if="gridView ? column.grid && column.grid.show : true"
              :key="column.id"
              class="table-column"
              :style="{
                'grid-column':
                  column.grid && column.grid.columnSpan
                    ? `1 / span ${column.grid.columnSpan}`
                    : null,
              }"
              :data-testid="`column-${column.id}-${getTestId(item)}`"
            >
              <slot :name="'cell-' + column.id" :item="item">
                {{
                  typeof column.getAttributeValue === 'function'
                    ? column.getAttributeValue(column, item)
                    : getAttributeValue(item, column.attributeName)
                }}
              </slot>
              <div
                v-if="column.pin && listView"
                :class="['pin-column', { pinned: item.isPinned }]"
              >
                <svg-icon
                  :icon="item.isPinned ? 'pin' : 'unpin'"
                  class="clickable hover-effect-highlight"
                  @click="$emit('pinItem', item)"
                />
              </div>
              <div
                v-if="hasOptions(column.options, item)"
                class="options-column"
              >
                <va-dropdown
                  :dropdown-actions="
                    typeof column.options === 'function'
                      ? column.options(item, this)
                      : column.options
                  "
                  :data-testid="`options-${getTestId(item)}`"
                  @DROPDOWN_SELECTED="onDropdownItemClick($event, item)"
                >
                  <svg-icon icon="options" class="options" />
                </va-dropdown>
              </div>
              <div v-if="column.checkbox && listView" class="checkbox-column">
                <va-checkbox
                  v-if="item.allow ? item.allow.edit : true"
                  v-model="newSelectedItems"
                  :value="item[itemId]"
                  @update:model-value="onCheckboxChange"
                />
              </div>
              <div v-if="column.radio && listView" class="checkbox-column">
                <va-radio
                  v-model="newSelectedItems[0]"
                  :value="item[itemId]"
                  @update:model-value="onRadioChange"
                />
              </div>
            </td>
          </template>
        </tr>
      </tbody>
    </table>
    <div
      v-if="!items.length && allItemsLoaded && !loadingItems"
      class="empty-state-slot-content"
      data-testid="empty-state-slot-content"
    >
      <slot name="empty-state">{{ $translate('generic.noResultFound') }}</slot>
    </div>
    <va-show-more-button
      v-else-if="loadMoreButtonLabel"
      class="show-more-button"
      :text="loadMoreButtonLabel"
      :disabled="allItemsLoaded"
      :loading="loadingItems"
      @click="loadMoreItems"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import VaShowMoreButton from '@/components/framework/va-show-more-button.vue';
import VaDropdown from '@/components/framework/va-dropdown.vue';
import VaCheckbox from '@/components/framework/va-checkbox.vue';
import VaRadio from '@/components/framework/va-radio.vue';
import { withAsync } from '@/helpers/withAsync';
import { updateUserSettings } from '@/api/userApi';

export default {
  components: {
    SvgIcon,
    VaShowMoreButton,
    VaDropdown,
    VaCheckbox,
    VaRadio,
  },
  props: {
    columns: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    loadMoreButtonLabel: {
      type: String,
      default: '',
    },
    allItemsLoaded: {
      type: Boolean,
    },
    loadingItems: {
      type: Boolean,
    },
    order: {
      type: String,
      default: '',
    },
    orderBy: {
      type: String,
      default: '',
    },
    itemId: {
      type: String,
      default: '',
    },
    testId: {
      type: String,
      default: 'id',
    },
    checkboxActions: {
      type: Array,
      default: null,
    },
    resizable: {
      type: Boolean,
      default: false,
    },
    selectedItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    view: {
      type: String,
      default: () => {
        return 'list';
      },
    },
    userSettingId: {
      type: String,
      default: null,
    },
  },
  emits: [
    'OPTION_DROPDOWN_CLICK',
    'ORDER_BY',
    'ON_CHECKBOX_ACTION_CLICK',
    'ON_RADIO_CHANGE',
    'ON_SELECTED_CHANGE',
    'LOAD_MORE_ITEMS',
    'pinItem',
  ],
  data() {
    return {
      currentColumns: [],
      mouseOver: '',
      newSelectedItems: this.selectedItems,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'currentUser',
      userSettings: 'userSettings',
    }),
    selectedColumns() {
      return this.currentColumns.filter(
        (column) => column.enabled && column.permission !== false,
      );
    },
    availableColumns() {
      return this.currentColumns
        .filter((column) => !column.locked && column.permission !== false)
        .map((column) => {
          return {
            Label: column.name,
            EmitAction: column.id,
            Checked: column.enabled,
          };
        });
    },
    selectAll: {
      get() {
        return this.newSelectedItems.length > 0;
      },
      set(value) {
        let selected = [];

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        if (value) {
          this.items.forEach((item) => {
            // If standard allow object is present, check edit permission
            if (!item.allow || item.allow.edit) {
              selected.push(item[that.itemId]);
            }
          });
        }

        this.newSelectedItems = selected;
      },
    },
    listView() {
      return this.view === 'list';
    },
    gridView() {
      return this.view === 'grid';
    },
  },
  watch: {
    columns: {
      deep: true,
      immediate: true,
      handler(newVal) {
        /**
         * If there is a vuex store available, check for saved columns.
         * Else case is used mainly for unit tests without a real/mocked vuex store.
         */
        if (this.$store) {
          const columnSettings = this.userSettings?.find(
            (setting) => setting.id === this.userSettingId,
          )?.data;

          this.currentColumns = newVal.map((column) => ({
            ...column,
            enabled: columnSettings?.some((c) => c.id === column.id)
              ? columnSettings.find((c) => c.id === column.id).enabled
              : column.enabled,
          }));
        } else {
          this.currentColumns = newVal;
        }
      },
    },
    items: {
      deep: true,
      immediate: true,
      handler() {
        this.newSelectedItems = this.selectedItems;
      },
    },
    selectedItems: {
      deep: true,
      immediate: true,
      handler() {
        this.newSelectedItems = this.selectedItems;
      },
    },
  },
  mounted() {
    if (this.resizable) {
      this.resizableGrid();
    }
  },
  updated() {
    // This could probably be moved to a better place so it is not called every update
    if (this.resizable) {
      this.resizableGrid();
    }
  },
  methods: {
    getTestId(item) {
      const testIdArray = this.testId.split('.');
      if (testIdArray.length > 1) {
        return testIdArray.reduce((item, index) => item[index], item);
      }
      return item[this.testId];
    },
    resizableGrid() {
      var table = this.$refs['list-table'];

      var row = table.getElementsByClassName('table-row-header')[0],
        cols = row ? row.children : undefined;
      if (!cols) return;

      for (var i = 0; i < cols.length; i++) {
        if (i < cols.length - 1) {
          if (cols[i].getElementsByClassName('handler').length) {
            let element = cols[i].getElementsByClassName('handler')[0];
            if (element) {
              element.parentNode.removeChild(element);
            }
          }
          var div = this.createDiv(table.offsetHeight);
          cols[i].appendChild(div);
          this.setListeners(div);
        }
        cols[i].style.position = 'sticky';
      }
    },
    createDiv(height) {
      var div = document.createElement('div');
      div.classList.add('handler');
      div.style.top = 0;
      div.style.right = 0;
      div.style.width = '5px';
      div.style.position = 'absolute';
      div.style.cursor = 'col-resize';
      div.style.userSelect = 'none';
      div.style.zIndex = 1;
      /* table height */
      div.style.height = height + 'px';
      return div;
    },
    setListeners(div) {
      var pageX, curCol, nxtCol, curColWidth, nxtColWidth;
      div.addEventListener('mousedown', (e) => {
        curCol = e.target.parentElement;
        nxtCol = curCol.nextElementSibling;
        pageX = e.pageX;
        curColWidth = curCol.offsetWidth;
        if (nxtCol) nxtColWidth = nxtCol.offsetWidth;
      });

      document.addEventListener('mousemove', (e) => {
        if (curCol) {
          var diffX = e.pageX - pageX;

          if (nxtCol) {
            nxtCol.style.width = nxtColWidth - diffX + 'px';
          }

          curCol.style.width = curColWidth + diffX + 'px';
        }
      });

      document.addEventListener('mouseup', () => {
        curCol = undefined;
        nxtCol = undefined;
        pageX = undefined;
        nxtColWidth = undefined;
        curColWidth = undefined;
      });
    },
    onDropdownItemClick(action, item) {
      this.$emit('OPTION_DROPDOWN_CLICK', { action: action, item: item });
    },
    onColumnFilterClick(action) {
      let foundColumn = this.currentColumns.find(
        (column) => column.id === action,
      );
      foundColumn.enabled = !foundColumn.enabled;

      if (this.userSettingId) {
        this.$store.dispatch('updateUserSetting', {
          data: this.currentColumns.map((column) => ({
            id: column.id,
            enabled: column.enabled,
          })),
          id: this.userSettingId,
        });

        this.saveUserSettings();
      }
    },
    async saveUserSettings() {
      const data = this.userSettings.find(
        (setting) => setting.id === this.userSettingId,
      )?.data;
      if (data) {
        const settings = [
          {
            Id: this.userSettingId,
            Data: JSON.stringify(data),
          },
        ];
        await withAsync(updateUserSettings, this.currentUser.id, settings);
      }
    },
    loadMoreItems() {
      this.$emit('LOAD_MORE_ITEMS');
    },
    onSort(column) {
      if (column.orderBy) {
        this.$emit('ORDER_BY', column.orderBy);
      }
    },
    getColumnWidth(column) {
      let width = 'inherit';
      if (column.defaultWidth) {
        width = column.defaultWidth;
      } else if (column.options) {
        width = '60px';
      } else if (column.checkbox || column.radio) {
        width = '40px';
      }
      return width;
    },
    isItemSelected(itemId) {
      return this.newSelectedItems.includes(itemId);
    },
    onCheckboxActionClick(action) {
      if (action.emitAction)
        this.$emit('ON_CHECKBOX_ACTION_CLICK', {
          action: action.emitAction,
          items: this.newSelectedItems,
        });
    },
    onRadioChange(value) {
      this.$emit('ON_RADIO_CHANGE', value);
    },
    onCheckboxChange() {
      this.$emit('ON_SELECTED_CHANGE', this.newSelectedItems);
    },
    hasOptions(options, item) {
      return typeof options === 'function'
        ? options(item, this)?.length
        : options?.length;
    },
    getAttributeValue(item, attributeName) {
      if (!item || !attributeName) return null;

      let value = null;
      let attribute = attributeName.split('.');
      value = item[attribute[0]];

      if (attribute.length > 1) {
        for (let i = 1; i < attribute.length; i++) {
          value = value[attribute[i]];
        }
      }
      if (Array.isArray(value)) {
        value = value.join(', ');
      }

      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
.va-list {
  position: relative;
  font-size: 11px;
  line-height: 15px;
  color: #fafafa;
  overflow: scroll;
  font-weight: normal;
  height: 100%;
  letter-spacing: 0.33px;

  * {
    box-sizing: border-box;
  }

  .table-column {
    font-weight: normal;
    text-align: left;
    padding: 10px;
    vertical-align: middle;
    max-width: 200px;
    word-wrap: break-word;
    white-space: pre-wrap;
  }

  :deep(.va-list-clickable) {
    color: inherit;
    text-decoration: none;
    transition: color 0.2s;
    cursor: pointer;
    text-align: left;
  }

  .pin-column {
    opacity: 0;
    transition: opacity 0.3s;
    font-size: 20px;
    width: 0;

    &.pinned {
      opacity: 1;
    }
  }

  .table-row {
    height: 54px;
    border-top: 1px solid #3a3a3f;
    border-bottom: 1px solid #3a3a3f;
    transition: background-color 0.2s;

    &:hover {
      background-color: #242c35;

      :deep(.va-list-clickable) {
        text-decoration-line: underline;
        color: #fafafa;
      }

      .pin-column {
        opacity: 1;
      }
    }
  }

  .table-body {
    color: $color-text-secondary;
    font-size: 13px;
  }

  .list-table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 10px;

    &.items-selected {
      margin-top: -45px;
    }

    &.grid {
      .table-body {
        display: flex;
        flex-wrap: wrap;

        .table-row {
          display: grid;
          grid-template-columns: 60px 60px 60px 60px 60px;
          height: 100%;
          width: 300px;
          background-color: #181e24;
          margin: 10px;
          border: none;

          .table-column {
            min-height: 54px;
            white-space: nowrap;
            display: flex;
            align-items: center;
            overflow: hidden;
            max-width: none;
          }

          :deep(.va-list-thumbnail) {
            width: 300px;
            height: 300px;
          }
        }
      }
    }
  }

  .options-column {
    height: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .checkbox-column {
    width: 20px;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .header-column {
    width: inherit;
    padding: 0;
    white-space: nowrap;
    font-weight: 500;
    position: sticky;
    top: 0;
    background-color: #1d252c;
    z-index: 2;

    .sortable-column {
      height: 100%;
      display: flex;
      align-items: center;
    }

    .header-column-wrapper {
      z-index: 1;
      width: inherit;
      height: 45px;
      border-right: 1px solid #3a3a3f;

      &:last-child {
        border-right: none;
      }

      .sortable-column {
        padding: 7.5px 10px;
      }

      .options-column {
        padding: 0 10px;
      }

      .checkbox-column {
        padding: 0 10px;
      }
    }
  }

  .table-row-header {
    height: 45px;
  }

  .icons {
    font-size: 20px;

    .column-icon {
      display: inline-block;

      .icon {
        margin: 0 2.5px;
      }
    }
  }

  .options {
    font-size: 18px;
  }

  .rotated {
    transform: rotate(90deg);
  }

  .clickable {
    cursor: pointer;

    &:hover {
      color: #fafafa;
    }
  }

  .column-wrapper {
    display: flex;
    align-items: center;
  }

  .sort-icon {
    margin-right: 5px;

    &.desc {
      transform: rotate(180deg);
    }

    &.hidden {
      opacity: 0;
    }
  }

  .selected {
    background-color: #181e24 !important;
    color: #fafafa !important;
    border-right: none !important;
  }

  .checkbox-actions {
    width: calc(100% - 50px);
    position: sticky;
    top: 0;
    left: 50px;
    margin: 0;
    z-index: 3;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
    font-size: 13px;
    letter-spacing: 0.33px;
    text-decoration: underline;
    background-color: #181e24;

    .checkbox-action {
      padding: 0 10px;
      cursor: pointer;
    }
  }

  .selected-items {
    position: absolute;
    left: 0;
  }

  /* stylelint-disable-next-line no-descending-specificity */
  :deep(.item-thumbnail) {
    display: flex;

    .va-list-thumbnail {
      margin: -10px;
    }
  }

  /* stylelint-disable-next-line no-descending-specificity */
  :deep(.va-list-thumbnail) {
    min-width: 53px;
    min-height: 54px;
    width: 53px;
    height: 54px;
    margin: -10px 10px -10px -10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    background-color: #1d2127;
    cursor: pointer;
  }

  .empty-state-slot-content {
    padding: 1rem 0;
    text-align: center;
    font-size: 14px;
  }
}
</style>
