<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="icons-sp" transform="translate(-70.000000, -351.000000)">
        <g id="Group-Copy-6" transform="translate(50.000000, 331.000000)">
          <g
            id="contact-circle-copy-4-contact-circle"
            transform="translate(20.000000, 20.000000)"
          >
            <circle id="circle" fill="currentColor" cx="10" cy="10" r="10" />
            <path
              id="check"
              d="M6.76440423,8.769762 C6.23368139,8.25264742 5.37320964,8.25264742 4.84248679,8.769762 C4.31176394,9.28687658 4.31176394,10.125285 4.84248679,10.6423996 L8.11846865,13.834382 C8.67204052,14.3737597 9.57764142,14.3470597 10.0967301,13.7760566 L15.3658746,7.97994085 C15.8635376,7.43250623 15.8115112,6.59563174 15.2496704,6.11072936 C14.6878296,5.62582697 13.8289322,5.67651936 13.3312692,6.22395398 L9.01945743,10.9669934 L6.76440423,8.769762 Z"
              fill="#181E24"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
