<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="50"
    height="50"
    viewBox="0 0 50 50"
  >
    <g transform="translate(-174.657 -816.118) rotate(45)">
      <rect
        class="a"
        width="8"
        height="37"
        rx="4"
        transform="translate(731.828 422.485)"
      />
    </g>
    <rect class="a" width="50" height="8" rx="4" transform="translate(0 42)" />
    <rect class="a" width="25" height="8" rx="4" />
    <rect class="a" width="20" height="8" rx="4" transform="translate(30)" />
    <rect
      class="a"
      width="50"
      height="8"
      rx="4"
      transform="translate(0 50) rotate(-90)"
    />
    <rect
      class="a"
      width="25"
      height="8"
      rx="4"
      transform="translate(42 50) rotate(-90)"
    />
    <rect
      class="a"
      width="20"
      height="8"
      rx="4"
      transform="translate(42 20) rotate(-90)"
    />
  </svg>
</template>
