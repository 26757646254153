export const TriggerConstants = Object.freeze({
  ACCOUNT_TRIGGER_VIEW: 'TriggerView',
  LOCATION_TRIGGER_VIEW: 'LocationsTriggerView',
  PLAYER_TRIGGER_VIEW: 'PlayersTriggerView',
  TRIGGER_GROUP_DAYPART: 'ScheduledDaypart',
  TRIGGER_GROUP_TIMER: 'Timer',
  TRIGGER_GROUP_EXTERNAL: 'External',
  TRIGGER_ACTION_PLAY: 'Play',
  TRIGGER_ACTION_PAUSE: 'Pause',
});
export const MediaConstants = Object.freeze({
  MEDIA_TYPE_ALL: 'All',
  MEDIA_TYPE_IMAGES: 'Images',
  MEDIA_TYPE_VIDEOS: 'Videos',
  MEDIA_TYPE_FILES: 'Files',
  MEDIA_TYPE_SIGNS: 'Signs',
});
export const CommonConstants = Object.freeze({
  GRID_VIEW: 'grid',
  LIST_VIEW: 'list',
});
