import api from '@/api/api.js';

const URLS = {
  signWithMediaCode: 'signs/{mediaCode}',
  signsUrl: 'signs',
  signsOptionsWithName: 'signs/options/{name}',
};

export const getSign = (mediaCode) => {
  return api.get(URLS.signWithMediaCode.replace('{mediaCode}', mediaCode));
};

export const editSign = (mediaCode, formData) => {
  return api.put(URLS.signWithMediaCode.replace('{mediaCode}', mediaCode), {
    form_data: formData,
  });
};

export const addSign = (formData, templateMediaCode) => {
  return api.post(URLS.signsUrl, {
    form_data: formData,
    templateMediaCode: templateMediaCode,
  });
};

export const getSignOptions = ({
  name,
  searchText,
  maxItemsInResult,
  skipItemsInResult,
}) => {
  return api.get(URLS.signsOptionsWithName.replace('{name}', name), {
    params: {
      searchText: searchText,
      maxItemsInResult: maxItemsInResult,
      skipItemsInResult: skipItemsInResult,
    },
  });
};
