<template>
  <top-nav-bar
    ref="top-nav-bar"
    :nav-bar-action-button-label="actionButtonLabel"
    :nav-bar-actions="navBarActions"
    :nav-bar-view-menu="viewMenu"
    :nav-bar-search="!searchFilter"
    :nav-bar-search-filter="searchFilter"
    :tab-bar-items="tabBarItems"
    :active-tab="activeTab"
    :media-libraries="mediaLibraries"
    :tag-type="getTagTypes().MEDIA"
    @TABBAR_ITEM_CLICKED="onTabClick"
    @NAVBAR_SEARCH="$emit('search', $event)"
    @NAVBAR_DROPDOWN_SELECTED="$emit('nav-bar-dropdown-select', $event)"
    @FILTER_CHANGED="onFilterChange"
    @search="$emit('saved-search', $event)"
  />
</template>

<script>
import TopNavBar from '@/components/framework/va-top-navbar.vue';
import { getMediaFilters } from '@/api/mediaFilterApi';
import { search } from '@/mixins/search';
import { getTabBarItems } from '../helpers/mediaUtils';
import { getTagTypes } from '@/constants/tagType';

export default {
  components: {
    TopNavBar,
  },
  mixins: [search],
  props: {
    navBarActions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    searchFilter: {
      type: Boolean,
    },
    viewMenu: {
      type: Boolean,
    },
    tabBarItemsSettings: {
      type: Object,
      default: () => {
        return {};
      },
    },
    mediaLibraries: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: [
    'search',
    'nav-bar-dropdown-select',
    'saved-search',
    'tab-click',
    'update-filter',
  ],
  data() {
    return {
      activeTab: 'all',
      searchFunction: getMediaFilters,
      itemsToGet: 'MediaFilters',
    };
  },
  computed: {
    filters() {
      return this.searchResult || [];
    },
    tabBarItems() {
      let items = getTabBarItems(
        this.$translate,
        this.tabBarItemsSettings,
        this.activeTab,
      );

      if (this.filters.length) {
        items.push({
          label: '|',
          emitAction: '|',
          unclickable: true,
          default: false,
        });
        this.filters.forEach((filter) => {
          items.push({
            label: filter.mediaFilterName,
            emitAction: 'mediaFilter:' + filter.mediaFilterCode,
            default: this.activeTab == 'mediaFilter:' + filter.mediaFilterCode,
          });
        });
      }

      return items;
    },
    actionButtonLabel() {
      if (this.navBarActions.length) {
        return this.$translate('media.newMedia');
      }
      return '';
    },
  },
  created() {
    this.getItems();
  },
  methods: {
    onTabClick(tab) {
      this.activeTab = tab;
      this.$emit('tab-click', tab);
    },
    onFilterChange(condition) {
      this.$emit('update-filter', condition);
      this.getItems();
    },
    getTagTypes,
  },
};
</script>
