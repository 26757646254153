<template>
  <svg
    width="17px"
    height="12px"
    viewBox="0 0 17 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="quote-icon" fill="#BABABA" fill-rule="nonzero">
        <path
          id="“"
          d="M5.33986928,12 L5.33986928,6.24299065 L2.85947712,6.24299065 C2.85947712,5.32087227 3.03485839,4.54205607 3.38562092,3.90654206 C3.73638344,3.27102804 4.38779956,2.79127726 5.33986928,2.46728972 L5.33986928,0 C4.56318083,0.0996884735 3.84286492,0.330218069 3.17892157,0.691588785 C2.51497821,1.0529595 1.94498911,1.50778816 1.46895425,2.05607477 C0.99291939,2.60436137 0.623366013,3.23987539 0.360294118,3.96261682 C0.0972222222,4.68535826 -0.0217864924,5.45794393 0.00326797386,6.28037383 L0.00326797386,12 L5.33986928,12 Z M17,12 L17,6.24299065 L14.5196078,6.24299065 C14.5196078,5.32087227 14.6949891,4.54205607 15.0457516,3.90654206 C15.3965142,3.27102804 16.0479303,2.79127726 17,2.46728972 L17,2.04281037e-14 C16.2233115,0.0996884735 15.5029956,0.330218069 14.8390523,0.691588785 C14.1751089,1.0529595 13.6051198,1.50778816 13.129085,2.05607477 C12.6530501,2.60436137 12.2834967,3.23987539 12.0204248,3.96261682 C11.7573529,4.68535826 11.6383442,5.45794393 11.6633987,6.28037383 L11.6633987,12 L17,12 Z"
        />
      </g>
    </g>
  </svg>
</template>
