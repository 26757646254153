<template>
  <div class="va-filter-input">
    <div class="input-field-container">
      <input v-model="value" class="input-area" @keyup="onValueChanged" />
      <svg-icon v-if="value === ''" icon="search" class="search-icon" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import SvgIcon from '@/components/icons/SvgIcon.vue';

export default {
  components: {
    SvgIcon,
  },
  props: {
    nodeCodes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['CHANGED_CODES'],
  data() {
    return {
      value: '',
      codes: [],
    };
  },
  computed: {
    ...mapGetters({
      langData: 'langData',
    }),
  },
  created() {
    if (this.nodeCodes.length > 0) {
      this.value = this.nodeCodes[0];
    }
  },
  methods: {
    onValueChanged(e) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        this.codes = [];
        this.codes.push(e.target.value);
        this.$emit('CHANGED_CODES', this.codes);
      }, 800);
    },
  },
};
</script>

<style lang="scss" scoped>
.va-filter-input {
  display: inline-block;
  color: $color-text-secondary;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  width: 100%;

  .input-field-container {
    position: relative;
    min-height: 40px;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 2.5px;
    background-color: #4b4b51;

    .input-area {
      border: none;
      background: none;
      color: $color-text-secondary;
      margin: 2.5px;
      font-size: 14px;
      letter-spacing: 0.35px;
      width: 100%;

      &:focus {
        outline: none;
      }
    }

    .search-icon {
      position: absolute;
      right: 15px;
      top: 13px;
      cursor: pointer;
    }
  }
}
</style>
