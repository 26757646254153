import { TagType } from '@/enums/tag';
import { translate } from '../translationHelper';
import type { FilterNode } from '@/types/filterTypes';

export const removeEmptyNodes = (condition?: FilterNode) => {
  if (!condition) {
    return null;
  }

  const conditionCopy: FilterNode = JSON.parse(JSON.stringify(condition));

  const subNodesLevel1: FilterNode[] = [];

  if (conditionCopy.subNodes?.length) {
    conditionCopy.subNodes.forEach((subNodeLevel1) => {
      const subNodesLevel2: FilterNode[] = [];

      if (subNodeLevel1.subNodes?.length) {
        subNodeLevel1.subNodes.forEach((subNodeLevel2) => {
          if (subNodeLevel2.codes?.length) {
            subNodesLevel2.push(subNodeLevel2);
          }
        });
      }

      if (subNodesLevel2.length) {
        subNodesLevel1.push({
          nodeType: subNodeLevel1.nodeType,
          subNodes: subNodesLevel2,
        });
      }
    });
  }

  if (subNodesLevel1.length) {
    return {
      nodeType: conditionCopy.nodeType,
      subNodes: subNodesLevel1,
    };
  }

  return null;
};

export const getTagsButtonText = (tagType: TagType) => {
  switch (tagType) {
    case TagType.MEDIA:
      return translate('filter.mediaTags');
    case TagType.PLAYER:
      return translate('filter.screenTags');
    default:
      return translate('filter.tags');
  }
};
