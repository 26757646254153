<template>
  <svg
    id="switch-account-icon"
    xmlns="http://www.w3.org/2000/svg"
    width="21.03"
    height="16.993"
    viewBox="0 0 21.03 16.993"
  >
    <path
      id="Union_4"
      data-name="Union 4"
      d="M4.517,15.019a1,1,0,0,1-.866-.47L.293,11.192A1,1,0,0,1,1.707,9.778l1.828,1.828V2a1,1,0,0,1,2,0v9.57L7.328,9.778a1,1,0,0,1,1.415,1.414L5.384,14.55a1,1,0,0,1-.848.47Z"
      transform="translate(15.02 0) rotate(90)"
      fill="currentColor"
    />
    <path
      id="Union_5"
      data-name="Union 5"
      d="M3.536,13.019V3.414L1.707,5.243A1,1,0,0,1,.293,3.829L3.654.467a1.023,1.023,0,0,1,1.728,0L8.743,3.828A1,1,0,1,1,7.329,5.243L5.536,3.45v9.57a1,1,0,0,1-2,0Z"
      transform="translate(21.029 7.957) rotate(90)"
      fill="currentColor"
    />
  </svg>
</template>
