<template>
  <div class="va-filter-search">
    <div
      ref="search-field-container"
      data-testid="filter-search-container"
      class="search-field-container"
      aria-haspopup="listbox"
      aria-expanded="false"
      tabindex="0"
      @keypress.enter="setFocusOnInput"
      @click="setFocusOnInput"
    >
      <div
        v-for="(code, index) in codesAttributes"
        :key="code.code"
        class="code-container"
      >
        <div class="code">
          <div
            v-if="codeType === 'Tags'"
            :class="{
              'tag-color': code.attributes.color,
              'no-tag-color':
                !code.attributes.color ||
                code.attributes.color === 'transparent',
            }"
            :style="'background-color:' + code.attributes.color"
          />
          <span class="break-word">{{ code.attributes.name }}</span>
          <i class="fa fa-times" @click="onRemoveCode(index)" />
        </div>
        <span v-if="index < codesAttributes.length - 1" class="separator">
          {{ separator }}
        </span>
      </div>
      <input
        v-if="!singleSelect || codes.length === 0"
        ref="input-area"
        v-model="searchText"
        data-testid="filter-search-input"
        class="input-area"
        @keypress="setInputWidth"
        @keyup="onKeyUp"
        @keydown.down.prevent
        @keydown="onKeyDown"
      />
      <span
        v-if="placeholder && !searchText && codesAttributes.length === 0"
        class="placeholder"
      >
        {{ placeholder }}
      </span>
      <svg-icon
        v-if="codesAttributes.length === 0"
        icon="search"
        class="search-icon"
      />
    </div>
    <va-popup
      v-if="showSearchResult"
      :reference-element="$refs['search-field-container']"
      direction="bottom"
      @close="onPopupClose"
    >
      <div ref="popup-content" class="search-result-container">
        <ul
          v-if="computedOptions.length"
          data-testid="filter-search-list"
          class="popup-content__list"
          @scroll="checkScroll"
        >
          <li
            v-for="(option, index) in computedOptions"
            :id="`option${index}`"
            :key="option.code"
            tabindex="-1"
            role="option"
            :class="[
              { selected: codes.includes(option.code) },
              'popup-content__list--item',
            ]"
            :data-testid="`filter-search-list-item-${option.name}`"
            @keydown.prevent="onListItemKeyPress($event, index, option)"
            @click="
              addCode(option.code, { name: option.name, color: option.color })
            "
          >
            <div
              v-if="option.color"
              :class="{
                'tag-color': option.color,
                'no-tag-color': option.color === 'transparent',
              }"
              :style="'background-color:' + option.color"
            />
            <span :title="option.name">{{ option.name }}</span>
            <svg-icon
              v-if="codes.includes(option.code)"
              class="close-icon"
              icon="close"
            />
          </li>
        </ul>
      </div>
    </va-popup>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import VaPopup from '../va-popup.vue';
import playerServices from '@/services/player';
import SvgIcon from '@/components/icons/SvgIcon.vue';
import { withAsync } from '@/helpers/withAsync';
import { getAspects } from '@/api/aspectsApi';
import { getTags } from '@/api/tagsApi';
import { getLocations } from '@/api/locationsApi';
import { getAccountTriggers } from '@/api/triggersApi';
import { getMedia } from '@/api/mediaApi';

export default {
  components: {
    VaPopup,
    SvgIcon,
  },
  props: {
    nodeCodes: {
      type: Array,
      default: () => {
        return [];
      },
    },
    codeType: {
      type: String,
      default: '',
    },
    conditionLookups: {
      type: Object,
      default: () => {
        return {};
      },
    },
    singleSelect: {
      type: Boolean,
    },
    placeholder: {
      type: String,
      default: '',
    },
    separator: {
      type: String,
      default: '',
    },
    options: {
      type: Function,
      default: null,
    },
    mediaLibraries: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tagType: {
      type: String,
      default: '',
    },
  },
  emits: ['CHANGED_ATTRIBS', 'CHANGED_CODES'],
  data() {
    return {
      searchText: '',
      codes: [],
      showSearchResult: false,
      players: [],
      medias: [],
      codesAttributes: [],
      lookup: [],
      playerStatuses: [
        {
          name: 'FakePlayer',
          code: 'FakePlayer',
        },
        {
          name: 'NotStarted',
          code: 'NotStarted',
        },
        {
          name: 'NoContactOrErrorFor20Min',
          code: 'NoContactOrErrorFor20Min',
        },
        {
          name: 'NoContactOrErrorFor4Hours',
          code: 'NoContactOrErrorFor4Hours',
        },
        {
          name: 'NoContactOrErrorFor12Hours',
          code: 'NoContactOrErrorFor12Hours',
        },
        {
          name: 'NoContactOrErrorFor24Hours',
          code: 'NoContactOrErrorFor24Hours',
        },
        {
          name: 'NoContactOrErrorFor48Hours',
          code: 'NoContactOrErrorFor48Hours',
        },
        {
          name: 'OK',
          code: 'OK',
        },
      ],
      fetchedOptions: [],
      aspects: [],
      availableTags: [],
      locations: [],
      triggers: [],
      searchDelayTimer: null,
      maxItemsInResult: 10,
      skipItemsInResult: 0,
      totalCount: 0,
      activeOption: 0,
    };
  },
  computed: {
    ...mapGetters({
      layers: 'layers',
    }),
    layersWithPermission() {
      return this.layers
        ? this.layers.filter((layer) => {
            return layer.hasPermission;
          })
        : [];
    },
    // TODO Refactor this part to get options as a prop instead from store
    computedOptions() {
      if (this.options) {
        return this.fetchedOptions;
      }
      switch (this.codeType) {
        case 'Players':
          return (
            this.players?.map((player) => ({
              code: player.id,
              name: player.name,
            })) || []
          );
        case 'Locations':
          return (
            this.locations?.map((location) => ({
              code: location.locationCode,
              name: location.name,
            })) || []
          );
        case 'Aspects':
          return (
            this.aspects?.map((aspect) => ({
              code: aspect.AspectCode,
              name: aspect.AspectCode,
            })) || []
          );
        case 'Tags':
          return (
            this.availableTags?.map((tag) => ({
              code: tag.tagCode,
              name: tag.name,
              color: tag.tagAttribute?.[0]?.attributeValue || 'transparent',
            })) || []
          );
        case 'Media':
          return (
            this.medias?.map((media) => ({
              code: media.mediaCode,
              name: media.displayName,
            })) || []
          );
        case 'Layers':
          return (
            this.layersWithPermission?.map((layer) => ({
              code: layer.code,
              name: layer.name,
            })) || []
          );
        case 'Triggers':
          return (
            this.triggers?.map((trigger) => ({
              code: trigger.triggerCode,
              name: trigger.trigger.name,
            })) || []
          );
        case 'PlayerStatus':
          return (
            this.playerStatuses?.map((status) => ({
              code: status.code,
              name: status.name,
            })) || []
          );
        default:
          return [];
      }
    },
  },
  watch: {
    nodeCodes: {
      immediate: true,
      deep: true,
      handler() {
        this.renderCodes();
      },
    },
    conditionLookups: {
      immediate: true,
      deep: true,
      handler() {},
    },
  },
  mounted() {
    this.setInputWidth();
  },
  methods: {
    addCode(code, attributes) {
      this.$refs['input-area'].focus();
      if (this.codes.includes(code) && !this.singleSelect) {
        this.onRemoveCode(this.codes.indexOf(code));
        return;
      } else if (this.singleSelect) {
        this.onPopupClose();
      }
      this.codesAttributes.push({ code: code, attributes: attributes });
      const attribs = [];
      for (let atr in attributes) {
        if (atr == 'color') {
          attribs.push({
            type: atr.charAt(0).toUpperCase() + atr.slice(1),
            value: attributes[atr],
          });
        }
      }
      this.lookup.push({
        code: code,
        name: attributes.name,
        attributes: attribs,
      });
      this.codes.push(code);
      this.$emit('CHANGED_ATTRIBS', this.lookup);
      this.$emit('CHANGED_CODES', this.codes);
    },
    onRemoveCode(index) {
      this.codesAttributes.splice(index, 1);
      this.lookup.splice(index, 1);
      this.codes.splice(index, 1);
      this.$emit('CHANGED_ATTRIBS', this.lookup);
      this.$emit('CHANGED_CODES', this.codes);
    },
    onListItemKeyPress(e, index, option) {
      if (e.key === 'Enter') {
        this.addCode(option.code, { name: option.name, color: option.color });
      } else if (
        e.key === 'ArrowDown' &&
        index < this.computedOptions.length - 1
      ) {
        this.activeOption = index + 1;
        document.getElementById(`option${index + 1}`).focus();
      } else if (e.key === 'ArrowUp' && index > 0) {
        this.activeOption = index - 1;
        document.getElementById(`option${index - 1}`).focus();
      }
    },
    setInputWidth() {
      if (this.$refs['input-area'])
        this.$refs['input-area'].style.width =
          (this.searchText.length + 1) * 8 + 'px';
    },
    setFocusOnInput(e) {
      if (!this.singleSelect || this.codes.length === 0) {
        if (
          e.target !== this.$refs['search-field-container'] &&
          e.target !== this.$refs['input-area']
        )
          return;
        this.$refs['input-area'].focus();
        this.showSearchResult = true;
        this.skipItemsInResult = 0;
        this.setInputWidth();
        this.onSearch();
      }
    },
    onPopupClose() {
      this.searchText = '';
      this.showSearchResult = false;
      this.activeOption = 0;
    },
    searchPlayers() {
      // Store is not used because it would collide with the filter search result
      playerServices
        .searchPlayers(
          null,
          this.searchText,
          '',
          '',
          this.maxItemsInResult,
          this.skipItemsInResult,
        )
        .then((responseData) => {
          if (this.skipItemsInResult) {
            this.players.push(...responseData.Players);
          } else {
            this.players = responseData.Players;
          }
          this.totalCount = responseData.TotalCount;
        })
        .catch((error) => {
          console.error(error);
        });
    },
    async searchLocations() {
      const { response } = await withAsync(getLocations, {
        searchText: this.searchText,
        orderBy: 'LocationName',
        order: 'asc',
        maxItemsInResult: this.maxItemsInResult,
        skipItemsInResult: this.skipItemsInResult,
      });

      if (response?.data?.Locations) {
        if (this.skipItemsInResult) {
          this.locations.push(...response.data.Locations);
        } else {
          this.locations = response.data.Locations;
        }
        this.totalCount = response.data.TotalCount;
      }
    },
    async searchAspects() {
      const { response } = await withAsync(getAspects, {
        searchText: this.searchText,
        orderBy: 'Code',
        order: 'asc',
        maxItemsInResult: this.maxItemsInResult,
        skipItemsInResult: this.skipItemsInResult,
      });

      if (response?.data?.Aspects) {
        if (this.skipItemsInResult) {
          this.aspects.push(...response.data.Aspects);
        } else {
          this.aspects = response.data.Aspects;
        }
        this.totalCount = response.data.totalCount;
      }
    },
    async searchTags() {
      const { response } = await withAsync(getTags, {
        searchText: this.searchText,
        maxItemsInResult: this.maxItemsInResult,
        skipItemsInResult: this.skipItemsInResult,
        tagType: this.tagType,
      });
      if (response?.data?.tags) {
        if (this.skipItemsInResult) {
          this.availableTags.push(...response.data.tags);
        } else {
          this.availableTags = response.data.tags;
        }
        this.totalCount = response.data.totalCount;
      }
    },
    async searchMedia() {
      const { response } = await withAsync(getMedia, {
        searchText: this.searchText,
        maxItemsInResult: this.maxItemsInResult,
        skipItemsInResult: this.skipItemsInResult,
        libraries: this.mediaLibraries,
      });
      if (response?.data?.Medias) {
        if (this.skipItemsInResult) {
          this.medias.push(...response.data.Medias);
        } else {
          this.medias = response.data.Medias;
        }
        this.totalCount = response.data.totalCount;
      }
    },
    searchLayers() {
      // We get all layers because backend does not send info about total count
      this.$store.dispatch('getLayers', {
        searchText: this.searchText,
        orderBy: '',
        order: '',
      });
    },
    async searchTriggers() {
      const { response } = await withAsync(getAccountTriggers, {
        triggerGroup: null,
        searchText: this.searchText,
        order: '',
        orderBy: '',
        maxItemsInResult: this.maxItemsInResult,
        skipItemsInResult: this.skipItemsInResult,
      });
      if (response?.data?.accountTriggers) {
        this.triggers = response.data.accountTriggers;
      }
    },
    onSearch() {
      if (this.options) {
        this.getOptions();
      } else {
        switch (this.codeType) {
          case 'Players':
            this.searchPlayers();
            break;
          case 'Locations':
            this.searchLocations();
            break;
          case 'Aspects':
            this.searchAspects();
            break;
          case 'Tags':
            this.searchTags();
            break;
          case 'Media':
            this.searchMedia();
            break;
          case 'Layers':
            this.searchLayers();
            break;
          case 'Triggers':
            this.searchTriggers();
            break;
        }
      }
    },
    renderCodes() {
      if (this.nodeCodes) {
        this.codes = this.nodeCodes;
      }

      if (this.codes.length) {
        for (var i = 0; i < this.codes.length; i++) {
          if (
            this.codesAttributes.map((ca) => ca.code).includes(this.codes[i])
          ) {
            continue;
          } // No duplicates
          this.codesAttributes.push({
            code: this.codes[i],
            attributes: { name: this.codes[i] },
          });
          this.lookup.push({ code: this.codes[i], name: this.codes[i] });
        }
      }

      if (this.conditionLookups?.nodes && this.codes.length && this.codeType) {
        this.conditionLookups.nodes.forEach((node) => {
          if (
            node.nodeKey === this.codeType ||
            node.nodeType === this.codeType
          ) {
            var nodeItems = node.items;

            for (var i = 0; i < this.codes.length; i++) {
              var item = nodeItems.find((item) => item.code === this.codes[i]);
              if (item) {
                let foundAttribute = this.codesAttributes.find(
                  (ca) => ca.code === item.code,
                );
                let foundLookup = this.lookup.find(
                  (lu) => lu.code === item.code,
                );
                let name = item.name || item.code;
                let color = item.attributes?.[0]?.value;
                if (foundAttribute) {
                  foundAttribute.attributes = {
                    name: name,
                    color: color,
                  };
                  foundLookup.name = name;
                  foundLookup.attributes = item.attributes;
                } else {
                  this.codesAttributes.push({
                    code: item.code,
                    attributes: {
                      name: name,
                      color: color,
                    },
                  });
                  this.lookup.push({
                    code: item.code,
                    name: item.name,
                    attributes: item.attributes,
                  });
                }
              }
            }
          }
        });
      }
    },
    getOptions() {
      if (this.options) {
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        var that = this;
        this.options(
          {
            codeType: this.codeType,
            searchText: this.searchText,
          },
          function (returnValue) {
            that.fetchedOptions = returnValue;
          },
        );
      }
    },
    onKeyDown(e) {
      if (!this.showSearchResult && e.key !== 'Tab' && e.key !== 'Escape') {
        this.showSearchResult = true;
        this.skipItemsInResult = 0;
        this.onSearch();
      } else if (
        (e.key === 'Tab' || e.key === 'Escape') &&
        this.showSearchResult
      ) {
        this.onPopupClose();
      } else if (e.key === 'Backspace' && !e.target.value) {
        // Remove the last tag in the input when clicking backspace
        this.onRemoveCode(this.codesAttributes.length - 1);
      } else if (
        e.key === 'ArrowDown' &&
        this.showSearchResult &&
        this.computedOptions.length
      ) {
        document.getElementById(`option${this.activeOption}`).focus();
      } else {
        this.activeOption = 0;
      }
    },
    onKeyUp() {
      this.setInputWidth();
      if (this.searchDelayTimer) {
        clearTimeout(this.searchDelayTimer);
      }
      this.skipItemsInResult = 0;
      this.searchDelayTimer = setTimeout(this.onSearch, 500);
    },
    checkScroll(event) {
      const { target } = event;
      if (target.scrollTop + target.clientHeight >= target.scrollHeight) {
        this.loadMore();
      }
    },
    loadMore() {
      if (this.computedOptions.length === this.totalCount) {
        return;
      }
      this.skipItemsInResult += this.maxItemsInResult;
      this.onSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../../styles/animations';

.va-filter-search {
  display: inline-block;
  color: $color-text-secondary;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  width: 100%;

  .search-field-container {
    position: relative;
    min-height: 40px;
    width: 100%;
    box-sizing: border-box;
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 2.5px;
    background-color: $color-container-secondary;
    cursor: pointer;

    // Set focus on the container when tabbing through the page
    &:focus-visible {
      outline: $active-border-primary solid 2px;
    }

    .code-container {
      display: flex;
      align-items: center;

      .code {
        display: flex;
        align-items: center;
        background-color: $color-button-secondary;
        padding: 10px 15px;
        border-radius: 15.8px;
        font-size: 12.15px;
        letter-spacing: 0.3px;
        text-align: center;
        margin: 2.5px;
        line-height: 1;

        > i {
          padding-left: 5px;
          transition: color 0.2s ease-out;

          &:hover {
            color: $color-text-primary;
          }
        }
      }
    }

    .input-area {
      border: none;
      background: none;
      color: $color-text-secondary;
      margin: 2.5px;
      cursor: pointer;
      font-size: 14px;
      letter-spacing: 0.35px;

      &:focus {
        outline: none;
      }
    }

    .search-icon {
      position: absolute;
      right: 15px;
      top: 13px;
    }

    .placeholder {
      position: absolute;
      font-size: 14px;
      padding-left: 5px;
      letter-spacing: 0.35px;
      user-select: none;
      pointer-events: none;
    }
  }

  .search-result-container {
    position: absolute;
    width: 100%;
    margin-top: 0.2rem;
    background-color: $color-container-secondary;
    right: 0;
    border-radius: 4px;
    box-shadow: 0 3px 10px #0003;

    .popup-content {
      &__list {
        max-height: 240px;
        overflow-y: auto;
        border-radius: 4px;
        padding: 0;

        &--item {
          display: flex;
          align-items: center;
          height: 20px;
          padding: 10px 20px;
          white-space: pre;
          overflow: hidden;
          text-overflow: ellipsis;
          line-height: 20px;
          text-align: left;
          transition:
            background-color 0.2s ease-out,
            color 0.2s ease-out;

          &:hover,
          &:focus {
            background-color: $hover-container-primary;
            color: $color-text-primary;
            cursor: pointer;
          }

          // This is used when we use the keyboard to navigate the list, to make it more obvious which item is focused
          &:focus-visible {
            outline: $active-border-primary solid 1px;
          }

          &.selected {
            color: $color-text-primary;
            background: $active-container-primary;

            .close-icon {
              margin-left: auto;
              font-size: 10px;
              color: $color-text-secondary;
              visibility: hidden;
              transition: visibility 0.3s ease-out;
            }

            &:hover,
            &:focus {
              background: $hover-container-active-primary;

              .close-icon {
                visibility: visible;
              }
            }
          }
        }
      }
    }
  }

  .tag-color {
    display: inline-block;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    margin: 1px 6px 1px 1px;
  }

  .no-tag-color {
    display: inline-block;
    width: 6px;
    height: 6px;
    border-radius: 50%;
    border: 1px solid #bababa;
    margin: 1px 6px 1px 1px;
  }

  .separator {
    text-transform: lowercase;
    line-height: 1;
  }

  .break-word {
    word-break: break-all;
    white-space: pre-wrap;
  }
}

::-webkit-scrollbar-thumb {
  background-color: $highlight-container-secondary;
}
</style>
