import axios from 'axios';
import Env from '@/utils/env';

export default {
  //used in filters store, remove this after update of filter store (request prepared in factsApi)
  getFactFilters({
    factType,
    searchText,
    orderBy,
    order,
    maxItemsInResult,
    skipItemsInResult,
  }) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/facts/filters/' +
            factType +
            '/search',
          {
            params: {
              SearchText: searchText,
              OrderBy: orderBy,
              Order: order,
              MaxItemsInResult: maxItemsInResult,
              SkipItemsInResult: skipItemsInResult,
            },
          },
        )
        .then((response) => {
          resolve(response.data.filters);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when getting filters.');
        });
    });
  },
};
