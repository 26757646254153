<template>
  <div class="product-selector-modal">
    <va-modal type="warning" :width="1100" @close="closeSelectModal">
      <template #header>
        <h3>
          {{ $translate('items.addItem') }}
        </h3>
      </template>
      <template #body>
        <div class="va-product-popup">
          <top-nav-bar
            :nav-bar-search="true"
            :tab-bar-items="tabBarItems"
            :active-tab="activeTab"
            @TABBAR_ITEM_CLICKED="onTabClick"
            @NAVBAR_SEARCH="onSearch"
          />
          <va-list
            :columns="getColumns"
            :items="factItems"
            :load-more-button-label="loadMoreButtonText"
            :all-items-loaded="allItemsLoaded"
            :loading-items="loadingItems"
            :order="order"
            :order-by="orderBy"
            item-id="factCode"
            class="va-list-items"
            @LOAD_MORE_ITEMS="loadMoreItems"
            @ORDER_BY="onSort"
          >
            <template #cell-thumbnail="{ item: item }">
              <div class="item-thumbnail">
                <va-img
                  :src="getThumbnailUrl(item)"
                  class="va-list-thumbnail"
                />
              </div>
            </template>
            <template #cell-addButton="{ item: item }">
              <div class="button-container">
                <div class="button" @click="selectItem(item)">
                  {{ $translate('items.addItem') }}
                </div>
              </div>
            </template>
          </va-list>
        </div>
      </template>
    </va-modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TopNavBar from '@/components/framework/va-top-navbar.vue';
import VaModal from '@/components/framework/va-modal.vue';
import VaImg from '@/components/framework/va-img.vue';
import VaList from '@/components/framework/va-list/va-list.vue';
import { withAsync } from '@/helpers/withAsync';
import { getFactFilter, getFactScheme, searchFacts } from '@/api/factsApi';

export default {
  components: {
    VaModal,
    VaImg,
    TopNavBar,
    VaList,
  },
  emits: ['close', 'select'],
  data() {
    return {
      activeTab: 'all',
      searchText: '',
      searchDelayTimer: null,
      loadingItems: true,
      allItemsLoaded: false,
      loadMoreButtonText: '',
      skipItemsInResult: 0,
      maxItemsInResult: 50,
      nameAttribute: '',
      filterCondition: null,
      itemListSchema: {},
      factItems: [],
      orderBy: '',
      order: 'asc',
    };
  },
  computed: {
    ...mapGetters({
      itemFilters: 'itemFilters',
    }),
    tabBarItems() {
      let items = [];
      items.push({
        label: this.$translate('media.all'),
        emitAction: 'all',
        default: true,
      });
      this.itemFilters.forEach((filter) => {
        items.push({
          label: filter.attributeFilterName,
          emitAction: 'itemFilter:' + filter.attributeFilterCode,
          default: this.activeTab == 'itemFilter:' + filter.attributeFilterCode,
        });
      });
      return items;
    },
    thumbnailAttribute() {
      return (
        this.getColumns?.find((column) => column.id === 'thumbnail')
          ?.attributeName || ''
      );
    },
    getColumns() {
      let columns = [];

      this.itemListSchema?.schema?.forEach((attribute) => {
        if (attribute.mediaLibrary) {
          columns.push({
            id: 'thumbnail',
            name: attribute.title,
            attributeName: attribute.attributeCode,
            enabled: true,
            defaultWidth: '54px',
          });
        } else {
          columns.push({
            id: attribute.attributeCode,
            name: attribute.title,
            attributeName: attribute.attributeCode,
            getAttributeValue: this.getAttributeValue,
            enabled: true,
            orderBy: attribute.attributeCode,
            hasSchema: attribute.schema.length ? true : false,
          });
        }
      });

      columns.push({
        id: 'addButton',
        name: '',
        enabled: true,
      });

      return columns;
    },
  },
  created() {
    this.getItemListSchema();
    this.$store.dispatch('getItemFilters', {});
  },
  methods: {
    async getItemListSchema() {
      const { response } = await withAsync(getFactScheme, 'Item', 'List');

      if (response?.data?.factScheme) {
        this.itemListSchema = response.data.factScheme;
        this.onTabClick(this.activeTab);
      }
    },
    closeSelectModal() {
      this.$emit('close');
    },
    async getItems() {
      const { response } = await withAsync(searchFacts, 'Item', {
        condition: this.filterCondition,
        searchText: this.searchText,
        maxItemsInResult: this.maxItemsInResult,
        skipItemsInResult: this.skipItemsInResult,
        orderBy: this.orderBy,
        order: this.order,
      });

      if (response?.data?.facts) {
        if (this.skipItemsInResult === 0) {
          this.factItems = response.data.facts;
        } else {
          response.data.facts.forEach((fact) => {
            this.factItems.push(fact);
          });
        }
        this.onLoadedItems(response.data.facts);
      }
    },
    onTabClick(emitAction) {
      this.skipItemsInResult = 0;
      this.activeTab = emitAction;
      this.filterCondition = null;

      if (emitAction.includes('itemFilter:')) {
        this.searchWithFilter(emitAction.split(':')[1]);
      } else {
        this.getItems();
      }
    },
    async searchWithFilter(filterCode) {
      const { response } = await withAsync(getFactFilter, filterCode);

      if (response?.data?.filter) {
        this.filterCondition = response.data.filter.condition;
        this.getItems();
      }
    },
    onSearch(searchText) {
      this.searchText = searchText;
      if (this.searchDelayTimer) {
        clearTimeout(this.searchDelayTimer);
      }
      this.searchDelayTimer = setTimeout(() => {
        this.listItems();
      }, 500);
    },
    selectItem(item) {
      this.$emit('select', item);
    },
    getThumbnailUrl(item) {
      let media = item.factAttributes?.filter(
        (attribute) => attribute.AttributeCode === this.thumbnailAttribute,
      );
      let mediaCode =
        media.find((media) => media.IsDefault)?.MediaRef?.MediaCode ||
        media.find((media) => media.MediaRef?.MediaCode)?.MediaRef?.MediaCode ||
        '';
      return mediaCode
        ? `${this.$getEnv(
            'VITE_API_URL_SIGNAGE_BACKEND',
          )}/api/media/${mediaCode}/thumbnail`
        : '';
    },
    loadMoreItems() {
      this.loadingItems = true;
      let skipItemsInResult = this.skipItemsInResult + this.maxItemsInResult;
      this.listItems(skipItemsInResult);
    },
    listItems(skipItemsInResult) {
      if (skipItemsInResult) {
        this.skipItemsInResult = skipItemsInResult;
      } else {
        this.skipItemsInResult = 0;
        this.allItemsLoaded = false;
        this.loadMoreButtonText = this.$translate('items.loadMoreItems');
      }
      this.getItems();
    },
    getAttributeValue(column = {}, item = {}) {
      return (
        item.factAttributes
          ?.filter(
            (attribute) => attribute.AttributeCode === column.attributeName,
          )
          ?.map((a) =>
            column.hasSchema ? this.getSchemaValues(a.Value) : a.Value,
          )
          ?.join(', ') || null
      );
    },
    getSchemaValues(value) {
      let parsedValue = JSON.parse(value);
      let values = [];
      Object.keys(parsedValue).forEach((key) => {
        if (key !== 'id') {
          values.push(parsedValue[key]);
        }
      });
      return values.join(' - ');
    },
    onLoadedItems(items) {
      this.loadingItems = false;
      if (items.length < this.maxItemsInResult) {
        this.loadMoreButtonText = this.$translate('items.allItemsLoaded');
        this.allItemsLoaded = true;
      } else {
        this.loadMoreButtonText = this.$translate('items.loadMoreItems');
      }
    },
    onSort(orderBy) {
      if (this.orderBy === orderBy) {
        this.order = this.order == 'asc' ? 'desc' : 'asc';
      }
      this.orderBy = orderBy;
      this.listItems();
    },
  },
};
</script>

<style lang="scss" scoped>
.product-selector-modal {
  .va-list-items {
    height: 60vh;
  }

  .item-thumbnail {
    display: flex;
  }

  .button-container {
    text-align: end;
    white-space: nowrap;
  }

  .button {
    display: inline-block;
    color: #000;
    background-color: #5edc88;
    padding: 8px;
    border-radius: 19.5px;
    font-weight: 500;
    cursor: pointer;
  }

  :deep(.va-list) .table-row {
    border-bottom: 1px solid #2c2f35;
  }

  :deep(.header-column) {
    background-color: #3a3a3f !important;
    border-bottom: 1px solid #2c2f35 !important;
  }
}
</style>
