import PermissionsService from '@/services/permissions';

const CLEAR_PERMISSION_STATE = 'CLEAR_PERMISSION_STATE';

const SET_ROLES = 'SET_ROLES';
const SET_ROLE_NAMES = 'SET_ROLE_NAMES';

const LOAD_ROLE = 'LOAD_ROLE';
const SET_ROLE_NAME = 'SET_ROLE_NAME';
const SET_ROLE_DESCRIPTION = 'SET_ROLE_DESCRIPTION';
const SET_ROLE_COMPONENTS = 'SET_ROLE_COMPONENTS';
const SET_FULL_ACCESS = 'SET_FULL_ACCESS';
const SET_SUBPART_VALUE = 'SET_SUBPART_VALUE';
const SET_SCREEN_CONDITION = 'SET_SCREEN_CONDITION';
const SET_MEDIA_LIBRARY_CONDITION = 'SET_MEDIA_LIBRARY_CONDITION';

// state
const state = {
  roleNames: [],
  roles: [],
  currentRole: {
    roleCode: '',
    roleName: '',
    roleDescription: '',
    roleComponents: [],
    screensComponent: {
      Condition: {},
      ConditionLookups: {},
      FixedPlayerList: false,
    },
    mediaLibraryComponent: {
      Condition: {},
      ConditionLookups: {},
      FixedPlayerList: false,
    },
  },
};

// getters
const getters = {
  roleNames: (state) => state.roleNames,
  roles: (state) => state.roles,
  roleName: (state) => state.currentRole.roleName,
  roleDescription: (state) => state.currentRole.roleDescription,
  roleComponents: (state) => state.currentRole.roleComponents,
  screensComponent: (state) => state.currentRole.screensComponent,
  mediaLibraryComponent: (state) => state.currentRole.mediaLibraryComponent,
};

// actions
const actions = {
  clearPermissionState({ commit }) {
    commit(CLEAR_PERMISSION_STATE);
  },
  setRoleName({ commit }, { roleName }) {
    commit(SET_ROLE_NAME, { roleName });
  },
  setRoleDescription({ commit }, { roleDescription }) {
    commit(SET_ROLE_DESCRIPTION, { roleDescription });
  },
  setSubPartValue(
    { commit },
    { componentCode, componentSubPartCode, subPartOption },
  ) {
    commit('SET_SUBPART_VALUE', {
      componentCode,
      componentSubPartCode,
      subPartOption,
    });
  },
  setScreenFilterCondition({ commit }, { condition }) {
    commit(SET_SCREEN_CONDITION, { condition });
  },
  setMediaLibraryFilterCondition({ commit }, { condition }) {
    commit(SET_MEDIA_LIBRARY_CONDITION, { condition });
  },
  setFullAccess({ commit }, { componentCode, value }) {
    commit('SET_FULL_ACCESS', { componentCode, value });
  },
  loadRole({ commit }, { roleCode }) {
    if (!roleCode) {
      return;
    }

    PermissionsService.fetchComponents().then((response) => {
      commit('SET_ROLE_COMPONENTS', response.components);

      if (roleCode && roleCode.toLowerCase() !== 'new') {
        PermissionsService.fetchRole(roleCode).then((response) => {
          if (response.success.ok && response.role) {
            commit('LOAD_ROLE', { role: response.role });
          }
        });
      }
    });
  },
  saveRole({ dispatch, state }) {
    if (!state.currentRole.roleName) {
      return;
    }

    let components = [];

    for (let rc of state.currentRole.roleComponents) {
      let permission = rc.defaultPermission;

      if (rc.permission !== undefined) {
        permission = rc.permission;
      }

      // Don't edit account permissions
      if (rc.isAccountComponent) {
        continue;
      }

      components.push({
        UniqueCode: rc.id,
        Permission: permission,
      });

      // If there are no subparts
      if (!Array.isArray(rc.subParts) || rc.subParts.length === 0) {
        continue;
      }

      for (let subpart of rc.subParts) {
        components.push({
          UniqueCode: subpart.id,
          Permission:
            subpart.permission !== undefined
              ? subpart.permission
              : subpart.defaultPermission,
        });
      }
    }

    let requestPayload = {
      Name: state.currentRole.roleName,
      Description: state.currentRole.roleDescription,
      ScreensFilter: state.currentRole.screensComponent,
      MediaLibraryFilter: state.currentRole.mediaLibraryComponent,
      ComponentsAndSubParts: components,
    };

    if (!state.currentRole.roleCode) {
      PermissionsService.saveRole(requestPayload).then(() => {
        dispatch('getRoles');
      });
    } else {
      PermissionsService.updateRole(
        state.currentRole.roleCode,
        requestPayload,
      ).then(() => {
        dispatch('getRoles');
      });
    }
  },
  getRoles({ commit }) {
    PermissionsService.fetchRoles().then((response) => {
      if (!response.roles || response.roles.length === 0) {
        return;
      }

      commit('SET_ROLES', response.roles);
      commit(
        'SET_ROLE_NAMES',
        response.roles.map((r) => ({
          default: false,
          emitAction: r.id,
          label: r.name,
        })),
      );
    });
  },
};

// mutations
const mutations = {
  [CLEAR_PERMISSION_STATE](state) {
    state.currentRole['roleCode'] = '';
    state.currentRole['roleName'] = '';
    state.currentRole['roleDescription'] = '';
    state.currentRole['roleComponents'] = [];
    state.currentRole.screensComponent['Condition'] = {};
    state.currentRole.screensComponent['ConditionLookups'] = {};
    state.currentRole.mediaLibraryComponent['Condition'] = {};
    state.currentRole.mediaLibraryComponent['ConditionLookups'] = {};
  },
  [SET_ROLES](state, roles) {
    state['roles'] = roles;
  },
  [SET_ROLE_NAMES](state, roleNames) {
    state['roleNames'] = roleNames;
  },
  [SET_ROLE_NAME](state, { roleName }) {
    state.currentRole.roleName = roleName;
  },
  [SET_ROLE_DESCRIPTION](state, { roleDescription }) {
    state.currentRole.roleDescription = roleDescription;
  },
  [SET_ROLE_COMPONENTS](state, components) {
    if (!components) {
      return;
    }

    state.currentRole['roleComponents'] = components;
  },
  [SET_FULL_ACCESS](state, { componentCode, value }) {
    let targetComponent = state.currentRole.roleComponents.find(
      (rc) => rc.componentCode === componentCode,
    );

    if (!targetComponent) {
      return;
    }

    targetComponent['permission'] = value;
  },
  [SET_SUBPART_VALUE](
    state,
    { componentCode, componentSubPartCode, subPartOption },
  ) {
    if (!componentCode || !componentSubPartCode || !subPartOption) {
      return;
    }

    const targetComponent = state.currentRole.roleComponents.find(
      (rc) => rc.componentCode === componentCode,
    );
    if (!targetComponent || !targetComponent.subParts) {
      return;
    }

    const targetSubPart = targetComponent.subParts.find(
      (sp) => sp.componentSubPartCode === componentSubPartCode,
    );
    if (!targetSubPart) {
      return;
    }

    targetSubPart['permission'] = subPartOption;
  },
  [SET_SCREEN_CONDITION](state, { condition }) {
    if (!condition) {
      return;
    }
    state.currentRole.screensComponent['Condition'] = condition;
  },
  [SET_MEDIA_LIBRARY_CONDITION](state, { condition }) {
    if (!condition) {
      return;
    }
    state.currentRole.mediaLibraryComponent['Condition'] = condition;
  },
  [LOAD_ROLE](state, { role }) {
    state.currentRole.roleCode = role.id;
    state.currentRole.roleName = role.name;
    state.currentRole.roleDescription = role.description;

    for (let inRC of role.components) {
      let stateRC = state.currentRole.roleComponents.find(
        (rc) => rc.componentCode === inRC.componentCode,
      );
      stateRC['permission'] = inRC.permission;

      if (inRC.subParts) {
        for (let inSubPart of inRC.subParts) {
          let stateSubPart = stateRC.subParts.find(
            (src) => src.id === inSubPart.id,
          );
          stateSubPart['permission'] = inSubPart.permission;
        }
      }
    }

    if (role.filters.Media) {
      if (!role.filters.Media.condition) {
        state.currentRole.mediaLibraryComponent['Condition'] = {};
      } else {
        state.currentRole.mediaLibraryComponent['Condition'] =
          role.filters.Media.condition;
      }
      if (!role.filters.Media.conditionLookups) {
        state.currentRole.mediaLibraryComponent['ConditionLookups'] = {};
      } else {
        state.currentRole.mediaLibraryComponent['ConditionLookups'] =
          role.filters.Media.conditionLookups;
      }
    }
    if (role.filters.Player) {
      if (!role.filters.Player.condition) {
        state.currentRole.screensComponent['Condition'] = {};
      } else {
        state.currentRole.screensComponent['Condition'] =
          role.filters.Player.condition;
      }
      if (!role.filters.Player.conditionLookups) {
        state.currentRole.screensComponent['ConditionLookups'] = {};
      } else {
        state.currentRole.screensComponent['ConditionLookups'] =
          role.filters.Player.conditionLookups;
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
