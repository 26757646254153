<template>
  <div
    class="va-filter-node"
    :data-testid="`filter-node-${node.nodeKey || node.nodeType}`"
  >
    <va-filter-button
      class="filter-button"
      :text="buttonText"
      :type="'REMOVE'"
      @click="removeNode"
    />
    <va-select
      v-model="node.codeCondition"
      class="filter-select"
      :options="[
        { value: 'In', label: $translate('filter.include') },
        { value: 'NotIn', label: $translate('filter.notInclude') },
        { value: 'Matching', label: $translate('filter.matching') },
      ]"
      @update:model-value="onChangedNodeCondition"
    />
    <va-filter-input
      v-if="node.nodeType === 'SearchText'"
      class="filter-input"
      :code-type="node.nodeType"
      :node-codes="node.codes"
      @CHANGED_CODES="onChangedCodes"
    />
    <va-filter-search
      v-else-if="node.codeCondition !== 'Matching'"
      class="filter-search"
      :code-type="node.nodeKey || node.nodeType"
      :node-codes="node.codes"
      :placeholder="`${$translate(
        'generic.select',
      )} ${buttonText.toLowerCase()}...`"
      :condition-lookups="conditionLookups"
      :separator="$translate('filter.or')"
      :options="options"
      :media-libraries="mediaLibraries"
      :tag-type="tagType"
      @CHANGED_CODES="onChangedCodes"
      @CHANGED_ATTRIBS="onChangedAttribs"
    />
  </div>
</template>

<script>
import VaFilterButton from './va-filter-button.vue';
import VaFilterSearch from './va-filter-search.vue';
import VaSelect from '../va-select.vue';
import VaFilterInput from './va-filter-input.vue';
import { getTagsButtonText } from '@/helpers/filterHelper/filterHelper';

export default {
  components: {
    VaFilterButton,
    VaFilterSearch,
    VaSelect,
    VaFilterInput,
  },
  props: {
    nodeInfo: {
      type: Object,
      default: () => {
        return {};
      },
    },
    conditionLookups: {
      type: Object,
      default: () => {
        return {};
      },
    },
    filterBy: {
      type: [Object, Array],
      default: null,
    },
    mediaLibraries: {
      type: Array,
      default: () => {
        return [];
      },
    },
    tagType: {
      type: String,
      default: '',
    },
  },
  emits: ['remove', 'updateAttribs', 'update'],
  data() {
    return {
      node: {
        nodeType: '',
        subNodes: null,
        codes: [],
        codeCondition: 'In',
        nodeKey: '',
      },
      nodeAttributes: {
        nodeType: '',
        nodeKey: '',
        items: '',
      },
    };
  },
  computed: {
    buttonText() {
      return this.customButtonText || this.predefinedButtonText || '';
    },
    predefinedButtonText() {
      switch (this.node.nodeType) {
        case 'Locations':
          return this.$translate('filter.locations');
        case 'Aspects':
          return this.$translate('filter.screenFormats');
        case 'Tags':
          return getTagsButtonText(this.tagType);
        case 'Players':
          return this.$translate('filter.screenNames');
        case 'Media':
          return this.$translate('filter.mediaNames');
        case 'Layers':
          return this.$translate('filter.layers');
        case 'PlayerStatus':
          return 'Player status';
        case 'SearchText':
          return this.$translate('filter.searchText');
        default:
          return '';
      }
    },
    filterByNode() {
      return Array.isArray(this.filterBy)
        ? this.filterBy?.find((f) =>
            f.key ? f.key === this.node.nodeKey : f.type === this.node.nodeType,
          ) || null
        : null;
    },
    customButtonText() {
      return this.filterByNode?.label || '';
    },
    options() {
      return this.filterByNode?.options || null;
    },
  },
  mounted() {
    this.node.nodeType = this.nodeInfo.nodeType
      ? this.nodeInfo.nodeType
      : this.node.nodeType;
    this.node.codes = this.nodeInfo.codes
      ? this.nodeInfo.codes
      : this.node.codes;
    this.node.codeCondition = this.nodeInfo.codeCondition
      ? this.nodeInfo.codeCondition
      : this.node.codeCondition;
    this.node.nodeKey = this.nodeInfo.nodeKey;

    this.nodeAttributes.nodeType = this.node.nodeType;
    this.nodeAttributes.nodeKey = this.node.nodeKey;
  },
  methods: {
    removeNode() {
      this.$emit('remove', this.node);
    },
    updateNode() {
      this.$emit('updateAttribs', this.nodeAttributes);
      this.$emit('update', this.node);
    },
    onChangedAttribs(attribs) {
      this.nodeAttributes.items = attribs;
    },
    onChangedCodes(codes) {
      this.node.codes = codes;
      this.updateNode();
    },
    onChangedNodeCondition(condition) {
      if (condition === 'Matching') {
        this.node.codes = [];
      }
      this.updateNode();
    },
  },
};
</script>

<style lang="scss" scoped>
.va-filter-node {
  margin-left: -10px;
  display: grid;
  grid-template-columns: 180px 220px 1fr;
  gap: 20px;
  align-items: center;

  .filter-search,
  .filter-input {
    max-width: 500px;
  }
}
</style>
