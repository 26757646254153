const ItemDetails = () => import('@/views/items/ItemDetails.vue');
const ItemsView = () => import('@/views/items/ItemsView.vue');
const ItemImagesView = () =>
  import('@/views/media/itemImages/ItemImagesView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const itemRoutes = [
  {
    path: '/items',
    name: 'ItemsView',
    components: { default: ItemsView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Item', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ translate }) => {
        return [{ name: translate('sidebar.items') }];
      },
    },
  },
  {
    path: '/items/images',
    name: 'ItemsImages',
    components: { default: ItemImagesView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [
          { name: 'Item', action: 'ViewOnly' },
          { name: 'Media', action: 'ViewOnly' },
        ],
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.items'),
            route: { name: 'ItemsView' },
          },
          {
            name: translate('sidebar.itemImages'),
          },
        ];
      },
    },
  },
  {
    path: '/items/new',
    name: 'NewItemDetails',
    components: { default: ItemDetails, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Item', action: 'AddAndEdit' }],
        config: {
          noAccessRedirect: 'ItemsView',
        },
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.items'),
            route: { name: 'ItemsView' },
          },
          {
            name: translate('items.newItem'),
          },
        ];
      },
    },
  },
  {
    path: '/items/:factCode',
    name: 'EditItemDetails',
    components: { default: ItemDetails, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Item', action: 'AddAndEdit' }],
        config: {
          noAccessRedirect: 'ItemsView',
        },
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.items'),
            route: { name: 'ItemsView' },
          },
          {
            name: translate('items.editItem'),
          },
        ];
      },
    },
  },
];
export default itemRoutes;
