<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.22039 12.8864C2.00179 12.105 3.0616 11.666 4.16667 11.666H10.8333C11.9384 11.666 12.9982 12.105 13.7796 12.8864C14.561 13.6678 15 14.7276 15 15.8327V17.4993C15 17.9596 14.6269 18.3327 14.1667 18.3327C13.7064 18.3327 13.3333 17.9596 13.3333 17.4993V15.8327C13.3333 15.1696 13.0699 14.5338 12.6011 14.0649C12.1323 13.5961 11.4964 13.3327 10.8333 13.3327H4.16667C3.50363 13.3327 2.86774 13.5961 2.3989 14.0649C1.93006 14.5338 1.66667 15.1696 1.66667 15.8327V17.4993C1.66667 17.9596 1.29357 18.3327 0.833333 18.3327C0.373096 18.3327 0 17.9596 0 17.4993V15.8327C0 14.7276 0.438987 13.6678 1.22039 12.8864Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.4987 3.33268C6.11799 3.33268 4.9987 4.45197 4.9987 5.83268C4.9987 7.21339 6.11799 8.33268 7.4987 8.33268C8.87941 8.33268 9.9987 7.21339 9.9987 5.83268C9.9987 4.45197 8.87941 3.33268 7.4987 3.33268ZM3.33203 5.83268C3.33203 3.5315 5.19751 1.66602 7.4987 1.66602C9.79988 1.66602 11.6654 3.5315 11.6654 5.83268C11.6654 8.13387 9.79988 9.99935 7.4987 9.99935C5.19751 9.99935 3.33203 8.13387 3.33203 5.83268Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M15.8587 12.4006C15.9738 11.955 16.4283 11.687 16.8739 11.8021C17.7678 12.0329 18.5597 12.554 19.1253 13.2836C19.6909 14.0133 19.9982 14.9101 19.9989 15.8333L19.9989 17.5006C19.9989 17.9608 19.6258 18.3339 19.1656 18.3339C18.7053 18.3339 18.3322 17.9608 18.3322 17.5006V15.8345C18.3322 15.8345 18.3322 15.8346 18.3322 15.8345C18.3318 15.2807 18.1474 14.7425 17.8081 14.3047C17.4687 13.867 16.9936 13.5543 16.4572 13.4158C16.0116 13.3007 15.7436 12.8462 15.8587 12.4006Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5262 2.40223C12.6404 1.95637 13.0944 1.68748 13.5402 1.80163C14.4365 2.03111 15.2309 2.55236 15.7982 3.28321C16.3655 4.01405 16.6734 4.91291 16.6734 5.83809C16.6734 6.76327 16.3655 7.66213 15.7982 8.39298C15.2309 9.12382 14.4365 9.64507 13.5402 9.87455C13.0944 9.98871 12.6404 9.71981 12.5262 9.27396C12.4121 8.8281 12.681 8.37412 13.1268 8.25997C13.6646 8.12228 14.1412 7.80953 14.4816 7.37102C14.822 6.93252 15.0067 6.3932 15.0067 5.83809C15.0067 5.28299 14.822 4.74367 14.4816 4.30516C14.1412 3.86665 13.6646 3.5539 13.1268 3.41622C12.681 3.30206 12.4121 2.84808 12.5262 2.40223Z"
      fill="currentColor"
    />
  </svg>
</template>
