<template>
  <div class="va-field va-choices">
    <va-select
      :data="data"
      :field-model="fieldModelOptions"
      @updatedData="onEmit"
    />
  </div>
</template>

<script>
import { withAsync } from '@/helpers/withAsync';
import { getSignOptions } from '@/api/signsApi';
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      editMode: false,
      fieldModelOptions: '',
    };
  },
  created() {
    this.getSignOptions();
  },
  methods: {
    async getSignOptions() {
      const { response, error } = await withAsync(getSignOptions, {
        name: this.fieldModel.fieldName,
      });

      if (response?.data) {
        this.fieldModelOptions = {
          fieldName: this.fieldModel.fieldName,
          label: this.fieldModel.label,
          options: response.data,
        };
      }
      if (error) {
        this.$store.dispatch('addMessage', {
          message: {
            text: this.langData[this.langData.selected].getDropdownOptionsError,
            buttonFunction: this.onAlertError,
            buttonParameters: error,
            buttonText: this.langData[this.langData.selected].generic.showMore,
            type: 'error',
          },
          time: 10000,
        });
      }
    },
    onEmit() {
      this.$emit('updatedData');
    },
    onAlertError(err) {
      this.$store.dispatch('addMessage', {
        message: { text: err.message, type: 'info' },
        time: 10000,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
