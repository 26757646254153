<template>
  <div class="va-button-container">
    <button
      ref="button"
      data-testid="va-button"
      class="va-button"
      :class="[type, { disabled: disabled }]"
      @click="onClick"
    >
      {{ text }}
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
  methods: {
    onClick() {
      this.$emit('click', this.$refs.button);
    },
  },
};
</script>

<style lang="scss">
.va-button-container {
  display: inline-block;
  margin: 10px;

  .va-button {
    color: $color-text-inverted;
    background-color: $color-button-primary;
    padding-left: 30px;
    padding-right: 30px;
    height: 40px;
    font-weight: 500;
    cursor: pointer;
    border: none;
    border-radius: 19.5px;
    font-family: Roboto, sans-serif;
    font-size: 14px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    outline: none;
    user-select: none;
    transition: background-color 0.2s ease-out;

    &:hover {
      background-color: $hover-button-primary;
    }

    &.confirm {
      color: $color-text-inverted;
      background-color: $color-ui-ok;

      &:hover {
        background-color: $hover-ui-ok;
      }
    }

    &.light-warning {
      background-color: $color-ui-warning;

      &:hover {
        background-color: $hover-ui-warning;
      }
    }

    &.delete,
    &.warning {
      color: $color-text-inverted;
      background-color: $color-ui-danger;

      &:hover {
        background-color: $hover-ui-danger;
      }

      &.confirm {
        color: $color-text-primary;
      }
    }

    &.cancel {
      color: $color-text-secondary;
      background-color: $color-button-secondary;
      border: 1px solid $color-button-border;
      transition:
        border-color 0.2s ease-out,
        color 0.2s ease-out;

      &:hover {
        border: 1px solid $hover-button-border;
        color: $hover-text-secondary;
      }
    }

    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}
</style>
