import { Permission } from '@/helpers/constants';
import { ValidPermission } from '@/enums/permission';

const Overview = () => import('@/views/overview/OverviewView.vue');
const ClientsView = () => import('@/views/overview/clients/ClientsView.vue');
const TemplatesView = () =>
  import('@/views/overview/clients/templates/TemplatesView.vue');
const PlayerFormatsView = () =>
  import('@/views/overview/clients/playerFormats/PlayerFormatsView.vue');
const AccountSettingsView = () =>
  import('@/views/overview/clients/accountSettings/AccountSettingsView.vue');
const CustomersView = () =>
  import('@/views/overview/customers/CustomersView.vue');
const ClientLoginView = () =>
  import('@/views/overview/clients/ClientLoginView.vue');
const PlayerSoftwareView = () =>
  import('@/views/overview/playerSoftware/PlayerSoftwareView.vue');

const overviewRoutes = [
  {
    path: '/overview',
    name: 'OverviewView',
    components: { fullscreen: Overview },
    children: [
      {
        path: 'clients',
        name: 'ClientsView',
        component: ClientsView,
      },
      {
        path: 'clients/:clientCode/:clientName/templates',
        name: 'AccountTemplatesView',
        component: TemplatesView,
        meta: {
          backTo: 'ClientsView',
          permission: {
            resources: [
              { name: 'AccountClientHtmlTemplate', action: 'ViewOnly' },
            ],
            config: {
              noAccessRedirect: 'PlayerFormatsView',
            },
          },
        },
      },
      {
        path: 'clients/:clientCode/:clientName/player-formats',
        name: 'PlayerFormatsView',
        component: PlayerFormatsView,
        meta: {
          backTo: 'ClientsView',
          permission: {
            resources: [
              { name: 'AccountClientPlayoutFormat', action: 'ViewOnly' },
            ],
            config: {
              noAccessRedirect: 'ClientsView',
            },
          },
        },
      },
      {
        path: 'clients/:clientCode/:clientName/account-settings',
        name: 'ClientAccountSettingsView',
        component: AccountSettingsView,
        meta: {
          backTo: 'ClientsView',
          permission: {
            resources: [{ name: 'Account', action: 'ViewOnly' }],
            config: {
              noAccessRedirect: 'AccountTemplatesView',
            },
          },
        },
      },
      {
        path: 'customers',
        name: 'CustomersView',
        component: CustomersView,
      },
      {
        path: 'player-software',
        name: 'PlayerSoftwareView',
        component: PlayerSoftwareView,
        meta: {
          permission: {
            resources: [
              {
                name: Permission.Overview.PlayerSoftware.View,
                action: ValidPermission.VIEW,
              },
            ],
            config: {
              noAccessRedirect: 'ClientsView',
            },
          },
        },
      },
    ],
    meta: {
      permission: {
        resources: [{ name: 'Overview', action: 'ViewOnly' }],
        config: {
          noAccessRedirect: 'Screens',
        },
      },
    },
  },
  {
    path: '/overview/clients/login/:clientCode',
    name: 'ClientLoginView',
    components: { fullscreen: ClientLoginView },
  },
];
export default overviewRoutes;
