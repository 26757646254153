<template>
  <div class="va-and-or">
    <div class="va-and-or-buttons">
      <button
        class="va-and-or-button"
        :class="{ active: active.toUpperCase() === 'AND' }"
        @click="onAndClick"
      >
        <i class="fa fa-plus" aria-hidden="true" />
        {{ $translate('filter.and') }}
      </button>
      <button
        class="va-and-or-button"
        :class="{ active: active.toUpperCase() === 'OR' }"
        data-testid="va-and-or-button--or"
        @click="onOrClick"
      >
        <i class="fa fa-plus" aria-hidden="true" />
        {{ $translate('filter.or') }}
      </button>
    </div>
    <div v-if="active.toUpperCase() === 'OR'" class="break-line" />
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: String,
      default: '',
    },
  },
  emits: ['select'],
  data() {
    return {};
  },
  methods: {
    onAndClick() {
      this.$emit('select', 'AND');
    },
    onOrClick() {
      this.$emit('select', 'OR');
    },
  },
};
</script>

<style lang="scss" scoped>
.va-and-or {
  display: grid;
  grid-template-columns: 175px 1fr;
  gap: 20px;
  margin-left: -5px;
  align-items: center;

  .va-and-or-button {
    color: $color-text-secondary;
    background-color: #181b25;
    height: 31.59px;
    min-width: 69.66px;
    font-weight: 500;
    cursor: pointer;
    border: 0.81px solid #4b4b51;
    border-radius: 15.8px;
    font-family: Roboto, sans-serif;
    font-size: 12.15px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.3px;
    text-align: center;
    outline: none;
    padding: 0 16px;
    margin: 10px 5px;
    transition:
      border-color 0.2s ease-out,
      color 0.2s ease-out;

    &:hover {
      border-color: #bababa;
      color: #fafafa;
    }

    &.active {
      background-color: #5edc88;
      color: #181e24;
      border: none;
    }
  }

  .break-line {
    box-sizing: border-box;
    height: 1px;
    border: 1px solid #4b4b51;
    max-width: 740px;
  }
}
</style>
