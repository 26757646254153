<template>
  <svg
    width="21px"
    height="17px"
    viewBox="0 0 21 17"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="right-text-indent-icon" fill="#BABABA">
        <g id="Group-7">
          <rect id="Rectangle-Copy-3" x="3" y="0" width="17" height="2.5" />
          <rect id="Rectangle-Copy-3" x="11" y="7" width="9" height="2.5" />
          <polygon
            id="Rectangle-Copy-33"
            transform="translate(4.000000, 8.328427) scale(-1, 1) rotate(-45.000000) translate(-4.000000, -8.328427) "
            points="1.29289322 5.62132034 6.70710678 7.03553391 2.70710678 11.0355339"
          />
          <rect id="Rectangle-Copy-3" x="3" y="14" width="17" height="2.5" />
        </g>
      </g>
    </g>
  </svg>
</template>
