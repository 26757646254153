<template>
  <svg
    width="17px"
    height="19px"
    viewBox="0 0 17 19"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="underline-icon" fill="#BABABA">
        <g id="Group-6">
          <path
            id="U"
            d="M8.49333333,14.6 C10.4,14.6 11.8933333,14.13 12.9733333,13.19 C14.0533333,12.25 14.5933333,10.8133333 14.5933333,8.88 L14.5933333,-5.68434189e-14 L11.4533333,-5.68434189e-14 L11.4533333,8.88 C11.4533333,9.94666667 11.24,10.73 10.8133333,11.23 C10.3866667,11.73 9.61333333,11.98 8.49333333,11.98 C7.85333333,11.98 7.34333333,11.89 6.96333333,11.71 C6.58333333,11.53 6.28666666,11.2966667 6.07333333,11.01 C5.86,10.7233333 5.72,10.3933333 5.65333333,10.02 C5.58666666,9.64666667 5.55333333,9.26666667 5.55333333,8.88 L5.55333333,-5.68434189e-14 L2.41333333,-5.68434189e-14 L2.41333333,8.88 C2.41333333,10.8266667 2.94333333,12.2666667 4.00333333,13.2 C5.06333333,14.1333333 6.56,14.6 8.49333333,14.6 Z"
            fill-rule="nonzero"
          />
          <rect id="Rectangle-Copy-2" x="0" y="16.28" width="17" height="2" />
        </g>
      </g>
    </g>
  </svg>
</template>
