<template>
  <svg
    width="7px"
    height="15px"
    viewBox="0 0 7 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="italic-icon" fill="#BABABA" fill-rule="nonzero">
        <polygon id="I" points="3.14 14.28 6.16 0 3 0 -1.42108547e-14 14.28" />
      </g>
    </g>
  </svg>
</template>
