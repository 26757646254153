<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons/status_online" clip-path="url(#clip0_4_869)">
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM4.35824 7.89274C4.83589 7.42733 5.61031 7.42733 6.08796 7.89274L8.11751 9.87024L11.9981 5.60151C12.446 5.10882 13.219 5.0632 13.7247 5.49961C14.2304 5.93602 14.2772 6.68921 13.8293 7.1819L9.08706 12.3984C8.61988 12.9123 7.80484 12.9363 7.30662 12.4509L4.35824 9.57811C3.88059 9.11271 3.88059 8.35814 4.35824 7.89274Z"
        fill="#5EDC88"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_869">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
