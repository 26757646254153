import store from '@/store';
import * as sv_SE from '@/components/lang/sv_SE.json';
import * as en_US from '@/components/lang/en_US.json';
import * as de_DE from '@/components/lang/de_DE.json';
import * as es_ES from '@/components/lang/es_ES.json';
import type { NestedKeyOf } from '@/types/nestedKeyOf';

const get = <T extends object>(object: T, path: NestedKeyOf<T>) => {
  const keys = path.split('.');
  let result = object;
  for (const key of keys) {
    result = result[key as keyof typeof result] as T;
  }
  return result;
};

const languageFiles: { [key: string]: typeof en_US } = {
  en_US,
  sv_SE,
  de_DE,
  es_ES,
};

type Language = 'en_US' | 'sv_SE' | 'de_DE' | 'es_ES';

export const translate = (
  path: NestedKeyOf<typeof en_US>,
  ...args: string[]
) => {
  let labelPath = get(
    languageFiles[store.getters.userLanguage as Language],
    path,
  ) as unknown as string;

  // Check if string, if object just return it.
  if (typeof labelPath === 'string') {
    if (args.length > 0 && labelPath.includes('{}')) {
      for (let i = 0; i < args.length; i++) {
        labelPath = labelPath.replace('{}', args[i]);
      }
    }
  }

  // TODO Check for en_US translation if missing in other language (Same solution as in translationPlugin)
  // This is mainly used for templates where they only translate a few languages

  return labelPath ? labelPath : `Translation not found (${path})`;
};

// Can be removed when all references are updated.
const TranslationHelper = {
  translate,
};

export default TranslationHelper;
