<template>
  <div class="metadata-layer-modal">
    <va-modal ref="va-modal" type="confirm" width="620" @close="onClose">
      <template #header>
        <h3>
          {{ $translate('generic.options') }}
        </h3>
      </template>
      <template #body>
        <div>
          <metadata-layer-modal-fields
            ref="metadata-layer-modal-fields"
            v-model:layer="newLayer"
            :fields="fields"
          />
        </div>
      </template>
      <template #footer>
        <button class="modal-default-button confirm-button" @click="onSave">
          <p>{{ $translate('generic.save') }}</p>
        </button>
        <button class="modal-default-button cancel-button" @click="onClose">
          <p>{{ $translate('generic.cancel') }}</p>
        </button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import VaModal from '@/components/framework/va-modal.vue';
import MetadataLayerModalFields from './metadata-layer-modal-fields.vue';
export default {
  components: {
    VaModal,
    MetadataLayerModalFields,
  },
  props: {
    fields: {
      type: Object,
      required: true,
    },
    layer: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ['update:layer', 'close'],
  data() {
    return {
      newLayer: JSON.parse(JSON.stringify(this.layer)),
    };
  },
  methods: {
    onClose() {
      this.$emit('close');
    },
    onSave() {
      this.$emit('update:layer', this.newLayer);
      this.onClose();
    },
  },
};
</script>

<style lang="scss" scoped></style>
