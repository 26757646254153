<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-1"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="campaign-campaign-EOTF-HZ"
        transform="translate(-1848.000000, -273.000000)"
        fill="currentColor"
      >
        <g id="Group-10" transform="translate(1818.000000, 273.000000)">
          <g id="remove" transform="translate(30.000000, 0.000000)">
            <g id="contact-circle-copy-4">
              <g id="Group-8">
                <circle id="circle" cx="10" cy="10" r="10" />
              </g>
            </g>
            <g
              id="Group"
              transform="translate(6.500000, 6.500000)"
              stroke="#181E24"
              stroke-width="1.43333333"
            >
              <rect
                id="Rectangle-Copy"
                x="-0.716666667"
                y="-0.716666667"
                width="6.43333333"
                height="6.43333333"
                rx="0.333333333"
              />
              <rect
                id="Rectangle-Copy-2"
                x="1.78333333"
                y="1.78333333"
                width="6.43333333"
                height="6.43333333"
                rx="0.333333333"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
