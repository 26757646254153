const getXMPData = (blob) => {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = function (e) {
      const text = e.target.result;
      let xmpmeta = text.match(/<x:xmpmeta[\s\S]*?<\/x:xmpmeta>/gm);
      let parser = new DOMParser();
      let xmlDoc = parser.parseFromString(xmpmeta[0], 'text/xml');

      resolve(xmlDoc);
    };
    fr.onerror = function (e) {
      reject(e);
    };
    fr.readAsText(blob);
  });
};

const getLayersFromBlob = (blob) => {
  return new Promise((resolve, reject) => {
    var fr = new FileReader();
    fr.onload = function (e) {
      const text = e.target.result;
      let layers = [];
      let result = text.match(/{"layers":(.*)?/gm);
      if (result && result.length) {
        let json = JSON.parse(result[0]);
        layers = json.layers;
      }
      resolve(layers);
    };
    fr.onerror = function (e) {
      reject(e);
    };
    fr.readAsText(blob);
  });
};

export default {
  getXMPData,
  getLayersFromBlob,
};
