const getEnv = function (name) {
  return window?.configs?.[name] || import.meta.env[name];
};

export const isEnabled = function (name) {
  return getEnv(name) === 'true' ? true : false;
};

export const features = {
  // Example:
  // SOME_FLAG: 'VITE_SOME_FLAG'
};

export default {
  install: (app) => {
    app.config.globalProperties.$getEnv = getEnv;
    app.config.globalProperties.$isEnabled = isEnabled;
    app.config.globalProperties.$features = features;
  },
  getEnv,
  isEnabled,
  features,
};
