// TODO This file should be deleted and replace with shared/services/auth.js
import axios from 'axios';
import Env from '@/utils/env';

const ACCESS_TOKEN_KEY = 'VA-accesstoken';
const ID_TOKEN_KEY = 'VA-idtoken';

export default {
  isAuthenticated() {
    var credentials;
    credentials = this.getAccessToken();
    return !!credentials;
  },

  getAccessToken() {
    if (window.tempLogin) return window.tempLogin;
    return localStorage.getItem(ACCESS_TOKEN_KEY);
  },

  getIdToken() {
    return localStorage.getItem(ID_TOKEN_KEY);
  },

  setCredentials(accessToken, idToken) {
    localStorage.setItem(ACCESS_TOKEN_KEY, accessToken);
    localStorage.setItem(ID_TOKEN_KEY, idToken);
  },

  clearCredentials() {
    localStorage.removeItem(ACCESS_TOKEN_KEY);
    localStorage.removeItem(ID_TOKEN_KEY);
  },

  extractAccessToken(url) {
    // eslint-disable-next-line no-useless-escape
    var accessToken = url.match(/\&(?:access_token)\=([\S\s]*?)\&/);
    return accessToken ? accessToken[1] : '';
  },

  extractIdToken(url) {
    // eslint-disable-next-line no-useless-escape
    var idToken = url.match(/\#(?:id_token)\=([\S\s]*?)\&/);
    return idToken ? idToken[1] : '';
  },

  getIdentityServerLoginUrl(identityServerUrl, redirectionUrl, clientId) {
    if (!clientId) {
      clientId = 'signage-player-frontend';
    }
    if (identityServerUrl && redirectionUrl) {
      return (
        identityServerUrl +
        '/connect/authorize?response_type=' +
        encodeURI('token id_token') +
        '&redirect_uri=' +
        encodeURI(redirectionUrl + '/signin') +
        '&client_id=' +
        clientId +
        '&nonce=test&scope=' +
        encodeURI('iuser openid') +
        '&state=oauth2'
      );
    } else {
      return (
        Env.getEnv('VITE_API_URL_IDENTITY_SERVER') +
        '/connect/authorize?response_type=' +
        encodeURI('token id_token') +
        '&redirect_uri=' +
        encodeURI(Env.getEnv('VITE_API_URL_SIGNAGE_FRONTEND') + '/signin') +
        '&client_id=' +
        clientId +
        '&nonce=test&scope=' +
        encodeURI('iuser openid') +
        '&state=oauth2'
      );
    }
  },

  getIdentityServerSilentLoginUrl(identityServerUrl, redirectionUrl) {
    if (identityServerUrl && redirectionUrl) {
      return (
        identityServerUrl +
        '/connect/authorize?response_type=' +
        encodeURI('token id_token') +
        '&redirect_uri=' +
        encodeURI(redirectionUrl + '/signin') +
        '&client_id=signage-player-frontend&nonce=test&scope=' +
        encodeURI('iuser openid') +
        '&state=oauth2&prompt=none'
      );
    } else {
      return (
        Env.getEnv('VITE_API_URL_IDENTITY_SERVER') +
        '/connect/authorize?response_type=' +
        encodeURI('token id_token') +
        '&redirect_uri=' +
        encodeURI(Env.getEnv('VITE_API_URL_SIGNAGE_FRONTEND') + '/signin') +
        '&client_id=signage-player-frontend&nonce=test&scope=' +
        encodeURI('iuser openid') +
        '&state=oauth2&prompt=none'
      );
    }
  },

  getIdentityServerLogoutUrl(identityServerUrl, accessToken, idToken) {
    const redirectUri = localStorage.getItem('provider')
      ? `${Env.getEnv(
          'VITE_API_URL_SIGNAGE_FRONTEND',
        )}/logins/${localStorage.getItem('provider')}`
      : Env.getEnv('VITE_API_URL_SIGNAGE_FRONTEND');
    if (identityServerUrl) {
      return (
        identityServerUrl +
        '/connect/endsession?id_token_hint=' +
        idToken +
        '&post_logout_redirect_uri=' +
        encodeURI(redirectUri)
      );
    } else {
      return (
        Env.getEnv('VITE_API_URL_IDENTITY_SERVER') +
        '/connect/endsession?id_token_hint=' +
        idToken +
        '&post_logout_redirect_uri=' +
        encodeURI(redirectUri)
      );
    }
  },

  getUser() {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/users/signedInUser',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when retrieving user');
        });
    });
  },

  confirmUser(password) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/accounts/userEmailConfirmation',
          password,
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when choosing password');
        });
    });
  },
};
