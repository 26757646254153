import AuthService from '@/services/authentication';

const SET_USER = 'SET_USER';
const SET_IS_CLIENT_LOADED = 'SET_IS_CLIENT_LOADED';

const state = {
  userFirstName: '',
  userLastName: '',
  userEmail: '',
  userInitials: '',
  userRole: '',
  userRoleName: '',
  accountCode: '',
  isAccountOwner: false,
  isAdmin: false,
  userAuthorization: {},
  clientAuthorization: {},
  userId: '',
  currentUser: {},
  isClientLoaded: true,
};

const getters = {
  userFirstName: (state) => state.userFirstName,
  userLastName: (state) => state.userLastName,
  userInitials: (state) => state.userInitials,
  userEmail: (state) => state.userEmail,
  userRole: (state) => state.userRole,
  userRoleName: (state) => state.userRoleName,
  userId: (state) => state.userId,
  accountCode: (state) => state.accountCode,
  isAccountOwner: (state) => state.isAccountOwner,
  isAdmin: (state) => state.isAdmin,
  userAuthorization: (state) => state.userAuthorization,
  clientAuthorization: (state) => state.clientAuthorization,
  currentUser: (state) => state.currentUser,
  isClientLoaded: (state) => state.isClientLoaded,
};

const actions = {
  getUser({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      AuthService.getUser()
        .then((user) => {
          commit(SET_USER, { user });
          dispatch('setLang', { langCode: user.UserDetails.languageCode });
          dispatch('setUserDateFormat', {
            dateFormat: user.UserDetails.dateFormat,
          });
          resolve(user);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
  setIsClientLoaded({ commit }, isClientLoaded) {
    commit(SET_IS_CLIENT_LOADED, isClientLoaded);
  },
};

const mutations = {
  [SET_USER](state, { user }) {
    state.userRole =
      user.UserDetails.roles && user.UserDetails.roles.length > 0
        ? user.UserDetails.roles[0].roleCode
        : '';
    state.userRoleName =
      user.UserDetails.roles && user.UserDetails.roles.length > 0
        ? user.UserDetails.roles[0].roleName
        : '';
    state.userFirstName = user.UserDetails.firstName
      ? user.UserDetails.firstName
      : '';
    state.userLastName = user.UserDetails.lastName
      ? user.UserDetails.lastName
      : '';
    state.userEmail = user.UserDetails.email ? user.UserDetails.email : '';
    state.userId = user.UserDetails.id ? user.UserDetails.id : '';
    state.userInitials =
      state.userFirstName.charAt(0).toUpperCase() +
      '' +
      state.userLastName.charAt(0).toUpperCase();
    state.accountCode = user.UserDetails.accountCode;
    state.isAccountOwner = user.UserDetails.isOwner;
    state.isAdmin = user.UserDetails.isAdmin;
    state['userAuthorization'] = user.UserDetails.authorisation;
    state.clientAuthorization = user.UserDetails.clientAuthorization;
    state.currentUser = user.UserDetails;
  },
  [SET_IS_CLIENT_LOADED](state, isClientLoaded) {
    state.isClientLoaded = isClientLoaded;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
