import DateTimeHelper from '@/helpers/dateTimeHelper';
import { dateToLocaleString, timeToLocaleString } from '@/helpers/localization';

export const getPeriodsInText = (app, periods) => {
  const result = [];

  if (periods?.length) {
    periods.forEach((period) => {
      result.push(getPeriodInText(app, period));
    });
  }
  return result;
};

export const getPeriodInText = (app, period) => {
  const $translate = app.$translate;
  let periodArray = [];
  // startDateTime and endDateTime
  if (period.startDateTime || period.endDateTime) {
    let startDateTime = period.startDateTime
      ? dateToLocaleString(period.startDateTime)
      : $translate('broadcast.broadcastDetails.scheduleSection.noStartDate');
    let endDateTime = period.endDateTime
      ? dateToLocaleString(period.endDateTime)
      : $translate('broadcast.broadcastDetails.scheduleSection.noEndDate');
    periodArray.push(`${startDateTime} - ${endDateTime}`);
  }

  // dayOfWeeks
  let daysOfWeeks = period.dayOfWeeks || 127;
  let days = DateTimeHelper.convertWeekdayFlagIntToText(daysOfWeeks);

  if (!Array.isArray(days)) {
    // Special combinations ("Every day", "Weekdays", "Weekends")
    let translation = 'components.vascheduler.specialDayCombinations.' + days;
    periodArray.push($translate(translation));
  } else {
    // Go through each day (in English) and translate as needed
    periodArray.push(
      days
        .map((day) => {
          let translatedDay = $translate(
            'components.vascheduler.abbreviatedDays.' + day,
          );

          if (translatedDay) {
            return translatedDay;
          }
          return translatedDay ? translatedDay : '';
        })
        .filter((translatedDay) => translatedDay)
        .join(', '),
    );
  }

  // weekdayStartTime and weekdayEndTime
  if (period.weekdayStartTime || period.weekdayEndTime) {
    let weekdayStartTime = period.weekdayStartTime
      ? period.weekdayStartTime
      : '00:00';
    let weekdayEndTime = period.weekdayEndTime
      ? period.weekdayEndTime
      : '23:59';
    periodArray.push(
      `${timeToLocaleString(weekdayStartTime)} - ${timeToLocaleString(
        weekdayEndTime,
      )}`,
    );
  }

  return periodArray.join(', ');
};

const inRange = (period, selectedDate) => {
  let dateOk = false;
  let dayOk = false;
  let timeOk = false;
  let start = new Date(period.startDateTime);
  let end = new Date(period.endDateTime);
  let startTime = period.weekdayStartTime?.split(':');
  let startTimeDate = startTime
    ? new Date(selectedDate).setHours(startTime[0], startTime[1])
    : null;
  let endTime = period.weekdayEndTime?.split(':');
  let endTimeDate = endTime
    ? new Date(selectedDate).setHours(endTime[0], endTime[1])
    : null;
  let days = DateTimeHelper.convertWeekdayFlagIntToText(
    period.dayOfWeeks,
    false,
  );
  let selectedDay = DateTimeHelper.getWeekdayName(selectedDate.getDay());

  // Check date
  if (!period.startDateTime && !period.endDateTime) {
    dateOk = true;
  } else if (period.startDateTime && !period.endDateTime) {
    dateOk = selectedDate >= start;
  } else if (!period.startDateTime && period.endDateTime) {
    dateOk = selectedDate < end;
  } else if (period.startDateTime && period.endDateTime) {
    dateOk = selectedDate >= start && selectedDate < end;
  }
  // Check day
  if (
    days.includes(selectedDay) ||
    !period.dayOfWeeks ||
    period.dayOfWeeks === 127
  ) {
    dayOk = true;
  }
  // Check time
  if (!startTimeDate && !endTimeDate) {
    timeOk = true;
  } else if (startTimeDate && !endTimeDate) {
    timeOk = selectedDate >= startTimeDate;
  } else if (!startTimeDate && endTimeDate) {
    timeOk = selectedDate < endTimeDate;
  } else if (startTimeDate && endTimeDate) {
    timeOk = selectedDate >= startTimeDate && selectedDate < endTimeDate;
  }

  return dateOk && dayOk && timeOk;
};

const isDuplicate = (period1, period2) => {
  if (period1.startDateTime !== period2.startDateTime) {
    return false;
  }
  if (period1.endDateTime !== period2.endDateTime) {
    return false;
  }
  if (period1.weekdayStartTime !== period2.weekdayStartTime) {
    return false;
  }
  if (period1.weekdayEndTime !== period2.weekdayEndTime) {
    return false;
  }
  if (period1.dayOfWeeks !== period2.dayOfWeeks) {
    return false;
  }
  return true;
};

const removeDuplicates = (periods = []) => {
  let filteredPeriods = [];
  periods.forEach((period1) => {
    if (!filteredPeriods.find((period2) => isDuplicate(period1, period2))) {
      filteredPeriods.push(period1);
    }
  });
  return filteredPeriods;
};

export default {
  getPeriodsInText,
  getPeriodInText,
  inRange,
  removeDuplicates,
};
