<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16797 3.33268C3.70773 3.33268 3.33464 3.70578 3.33464 4.16602V15.8327C3.33464 16.2929 3.70773 16.666 4.16797 16.666H15.8346C16.2949 16.666 16.668 16.2929 16.668 15.8327V4.16602C16.668 3.70578 16.2949 3.33268 15.8346 3.33268H4.16797ZM1.66797 4.16602C1.66797 2.7853 2.78726 1.66602 4.16797 1.66602H15.8346C17.2153 1.66602 18.3346 2.7853 18.3346 4.16602V15.8327C18.3346 17.2134 17.2153 18.3327 15.8346 18.3327H4.16797C2.78726 18.3327 1.66797 17.2134 1.66797 15.8327V4.16602Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M7.08333 6.66667C6.85321 6.66667 6.66667 6.85321 6.66667 7.08333C6.66667 7.31345 6.85321 7.5 7.08333 7.5C7.31345 7.5 7.5 7.31345 7.5 7.08333C7.5 6.85321 7.31345 6.66667 7.08333 6.66667ZM5 7.08333C5 5.93274 5.93274 5 7.08333 5C8.23393 5 9.16667 5.93274 9.16667 7.08333C9.16667 8.23393 8.23393 9.16667 7.08333 9.16667C5.93274 9.16667 5 8.23393 5 7.08333Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7428 7.74408C13.0682 7.41864 13.5958 7.41864 13.9213 7.74408L18.088 11.9107C18.4134 12.2362 18.4134 12.7638 18.088 13.0893C17.7625 13.4147 17.2349 13.4147 16.9094 13.0893L13.332 9.51184L4.75462 18.0893C4.42918 18.4147 3.90155 18.4147 3.57611 18.0893C3.25067 17.7638 3.25067 17.2362 3.57611 16.9107L12.7428 7.74408Z"
      fill="currentColor"
    />
  </svg>
</template>
