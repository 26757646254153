import playerFilterServices from '@/services/playerFilter';
import mediaFilterServices from '@/services/mediaFilter';
import factsServices from '@/services/facts';

const SET_FILTERS = 'SET_FILTERS';

// state
const state = {
  playerFilters: [],
  mediaFilters: [],
  itemFilters: [],
};

// getters
const getters = {
  playerFilters: (state) => state.playerFilters,
  mediaFilters: (state) => state.mediaFilters,
  itemFilters: (state) => state.itemFilters,
};

// actions
const actions = {
  getPlayerFilters(
    { commit },
    { searchText, orderBy, order, maxItemsInResult, skipItemsInResult },
  ) {
    playerFilterServices
      .getPlayerFilters(
        searchText,
        orderBy,
        order,
        maxItemsInResult,
        skipItemsInResult,
      )
      .then((playerFilters) => {
        commit(SET_FILTERS, {
          target: 'playerFilters',
          filter: playerFilters,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  getMediaFilters(
    { commit },
    { searchText, orderBy, order, maxItemsInResult, skipItemsInResult },
  ) {
    mediaFilterServices
      .getMediaFilters(
        searchText,
        orderBy,
        order,
        maxItemsInResult,
        skipItemsInResult,
      )
      .then((mediaFilters) => {
        commit(SET_FILTERS, {
          target: 'mediaFilters',
          filter: mediaFilters,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
  getItemFilters(
    { commit },
    { searchText, orderBy, order, maxItemsInResult, skipItemsInResult },
  ) {
    factsServices
      .getFactFilters({
        factType: 'Item',
        searchText,
        orderBy,
        order,
        maxItemsInResult,
        skipItemsInResult,
      })
      .then((itemFilters) => {
        commit(SET_FILTERS, {
          target: 'itemFilters',
          filter: itemFilters,
        });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

// mutations
const mutations = {
  [SET_FILTERS](state, { target, filter }) {
    state[target] = filter;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
