<template>
  <svg
    width="14px"
    height="18px"
    viewBox="0 0 14 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icons-sp"
        transform="translate(-763.000000, -685.000000)"
        fill="currentColor"
      >
        <g id="Group-14-Copy-2" transform="translate(740.000000, 664.000000)">
          <g id="users" transform="translate(21.000000, 21.000000)">
            <g id="Group-13" transform="translate(2.500000, 0.500000)">
              <path
                id="Combined-Shape"
                d="M0.0382182246,15.195122 C-0.059342751,14.2804402 0.0298817154,13.2877763 0.305891624,12.2171303 C0.581901532,11.1464844 1.46828682,10.0903678 2.96504749,9.04878049 C4.1357792,10.2195122 5.30651091,10.804878 6.47724261,10.804878 L6.69675481,10.7980183 C7.79431579,10.7294207 8.89187676,10.1463415 9.98943774,9.04878049 C11.4861984,10.0903678 12.3725837,11.1464844 12.6485936,12.2171303 C12.9246035,13.2877763 13.013828,14.2804402 12.916267,15.195122 C11.8525284,16.5609756 9.70618697,17.2439024 6.47724261,17.2439024 L6.47724261,17.2439024 C3.24829826,17.2439024 1.10195679,16.5609756 0.0382182246,15.195122 Z"
              />
              <ellipse
                id="Oval"
                cx="6.47724261"
                cy="4.6097561"
                rx="3.95121951"
                ry="4.31707317"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
