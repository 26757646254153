<template>
  <div class="va-field va-radio" :class="{ label: computedLabel }">
    <label v-if="computedLabel" class="title-label">{{ computedLabel }}</label>
    <label
      v-for="(value, key) in computedOptions"
      :key="key"
      class="radio"
      :for="'_' + uid + key"
    >
      {{ value }}
      <input
        :id="'_' + uid + key"
        type="radio"
        :name="uid + fieldModel.fieldName"
        :checked="data[fieldModel.fieldName] == key"
        @change="select"
      />
      <label :for="'_' + uid + key" class="radio-style" />
    </label>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      editMode: false,
      uid: uuidv4(),
    };
  },
  computed: {
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    computedOptions: function () {
      let computedOptions = {};
      Object.keys(this.fieldModel.options).map((key) => {
        if (
          typeof this.fieldModel.options[key] === 'object' &&
          this.fieldModel.options[key] !== null
        ) {
          if (this.fieldModel.options[key][this.$getUserlanguage()]) {
            return (computedOptions[key] =
              this.fieldModel.options[key][this.$getUserlanguage()]);
          } else {
            return (computedOptions[key] =
              this.fieldModel.options[key]['en_US']);
          }
        } else {
          return (computedOptions[key] = this.fieldModel.options[key]);
        }
      });
      return computedOptions;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (
        !this.data[this.fieldModel.fieldName] &&
        Object.keys(this.fieldModel.options)?.length
      ) {
        var selectData = {
          target: { id: Object.keys(this.fieldModel.options)[0] },
          returnValue: true,
        };
        this.select(selectData);
      }
    });
  },
  methods: {
    select: function (e) {
      if (e.returnValue) {
        // TODO Fix no-mutating-props issue
        // eslint-disable-next-line vue/no-mutating-props
        this.data[this.fieldModel.fieldName] = e.target.id.replace(
          '_' + this.uid,
          '',
        );
        this.$emit('updatedData');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.va-radio {
  position: relative;
  text-align: left;

  .radio {
    display: inline-block;
    position: relative;
    padding-left: 1.9rem;
    margin: 0 1rem 0.5rem 0;
    cursor: pointer;
    user-select: none;
    color: $color-text-secondary;
    font-size: 0.8rem;
    line-height: 20px;

    input {
      position: absolute;
      opacity: 0;
    }

    .radio-style {
      position: absolute;
      top: 0;
      left: 0;
      height: 20px;
      width: 20px;
      margin: 0;
      padding: 0;
      background-color: #4b4b51;
      border-radius: 50%;
    }

    .radio-style::after {
      content: '';
      position: absolute;
      display: none;
      top: 6px;
      left: 6px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #fafafa;
    }

    input:checked ~ .radio-style::after {
      display: block;
    }

    input:checked ~ .radio-style {
      background-color: $color-ui-primary;
    }
  }
}
</style>
