<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="icons/status_offline">
      <path
        id="Subtract"
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M18 9C18 13.9706 13.9706 18 9 18C4.02944 18 0 13.9706 0 9C0 4.02944 4.02944 0 9 0C13.9706 0 18 4.02944 18 9ZM12.4805 7.23027C12.9638 6.74699 12.9638 5.96345 12.4805 5.48018L12.3923 5.39991C11.9061 4.99858 11.1853 5.02533 10.7305 5.48018L8.98037 7.23027L7.23028 5.48018C6.747 4.99691 5.96346 4.99691 5.48019 5.48018L5.39992 5.56846C4.99859 6.0546 5.02534 6.77542 5.48019 7.23027L7.23028 8.98036L5.48019 10.7304C4.99692 11.2137 4.99692 11.9973 5.48019 12.4805L5.56847 12.5608C6.05461 12.9621 6.77543 12.9354 7.23028 12.4805L8.98037 10.7304L10.7305 12.4805C11.2137 12.9638 11.9973 12.9638 12.4805 12.4805L12.5608 12.3923C12.9621 11.9061 12.9354 11.1853 12.4805 10.7304L10.7305 8.98036L12.4805 7.23027Z"
        fill="#FF5C7E"
      />
    </g>
  </svg>
</template>
