<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_117_1481)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.9987 2.50065C5.85656 2.50065 2.4987 5.85852 2.4987 10.0007C2.4987 14.1428 5.85656 17.5007 9.9987 17.5007C14.1408 17.5007 17.4987 14.1428 17.4987 10.0007C17.4987 5.85852 14.1408 2.50065 9.9987 2.50065ZM0.832031 10.0007C0.832031 4.93804 4.93609 0.833984 9.9987 0.833984C15.0613 0.833984 19.1654 4.93804 19.1654 10.0007C19.1654 15.0633 15.0613 19.1673 9.9987 19.1673C4.93609 19.1673 0.832031 15.0633 0.832031 10.0007Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M0.832031 9.99935C0.832031 9.53911 1.20513 9.16602 1.66536 9.16602H18.332C18.7923 9.16602 19.1654 9.53911 19.1654 9.99935C19.1654 10.4596 18.7923 10.8327 18.332 10.8327H1.66536C1.20513 10.8327 0.832031 10.4596 0.832031 9.99935Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.9987 0.833984C10.2328 0.833984 10.4561 0.932452 10.614 1.1053C12.8346 3.53641 14.0966 6.69136 14.1652 9.98329C14.1654 9.99486 14.1654 10.0064 14.1652 10.018C14.0966 13.3099 12.8346 16.4649 10.614 18.896C10.4561 19.0689 10.2328 19.1673 9.9987 19.1673C9.7646 19.1673 9.54129 19.0689 9.38341 18.896C7.16277 16.4649 5.90079 13.3099 5.83221 10.018C5.83197 10.0064 5.83197 9.99486 5.83221 9.98329C5.90079 6.69136 7.16277 3.53641 9.38341 1.1053C9.54129 0.932452 9.7646 0.833984 9.9987 0.833984ZM7.49889 10.0007C7.556 12.563 8.43724 15.0299 9.9987 17.0417C11.5601 15.0299 12.4414 12.563 12.4985 10.0007C12.4414 7.43828 11.5602 4.97136 9.9987 2.95965C8.43724 4.97136 7.556 7.43828 7.49889 10.0007Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_117_1481">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
