<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="start" transform="translate(-109.000000, -526.000000)">
        <g
          id="card-screens-locations"
          transform="translate(96.000000, 406.000000)"
        >
          <g id="Group-12" transform="translate(0.000000, 106.000000)">
            <g id="custom-copy-4" transform="translate(13.000000, 14.000000)">
              <g
                id="portrait"
                transform="translate(11.999999, 11.999999) rotate(-180.000000) translate(-11.999999, -11.999999) translate(-0.000001, -0.000001)"
                fill="currentColor"
                opacity="0.75"
              >
                <g id="Group-2">
                  <circle id="Oval-2" cx="12" cy="12" r="12" />
                </g>
              </g>
              <path
                id="broadcast-icon"
                d="M11.9195959,13.7127942 C13.0783899,13.7127942 14.0055201,14.0321516 14.6465731,14.6903248 C14.9276696,14.9789283 14.9041228,15.4249357 14.5939798,15.68651 C14.2838367,15.9480844 13.8045424,15.9261729 13.5234459,15.6375693 C13.1958429,15.3012172 12.6793641,15.123312 11.9195959,15.123312 C11.1692167,15.123312 10.6608732,15.310241 10.3319357,15.670772 C10.0610314,15.9676959 9.58275113,16.0040409 9.2636668,15.751951 C8.94458247,15.499861 8.90552483,15.0547973 9.17642913,14.7578734 C9.8161477,14.0567116 10.7514132,13.7127942 11.9195959,13.7127942 Z M11.9195959,10.7910072 C13.6549008,10.7910072 15.2561851,11.3909348 16.696573,12.5687703 C17.0109967,12.8258812 17.0419022,13.2714994 16.7656022,13.5640864 C16.4893023,13.8566734 16.0104261,13.8854324 15.6960024,13.6283215 C14.5237963,12.669784 13.2739527,12.2015251 11.9195959,12.2015251 C10.5652392,12.2015251 9.31539558,12.669784 8.14318953,13.6283215 C7.82876579,13.8854324 7.34988963,13.8566734 7.07358968,13.5640864 C6.79728974,13.2714994 6.82819518,12.8258812 7.14261892,12.5687703 C8.58300684,11.3909348 10.1842911,10.7910072 11.9195959,10.7910072 Z M11.9195959,7.91959595 C14.6766537,7.91959595 17.0171584,8.79809944 18.8970362,10.5436552 C19.1933311,10.8187791 19.193848,11.2653241 18.8981907,11.5410413 C18.6025333,11.8167586 18.1226612,11.8172396 17.8263663,11.5421157 C16.2341106,10.0636307 14.2798785,9.33011378 11.9195959,9.33011378 C9.55931344,9.33011378 7.60508132,10.0636307 6.01282564,11.5421157 C5.7165307,11.8172396 5.23665858,11.8167586 4.94100125,11.5410413 C4.64534392,11.2653241 4.6458608,10.8187791 4.94215574,10.5436552 C6.82203351,8.79809944 9.16253824,7.91959595 11.9195959,7.91959595 Z"
                fill="#3B3B40"
                fill-rule="nonzero"
                transform="translate(11.919596, 11.919596) rotate(-315.000000) translate(-11.919596, -11.919596) "
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
