<template>
  <svg
    width="15px"
    height="15px"
    viewBox="0 0 15 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="start-hover-screens"
        transform="translate(-351.000000, -425.000000)"
        fill="currentColor"
      >
        <g id="Group-3-Copy-19" transform="translate(351.000000, 425.000000)">
          <rect id="Rectangle" x="0" y="10" width="15" height="2" rx="1" />
          <rect
            id="Rectangle-Copy-2"
            x="0"
            y="3"
            width="15"
            height="2"
            rx="1"
          />
          <rect id="Rectangle" x="13" y="0" width="2" height="5" rx="1" />
          <rect
            id="Rectangle"
            x="3.41060513e-13"
            y="0"
            width="2"
            height="5"
            rx="1"
          />
          <rect id="Rectangle" x="13" y="10" width="2" height="5" rx="1" />
          <rect
            id="Rectangle"
            x="4.54747351e-13"
            y="10"
            width="2"
            height="5"
            rx="1"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
