const getRandomColor = () => {
  let colors = [
    '#F47C7C', // Red
    '#70A1D7', // Blue
    '#CE9DD9', // Purple
    '#F7F48B', // Yellow
    '#A1DE93', // Green
    '#74BAC3', // Turquoise
  ];
  let r = Math.floor(Math.random() * colors.length);
  return colors[r];
};

const componentToHex = (c) => {
  var hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
};

const rgbToHex = (r, g, b) => {
  return '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
};

export default {
  getRandomColor,
  rgbToHex,
};
