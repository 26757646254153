<template>
  <div class="metadata-layer-modal-fields">
    <div class="fields columns-4">
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.fontFamily') }}
      </span>
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.fontStyle') }}
      </span>
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.align') }}
      </span>
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.currency') }}
      </span>
      <va-select
        ref="font-family"
        v-model="newLayer.fontFamily"
        :options="fields.fontFamily"
        @update:modelValue="emitUpdateLayer"
      />
      <va-select
        ref="font-style"
        v-model="newLayer.fontStyle"
        :options="fields.fontStyle"
        @update:modelValue="emitUpdateLayer"
      />
      <va-select
        ref="align"
        v-model="newLayer.align"
        :options="fields.align"
        @update:modelValue="emitUpdateLayer"
      />
      <va-select
        ref="currency"
        v-model="newLayer.currency"
        :options="fields.currency"
        @update:modelValue="emitUpdateLayer"
      />
    </div>
    <div class="fields columns-6">
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.color') }}
      </span>
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.fontSize') }}
      </span>
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.xPos') }}
      </span>
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.yPos') }}
      </span>
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.inTime') }}
      </span>
      <span class="field-label">
        {{ $translate('formFields.vaMetadataLayers.labels.outTime') }}
      </span>
      <va-input
        ref="color"
        v-model="newLayer.color"
        placeholder="#"
        @update:model-value="emitUpdateLayer"
      />
      <va-number-picker
        ref="font-size"
        v-model="newLayer.fontSize"
        @update:model-value="emitUpdateLayer"
      />
      <va-number-picker
        ref="x-position"
        v-model="newLayer.x"
        @update:model-value="emitUpdateLayer"
      />
      <va-number-picker
        ref="y-position"
        v-model="newLayer.y"
        @update:model-value="emitUpdateLayer"
      />
      <va-number-picker
        ref="in-time"
        v-model="newLayer.inTime"
        @update:model-value="emitUpdateLayer"
      />
      <va-number-picker
        ref="out-time"
        v-model="newLayer.outTime"
        @update:model-value="emitUpdateLayer"
      />
    </div>
  </div>
</template>

<script>
import VaInput from '@/components/framework/va-input.vue';
import VaSelect from '@/components/framework/va-select.vue';
import VaNumberPicker from '@/components/framework/va-number-picker.vue';
export default {
  components: {
    VaInput,
    VaSelect,
    VaNumberPicker,
  },
  props: {
    fields: {
      type: Object,
      default: () => {
        return {
          fontFamily: [],
          fontStyle: [],
          align: [],
          currency: [],
        };
      },
    },
    layer: {
      type: Object,
      required: true,
    },
  },
  emits: ['update:layer'],
  computed: {
    newLayer() {
      return this.layer;
    },
  },
  methods: {
    emitUpdateLayer() {
      this.$emit('update:layer', this.newLayer);
    },
  },
};
</script>

<style lang="scss" scoped>
.metadata-layer-modal-fields {
  .fields {
    display: grid;
    grid-gap: 20px;

    &.columns-4 {
      grid-template-columns: 150px 100px 100px 100px;
    }

    &.columns-6 {
      grid-template-columns: 85px 85px 85px 85px 85px 85px;
    }

    .field-label {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
</style>
