import api from '@/api/api';

const URLS = {
  aspectsUrl: 'aspects',
};

export const getAspects = ({
  searchText,
  orderBy,
  order,
  maxItemsInResult,
  skipItemsInResult,
}) => {
  return api.get(URLS.aspectsUrl, {
    params: {
      SearchText: searchText,
      OrderBy: orderBy,
      Order: order,
      MaxItemsInResult: maxItemsInResult,
      SkipItemsInResult: skipItemsInResult,
    },
  });
};
