<template>
  <div class="va-field va-metadata-layers">
    <label class="title-label">{{ computedLabel }}</label>
    <metadata-layer-list
      ref="metadata-layer-list"
      :layers="layers"
      :modal-fields="modalFields"
      @update:layers="onChange"
    />
  </div>
</template>

<script>
import formHelper from '@/helpers/form-helper';
import fileReader from '@/helpers/file-reader';
import imageServices from '@/services/image';
import { v4 as uuidv4 } from 'uuid';

import MetadataLayerList from './metadata-layer-list.vue';
import { withAsync } from '@/helpers/withAsync';
import { getMetadata } from '@/api/mediaApi';
export default {
  components: {
    MetadataLayerList,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  computed: {
    computedLabel() {
      // TODO Use this helper function in all form components
      return formHelper.getTranslatedLabel(
        this.fieldModel.label,
        this.$getUserlanguage(),
      );
    },
    layers() {
      return this.data[this.fieldModel.fieldName] || [{ id: uuidv4() }];
    },
    isLoadedFromMedia() {
      return this.layers.find((layer) => layer.mediaCode)?.mediaCode;
    },
    modalFields() {
      return this.fieldModel.modalFields || {};
    },
    media() {
      if (this.fieldModel.mediaFieldName) {
        let mediaField = this.data[this.fieldModel.mediaFieldName];
        if (
          mediaField &&
          (mediaField.type === 'video' || mediaField.type === 'photo')
        ) {
          return mediaField.media;
        }
        return null;
      }
      return null;
    },
  },
  watch: {
    media: {
      immediate: true,
      handler(newVal) {
        if (newVal && this.isLoadedFromMedia !== newVal) {
          this.getLayersFromMedia(newVal);
        }
      },
    },
  },
  methods: {
    onChange(layers) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] =
        formHelper.addIndexToMetadataLayers(layers);
      this.$emit('updatedData');
    },
    getLayersFromMedia(mediaCode) {
      this.getMediaMetadata(mediaCode);
    },
    async getMediaMetadata(mediaCode) {
      const { response, error } = await withAsync(getMetadata, mediaCode);
      if (response?.data) {
        this.getMedia(response?.data.previewUrl);
      }
      if (error) {
        this.handleError(this.$translate('generic.errorMessage'));
      }
    },
    getMedia(src) {
      imageServices
        .getImage(src)
        .then((response) => {
          this.getLayersFromBlob(response.data);
        })
        .catch(() => {
          this.handleError(this.$translate('generic.errorMessage'));
        });
    },
    getLayersFromBlob(blob) {
      fileReader
        .getLayersFromBlob(blob)
        .then((layers) => {
          if (layers && layers.length) {
            let mappedLayers = formHelper.mapMetadataLayersFromMedia(
              layers,
              this.media,
            );
            this.onChange(mappedLayers);
          } else {
            this.handleError(
              this.$translate('formFields.vaMetadataLayers.noLayersFound'),
              'warning',
            );
          }
        })
        .catch(() => {
          this.handleError(this.$translate('generic.errorMessage'));
        });
    },
    handleError(text = '', type = 'error', time = 10000) {
      this.$store.dispatch('addMessage', {
        message: { text: text, type: type },
        time: time,
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
