import sv_SE from '@/components/lang/sv_SE.json';
import en_US from '@/components/lang/en_US.json';
import de_DE from '@/components/lang/de_DE.json';
import es_ES from '@/components/lang/es_ES.json';
import { updateUser } from '@/api/userApi';
import { withAsync } from '@/helpers/withAsync';

const SET_LANG = 'SET_LANG';
const SET_USER_DATE_FORMAT = 'SET_USER_DATE_FORMAT';
const DEFAULT_DATE_FORMAT = navigator.language?.slice(0, 2);

// state
const state = {
  langData: {
    selected: 'en_US',
    sv_SE,
    en_US,
    de_DE,
    es_ES,
  },
  userLanguage: 'en_US', // This is going to be used by the translate/language plugin.
  userDateFormat: DEFAULT_DATE_FORMAT,
};

// getters
const getters = {
  langData: (state) => state.langData,
  userLanguage: (state) => state.userLanguage,
  userDateFormat: (state) => state.userDateFormat,
};

// actions
const actions = {
  setLang({ state, commit }, { langCode }) {
    let validLanguage = Object.keys(state.langData).filter(
      (element) => ~element.indexOf('_'),
    );

    if (!langCode) {
      commit(SET_LANG, 'en_US');
    }

    if (!validLanguage.includes(langCode)) {
      commit(SET_LANG, 'en_US');
    } else {
      commit(SET_LANG, langCode);
    }
  },
  setUserDateFormat({ commit }, { dateFormat }) {
    if (dateFormat) {
      commit(SET_USER_DATE_FORMAT, { dateFormat });
    } else {
      commit(SET_USER_DATE_FORMAT, {
        dateFormat: DEFAULT_DATE_FORMAT,
      });
    }
  },
  async updateLang(
    { dispatch },
    { userCode, firstName, lastName, email, langCode, dateFormat },
  ) {
    dispatch('setLang', { langCode: langCode });
    dispatch('setUserDateFormat', { dateFormat: dateFormat });

    await withAsync(updateUser, userCode, {
      firstName,
      lastName,
      email,
      langCode,
      dateFormat,
    });
  },
};

// mutations
const mutations = {
  [SET_LANG](state, lang) {
    state.langData['selected'] = lang;

    // This is going to be used by the translate/language plugin.
    state.userLanguage = lang;
  },
  [SET_USER_DATE_FORMAT](state, { dateFormat }) {
    state.userDateFormat = dateFormat;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
