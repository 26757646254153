<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
  >
    <path
      d="M18.5149 6.54545L13.4568 1.48892C13.3019 1.33396 13.1181 1.21104 12.9158 1.12716C12.7134 1.04329 12.4965 1.00012 12.2774 1.00012C12.0584 1.00012 11.8415 1.04329 11.6391 1.12716C11.4368 1.21104 11.2529 1.33396 11.0981 1.48892L6.76761 5.8338C5.73165 5.55631 3.71057 5.35715 1.62199 7.04211C1.4396 7.18882 1.29017 7.37231 1.18341 7.58061C1.07666 7.78891 1.01499 8.01735 1.00241 8.25107C0.989829 8.48479 1.02662 8.71853 1.1104 8.93708C1.19418 9.15564 1.32305 9.3541 1.48864 9.51954L5.27743 13.3086L1.96036 16.6252C1.77248 16.813 1.66693 17.0678 1.66693 17.3335C1.66693 17.5992 1.77248 17.854 1.96036 18.0418C2.14825 18.2297 2.40307 18.3352 2.66878 18.3352C2.93449 18.3352 3.18932 18.2297 3.3772 18.0418L6.69427 14.7244L10.4839 18.5126C10.7961 18.8245 11.2194 18.9998 11.6607 19.0001C11.699 19.0001 11.7382 19.0001 11.7774 18.996C12.0153 18.9788 12.2467 18.9107 12.4561 18.7964C12.6654 18.6822 12.8478 18.5243 12.9909 18.3335C13.3817 17.8143 13.9076 16.9893 14.1943 16.0002C14.481 15.0111 14.4819 14.0936 14.2077 13.2169L18.5165 8.89456C18.8265 8.58211 19.0003 8.15975 19 7.71966C18.9997 7.27957 18.8253 6.85746 18.5149 6.54545ZM12.3258 12.2778C12.1778 12.4263 12.0803 12.6175 12.0469 12.8245C12.0136 13.0314 12.0461 13.2436 12.1399 13.4311C12.4258 14.0028 12.715 15.1811 11.6165 16.8185L3.17384 8.3779C4.93156 7.1621 6.46841 7.82458 6.59092 7.87792C6.77761 7.97483 6.99069 8.00862 7.1982 7.97421C7.40572 7.9398 7.59649 7.83906 7.7419 7.68709L12.2783 3.13638L16.8622 7.71959L12.3258 12.2778Z"
      fill="currentColor"
    />
  </svg>
</template>
