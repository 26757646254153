<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="icons-sp" transform="translate(-190.000000, -351.000000)">
        <g id="Group-Copy-7" transform="translate(170.000000, 331.000000)">
          <g
            id="contact-circle-copy-4-no-contact-circle"
            transform="translate(20.000000, 20.000000)"
          >
            <g id="Group-8">
              <circle id="circle" fill="currentColor" cx="10" cy="10" r="10" />
              <g
                id="Group-Copy"
                transform="translate(10.171573, 10.131728) rotate(-315.000000) translate(-10.171573, -10.131728) translate(4.421573, 4.381728)"
                fill="#181E24"
                fill-rule="nonzero"
              >
                <path
                  id="cross"
                  d="M5.50467729,0.278166503 C6.26406882,0.278166503 6.87967729,0.893774972 6.87967729,1.6531665 L6.87967729,1.6531665 L6.87967729,4.4031665 L9.62967729,4.4031665 C10.3443987,4.4031665 10.9317552,4.94848058 10.9983829,5.64574483 L11.0046773,5.7781665 C11.0046773,6.53755803 10.3890688,7.1531665 9.62967729,7.1531665 L9.62967729,7.1531665 L6.87967729,7.1531665 L6.87967729,9.9031665 C6.87967729,10.6178879 6.33436321,11.2052445 5.63709897,11.2718721 L5.50467729,11.2781665 C4.74528576,11.2781665 4.12967729,10.662558 4.12967729,9.9031665 L4.12967729,9.9031665 L4.12967729,7.1531665 L1.37967729,7.1531665 C0.66495585,7.1531665 0.0775993356,6.60785243 0.0109716538,5.91058818 L0.00467729056,5.7781665 C0.00467729056,5.01877497 0.62028576,4.4031665 1.37967729,4.4031665 L1.37967729,4.4031665 L4.12967729,4.4031665 L4.12967729,1.6531665 C4.12967729,0.938445062 4.67499137,0.351088548 5.37225561,0.284460866 Z"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
