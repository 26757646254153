export enum TagType {
  PLAYER = 'Player',
  MEDIA = 'Media',
  CONTENT_TYPE = 'ContentType',
  AI = 'AI',
}

export enum Area {
  SCREEN = 'Screen',
  SEARCH_SCREEN_FILTER = 'SearchScreenFilter',
  BROADCAST_SCREEN_FILTER = 'BroadcastScreenFilter',
  COMMUNICATION_MIX_SCREEN_FILTER = 'CommunicationMixScreenFilter',
  ROLE_SCREEN_FILTER = 'RoleScreenFilter',
  MEDIA = 'Media',
  ITEM_IMAGES = 'ItemImages',
  SEARCH_MEDIA_FILTER = 'SearchMediaFilter',
  ROLE_MEDIA_FILTER = 'RoleMediaFilter',
}
