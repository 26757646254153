import layersServices from '@/services/layers';

const SET_LAYERS = 'SET_LAYERS';
const SET_SHOW_LAYERS = 'SET_SHOW_LAYERS';

// state
const state = {
  layers: [],
  showLayers: false,
};

// getters
const getters = {
  layers: (state) => state.layers,
  showLayers: (state) => state.showLayers,
};

// actions
const actions = {
  getLayers(
    { commit },
    { searchText, orderBy, order, maxItemsInResult, skipItemsInResult },
  ) {
    layersServices
      .getLayers(
        searchText,
        orderBy,
        order,
        maxItemsInResult,
        skipItemsInResult,
      )
      .then((responseData) => {
        let layers = responseData.layers;
        let showLayers = responseData.hasPlayoutsWithMultiLayers;
        commit(SET_LAYERS, { layers });
        commit(SET_SHOW_LAYERS, { showLayers });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

// mutations
const mutations = {
  [SET_LAYERS](state, { layers }) {
    state.layers = layers;
  },
  [SET_SHOW_LAYERS](state, { showLayers }) {
    state.showLayers = showLayers;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
