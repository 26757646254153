<template>
  <svg
    width="18px"
    height="12px"
    viewBox="0 0 18 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icons-sp"
        transform="translate(-151.000000, -847.000000)"
        fill="currentColor"
      >
        <g id="Group-Copy-18" transform="translate(130.000000, 823.000000)">
          <g id="reorder" transform="translate(21.000000, 24.000000)">
            <g id="Group-4">
              <rect
                id="Rectangle-Copy-8"
                x="0"
                y="0"
                width="18"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-9"
                x="0"
                y="5"
                width="18"
                height="2"
                rx="1"
              />
              <rect
                id="Rectangle-Copy-10"
                x="0"
                y="10"
                width="18"
                height="2"
                rx="1"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
