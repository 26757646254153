import { v4 as uuidv4 } from 'uuid';
import colors from '@/helpers/colors';

const createEmptyChildNode = (inModelField) => {
  let copy = {};
  if (inModelField && inModelField.fields) {
    for (let currentField of inModelField.fields) {
      if (currentField.type === 'va-fieldset') {
        copy[currentField.fieldName] = [];
        copy[currentField.fieldName].splice(
          copy[currentField.fieldName].length,
          0,
          createEmptyChildNode(currentField),
        );
      } else if (currentField.fieldName) {
        copy[currentField.fieldName] = '';
      }
    }
  }
  return copy;
};

export const getTranslatedLabel = (label, userLanguage = 'en_US') => {
  if (typeof label === 'object' && label !== null) {
    return (
      label[userLanguage] || label['en_US'] || label[Object.keys(label)[0]]
    );
  } else {
    return label;
  }
};

export const getFieldSetTitle = (
  fieldModel,
  data,
  formFacts,
  fieldGroupIndex,
  userLanguage = 'en_US',
) => {
  let title = getTranslatedLabel(fieldModel.title, userLanguage);

  if (fieldModel.titleFields) {
    fieldModel.titleFields.forEach((field) => {
      let value = '';
      const fieldValue = data[fieldModel.fieldName]?.[fieldGroupIndex]?.[field];
      if (
        field === 'Item.Code' &&
        formFacts?.some((facts) => facts.type === 'Items')
      ) {
        const jsonString = formFacts.find(
          (facts) => facts.type === 'Items',
        ).json;
        const json = JSON.parse(jsonString);
        value = json.ItemsInStore.find(
          (item) => item.ItemCode === fieldValue,
        )?.Attributes.find((attribute) => attribute.Id === 'Name')?.Value;
      } else {
        value = fieldValue;
      }

      title = title.replace(`{${field}}`, value || '');
    });
  }

  return title;
};

const mapMetadataLayersFromMedia = (layers = [], mediaCode) => {
  return layers.map((layer) => ({
    id: uuidv4(),
    layerName: layer.layerName,
    x: layer.position?.[0],
    y: layer.position?.[1],
    fontFamily: layer.fontFamily,
    fontStyle: layer.fontStyle,
    fontSize: Number(layer.fontSize),
    align: layer.paragraphAlign,
    currency: layer.currency,
    color: layer.fillColor
      ? colors.rgbToHex(
          layer.fillColor[0],
          layer.fillColor[1],
          layer.fillColor[2],
        )
      : '',
    inTime: Number(layer.layerInPoint),
    outTime: Number(layer.layerOutPoint),
    rawLayerData: layer,
    mediaCode: mediaCode,
  }));
};

const addIndexToMetadataLayers = (layers = []) => {
  layers.forEach((layer, index) => {
    layer.rowNumber = index;
  });
  return layers;
};

export default {
  createEmptyChildNode,
  getTranslatedLabel,
  getFieldSetTitle,
  mapMetadataLayersFromMedia,
  addIndexToMetadataLayers,
};
