import api from '@/api/api.js';

const URLS = {
  usersUrl: 'users',
  deleteUserUrl: 'users/{userId}',
  usersRoles: 'users/roles',
  deleteRole: 'roles/{roleCode}',
  forgotPassword: 'users/forgotPassword',
  inviteUser: 'users/inviteUser',
  usersUserCodeLocations: '/users/{userCode}/locations',
  userOwner: 'users/owner',
  userModifyOwner: 'users/modifyOwner',
  userSettings: 'users/{userId}/settings',
  userEmailConfirmation: 'users/userEmailConfirmation',
  confirmNewPassword: 'users/confirmNewPassword',
};

export const getUsers = (
  { searchText, orderBy, order, maxItemsInResult, skipItemsInResult, role },
  config,
) => {
  return api.get(URLS.usersUrl, {
    params: {
      SearchText: searchText,
      OrderBy: orderBy,
      Order: order,
      MaxItemsInResult: maxItemsInResult,
      SkipItemsInResult: skipItemsInResult,
      Role: role,
    },
    ...config,
  });
};

export const inviteUser = ({ userEmail, userRoles, userLocations }) => {
  return api.post(URLS.inviteUser, {
    userEmail: userEmail,
    userRoles: userRoles,
    userLocations: userLocations,
  });
};

export const forgotPassword = (email) => {
  return api.post(URLS.forgotPassword, { userEmail: email });
};

export const deleteUser = (userId) => {
  return api.delete(URLS.deleteUserUrl.replace('{userId}', userId));
};

export const getRoles = () => {
  return api.get(URLS.usersRoles);
};

export const deleteRole = (roleCode) => {
  return api.delete(URLS.deleteRole.replace('{roleCode}', roleCode));
};

export const updateUser = (userCode, userDetails, config = {}) => {
  const payload = {
    UserCode: userCode,
    FirstName: userDetails.firstName,
    LastName: userDetails.lastName,
    Email: userDetails.email,
    LanguageCode: userDetails.langCode,
    DateFormat: userDetails.dateFormat,
    DefaultClientCode: userDetails.defaultClientCode,
    LocationCodes: userDetails.locations,
    RoleCodes: userDetails.roles,
  };
  return api.put(URLS.usersUrl, payload, config);
};

export const updateUserRoles = (userCode, roleCodes) => {
  return api.put(URLS.usersRoles, {
    UserCode: userCode,
    RoleCodes: roleCodes,
  });
};

export const updateUserLocations = (userCode, locations) => {
  return api.put(URLS.usersUserCodeLocations.replace('{userCode}', userCode), {
    LocationCodes: locations,
  });
};

export const getOwner = () => {
  return api.get(URLS.userOwner);
};

export const modifyOwner = (toUserId) => {
  return api.post(URLS.userModifyOwner, {
    ToUserId: toUserId,
  });
};

export const getUserSettings = (userId) => {
  return api.get(URLS.userSettings.replace('{userId}', userId));
};

export const updateUserSettings = (userId, settings) => {
  return api.put(URLS.userSettings.replace('{userId}', userId), {
    Settings: settings,
  });
};

export const userEmailConfirmation = (
  userId,
  token,
  firstName,
  lastName,
  password,
) => {
  return api.post(URLS.userEmailConfirmation, {
    userId: userId,
    password: password,
    token: token,
    firstName: firstName,
    lastName: lastName,
  });
};

export const confirmNewPassword = (userId, token, password) => {
  return api.post(URLS.confirmNewPassword, {
    userId: userId,
    newPassword: password,
    token: token,
  });
};
