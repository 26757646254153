import { Permission } from '@/helpers/constants';

const ShareOfVoiceView = () =>
  import('@/views/shareOfVoice/ShareOfVoiceView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');
const ContentTypesView = () =>
  import('@/views/shareOfVoice/contentType/ContentTypesView.vue');
const ContentMixesView = () =>
  import('@/views/shareOfVoice/contentMix/ContentMixesView.vue');
const ContentMixesDetails = () =>
  import('@/views/shareOfVoice/contentMix/details/ContentMixDetails.vue');

const shareOfVoiceRoutes = [
  {
    path: '/shareOfVoice/',
    name: 'ShareOfVoiceView',
    components: { default: ShareOfVoiceView, 'side-bar': SideBar },
    children: [
      {
        path: 'contentMixes',
        name: 'ContentMixesView',
        component: ContentMixesView,
        meta: {
          permission: {
            resources: [
              {
                name: Permission.Components.ContentMix,
                action: Permission.ValidPermissions.View,
                isClient: true,
              },
            ],
            config: {
              noAccessRedirect: 'ContentTypesView',
            },
          },
          breadcrumbs: ({ translate }) => {
            return [{ name: translate('sidebar.shareOfVoice') }];
          },
        },
      },
      {
        path: 'contentTypes',
        name: 'ContentTypesView',
        component: ContentTypesView,
        meta: {
          permission: {
            resources: [
              {
                name: Permission.Components.ContentType,
                action: Permission.ValidPermissions.View,
                isClient: true,
              },
            ],
          },
          breadcrumbs: ({ translate }) => {
            return [{ name: translate('sidebar.shareOfVoice') }];
          },
        },
      },
    ],
  },
  {
    path: '/shareOfVoice/contentMix/new',
    name: 'NewContentMix',
    components: { fullscreen: ContentMixesDetails },
    meta: {
      permission: {
        resources: [
          {
            name: Permission.Components.ContentMix,
            action: Permission.ValidPermissions.Edit,
            isClient: true,
          },
        ],
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.shareOfVoice'),
            route: { name: 'ContentMixesView' },
          },
          { name: translate('shareOfVoice.contentMix.navBarActionButton') },
        ];
      },
    },
  },
  {
    path: '/shareOfVoice/contentMix/edit/:code',
    name: 'EditContentMix',
    components: { fullscreen: ContentMixesDetails },
    meta: {
      permission: {
        resources: [
          {
            name: Permission.Components.ContentMix,
            action: Permission.ValidPermissions.Edit,
            isClient: true,
          },
        ],
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.shareOfVoice'),
            route: { name: 'ContentMixesView' },
          },
          { name: translate('shareOfVoice.contentMix.details.edit') },
        ];
      },
    },
  },
];
export default shareOfVoiceRoutes;
