import axios from 'axios';
import Env from '@/utils/env';

export default {
  searchTimeZones(searchtext) {
    return new Promise((resolve, reject) => {
      let targetUrl =
        Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/timezones/search';

      axios
        .post(targetUrl, { searchText: searchtext })
        .then((response) => {
          resolve(response.data.timeZones);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when searching for timezones.');
        });
    });
  },
};
