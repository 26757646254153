<template>
  <div class="va-filter-button-container">
    <button class="va-filter-button" @click="$emit('click')">
      <i
        v-if="type === 'ADD'"
        class="fa fa-plus-circle button-icon add"
        aria-hidden="true"
      />
      <i
        v-if="type === 'REMOVE'"
        class="fa fa-times-circle button-icon remove"
        aria-hidden="true"
      />
      <span ref="buttonText" class="button-text" :title="title">
        {{ text }}
      </span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },
  emits: ['click'],
  data() {
    return {
      overflowing: false,
    };
  },
  computed: {
    title() {
      return this.overflowing ? this.text : '';
    },
  },
  mounted() {
    var element = this.$refs.buttonText;
    this.overflowing = element.offsetWidth < element.scrollWidth;
  },
};
</script>

<style lang="scss" scoped>
.va-filter-button-container {
  display: inline-flex;
  margin: 10px 0 10px 10px;

  .va-filter-button {
    display: flex;
    align-items: center;
    color: $color-text-secondary;
    background-color: #181b25;
    height: 39px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid #4b4b51;
    border-radius: 19.5px;
    font-family: Roboto, sans-serif;
    font-size: 15px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: 0.38px;
    text-align: center;
    outline: none;
    padding: 0;
    max-width: 170px;
    transition:
      background-color 0.2s ease-out,
      border-color 0.2s ease-out,
      color 0.2s ease-out;

    &:hover {
      color: #fafafa;
      border-color: #bababa;
      background-color: #14171f;
    }

    .button-icon {
      padding-left: 11px;
      font-size: 17px;

      &.add {
        color: #5edc88;
      }

      &.remove {
        color: #ff5c7e;
      }
    }

    .button-text {
      padding-right: 15px;
      padding-left: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
}
</style>
