<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
      <g id="broadcast-list" transform="translate(-1206.000000, -187.000000)">
        <g
          id="contact-circle-schedule-play-circle"
          transform="translate(1206.000000, 187.000000)"
        >
          <g id="Group">
            <circle id="Combined-Shape" cx="10" cy="10" r="10" />
          </g>
          <rect
            id="Rectangle"
            fill="#181E24"
            x="4"
            y="5.5"
            width="12"
            height="10"
            rx="2"
          />
          <path
            id="Rectangle"
            d="M6,9 L14,9 L14,12.5 C14,13.0522847 13.5522847,13.5 13,13.5 L7,13.5 C6.44771525,13.5 6,13.0522847 6,12.5 L6,9 L6,9 Z"
          />
          <rect id="Rectangle" x="6" y="3.5" width="2.5" height="4" rx="1.25" />
          <rect
            id="Rectangle"
            fill="#181E24"
            x="6.5"
            y="4"
            width="1.5"
            height="3"
            rx="0.75"
          />
          <rect
            id="Rectangle"
            x="11.5"
            y="3.5"
            width="2.5"
            height="4"
            rx="1.25"
          />
          <rect
            id="Rectangle"
            fill="#181E24"
            x="12"
            y="4"
            width="1.5"
            height="3"
            rx="0.75"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
