import api from '@/api/api.js';

const URLS = {
  locations: 'locations',
  locationsWithCode: 'locations/{locationCode}',
  locationsCountries: 'locations/countries',
  locationSettings: 'locations/{locationCode}/settings',
};
export const getLocations = (searchParams, config) => {
  return api.get(
    URLS.locations,
    {
      params: {
        SearchText: searchParams.searchText,
        OrderBy: searchParams.orderBy,
        Order: searchParams.order,
        MaxItemsInResult: searchParams.maxItemsInResult,
        SkipItemsInResult: searchParams.skipItemsInResult,
      },
    },
    config,
  );
};

export const addLocation = ({
  locationName,
  description,
  addressLine1,
  addressLine2,
  postalCode,
  city,
  country,
  timeZoneId,
  contactPersonName,
  contactPhoneNumber,
  contactEmailAddress,
  longitude,
  latitude,
  storeNumber,
  settings,
}) => {
  return api.post(URLS.locations, {
    Location: {
      locationName: locationName,
      description: description,
      postalCode: postalCode,
      storeNumber: storeNumber,
      address: {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        countryISO3: country,
        timeZoneId: timeZoneId,
        longitude: longitude,
        latitude: latitude,
      },
      contact: {
        name: contactPersonName,
        phoneNumber: contactPhoneNumber,
        emailAddress: contactEmailAddress,
      },
      settings,
    },
  });
};

export const editLocation = ({
  locationCode,
  locationName,
  description,
  addressLine1,
  addressLine2,
  postalCode,
  city,
  country,
  timeZoneId,
  contactPersonName,
  contactPhoneNumber,
  contactEmailAddress,
  longitude,
  latitude,
  storeNumber,
  settings,
}) => {
  return api.put(
    URLS.locationsWithCode.replace('{locationCode}', locationCode),
    {
      Location: {
        locationName: locationName,
        description: description,
        postalCode: postalCode,
        storeNumber: storeNumber,
        address: {
          addressLine1: addressLine1,
          addressLine2: addressLine2,
          city: city,
          countryISO3: country,
          timeZoneId: timeZoneId,
          longitude: longitude,
          latitude: latitude,
        },
        contact: {
          name: contactPersonName,
          phoneNumber: contactPhoneNumber,
          emailAddress: contactEmailAddress,
        },
        settings,
      },
    },
  );
};

export const modifyLocation = (locationCode, location) => {
  return api.put(
    URLS.locationsWithCode.replace('{locationCode}', locationCode),
    {
      Location: location,
    },
  );
};

export const deleteLocation = (locationCode) => {
  return api.delete(
    URLS.locationsWithCode.replace('{locationCode}', locationCode),
  );
};

export const getCountries = ({
  searchText,
  orderBy,
  order,
  maxItemsInResult,
  skipItemsInResult,
}) => {
  return api.post(URLS.locationsCountries, {
    SearchText: searchText,
    OrderBy: orderBy,
    Order: order,
    MaxItemsInResult: maxItemsInResult,
    SkipItemsInResult: skipItemsInResult,
  });
};

export const getLocationSettings = (locationCode) => {
  return api.get(URLS.locationSettings.replace('{locationCode}', locationCode));
};

export const updateLocationSettings = (locationCode, settings) => {
  return api.put(
    URLS.locationSettings.replace('{locationCode}', locationCode),
    { Settings: settings },
  );
};
