<template>
  <div class="va-field va-date">
    <label class="title-label">{{ computedLabel }}</label>
    <va-datepicker :model-value="selectedDate" @update:model-value="onInput" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  computed: {
    computedLabel() {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    selectedDate() {
      return this.data[this.fieldModel.fieldName] || '';
    },
  },
  methods: {
    onInput(input) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = input;
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss" scoped>
.va-date {
  position: relative;
  text-align: left;
}
</style>
