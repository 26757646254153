export const getPreviewSize = (element, pixels) => {
  let width = element.offsetWidth;
  let height = element.offsetHeight;
  let targetWidth = parseInt(pixels.toLowerCase().split('x')[0]);
  let targetHeight = parseInt(pixels.toLowerCase().split('x')[1]);
  let calculatedWidth = width;
  let calculatedHeight = height;

  if (targetWidth > targetHeight) {
    calculatedHeight = (width * targetHeight) / targetWidth;
    if (calculatedHeight > height) {
      calculatedHeight = height;
      calculatedWidth = (height * targetWidth) / targetHeight;
    }
  } else {
    calculatedWidth = (height * targetWidth) / targetHeight;
    if (calculatedWidth > width) {
      calculatedWidth = width;
      calculatedHeight = (width * targetHeight) / targetWidth;
    }
  }

  return {
    width: calculatedWidth,
    height: calculatedHeight,
    scale: calculatedHeight / targetHeight,
  };
};
