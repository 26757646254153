import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import formGenerator from '@/store/modules/formGenerator';
import langHandler from '@/store/modules/langHandler';
import mediaLibrary from '@/store/modules/mediaLibrary';
import notificationMessages from '@/store/modules/notificationMessages';
import userHandler from '@/store/modules/userHandler';
import signageplayer from '@/store/modules/signagePlayer';
import userAuthentication from '@/store/modules/userAuthentication';
import broadcastStore from '@/store/modules/broadcastStore';
import permissions from '@/store/modules/permissions';
import timezone from '@/store/modules/timezone';
import filters from '@/store/modules/filters';
import layers from '@/store/modules/layers';
import search from '@/store/modules/search';
import sidebar from '@/store/modules/sidebar';
import userSettingsStore from '@/store/modules/userSettingsStore';

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage,
  reducer: (state) => ({
    sidebar: state.sidebar,
  }),
});

export default new Vuex.Store({
  namespaced: true,
  modules: {
    formGenerator,
    langHandler,
    mediaLibrary,
    notificationMessages,
    userHandler,
    signageplayer,
    userAuthentication,
    broadcastStore,
    permissions,
    timezone,
    filters,
    search,
    sidebar,
    layers,
    userSettingsStore,
  },
  plugins: [vuexLocalStorage.plugin],
});
