import axios from 'axios';
import Env from '@/utils/env';
import {
  userComputed,
  login,
  loginWithProvider,
} from '@/services/auth.service';
import store from '@/store';
import ErrorMessageHelper from '../helpers/errorMessageHelper.js';
import { waitForCondition } from '@/helpers/waitForCondition';

const BASE_URL_SIGNAGE_BACKEND =
  Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/';

// Default config for the axios instance
const axiosParams = {
  baseURL: BASE_URL_SIGNAGE_BACKEND,
};
// Create axios instance with default params
const axiosInstance = axios.create(axiosParams);

const authInterceptor = async (config) => {
  // Wait until the condition is fulfilled
  await waitForCondition(
    () => store.getters.isClientLoaded || config.switchingClient,
  );

  const authToken = userComputed.user.get()?.access_token;
  if (authToken && [BASE_URL_SIGNAGE_BACKEND].includes(config.baseURL)) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }
  return config;
};

const errorInterceptor = (error) => {
  // check if it's a server error
  if (!error.response) {
    return Promise.reject(error);
  }

  const provider = localStorage.getItem('provider');

  // all the error responses
  switch (error.response.status) {
    case 401:
      if (provider) {
        loginWithProvider(provider);
      } else {
        login();
      }
      break;
    case 400:
    case 403:
    case 500:
      store.dispatch('addMessage', {
        message: {
          text: ErrorMessageHelper.renderErrorMessage(error.response),
          type: 'error',
        },
        time: 10000,
      });
      break;
    default:
      console.error(error.response.status, error.message);
  }
  return Promise.reject(error);
};

const responseInterceptor = (response) => {
  if (response.data.success && !response.data.success.ok) {
    store.dispatch('addMessage', {
      message: {
        text: ErrorMessageHelper.renderErrorMessage(response),
        type: 'error',
      },
      time: 10000,
    });
  }

  return response;
};

axiosInstance.interceptors.request.use(authInterceptor);
axiosInstance.interceptors.response.use(responseInterceptor, errorInterceptor);

const didAbort = (error) => axios.isCancel(error);
const getCancelSource = () => axios.CancelToken.source();

// Main api function
const api = (axios) => {
  const withAbort =
    (fn) =>
    async (...args) => {
      const originalConfig = args[args.length - 1];
      // Extract abort property from the config
      let { abort, ...config } = originalConfig;
      // Create cancel token and abort method only if abort
      // function was passed
      if (typeof abort === 'function') {
        const { cancel, token } = getCancelSource();
        config.cancelToken = token;
        abort(cancel);
      }
      try {
        // Spread all arguments from args besides the original config,
        // and pass the rest of the config without abort property
        return await fn(...args.slice(0, args.length - 1), config);
      } catch (error) {
        // Add "aborted" property to the error if the request was cancelled
        if (didAbort(error)) {
          error.aborted = true;
        }
        throw error;
      }
    };

  // Wrapper functions around axios
  return {
    get: (url, config = {}) => withAbort(axios.get)(url, config),
    post: (url, body, config = {}) => withAbort(axios.post)(url, body, config),
    put: (url, body, config = {}) => withAbort(axios.put)(url, body, config),
    delete: (url, config = {}) => withAbort(axios.delete)(url, config),
  };
};
// Initialize the api function and pass axiosInstance to it
export default api(axiosInstance);
