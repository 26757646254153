<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="40"
    height="40"
    viewBox="0 0 40 40"
  >
    <path
      fill-rule="evenodd"
      d="M20 40C8.954 40 0 31.046 0 20S8.954 0 20 0s20 8.954 20 20-8.954 20-20 20zm1.837-20.611v-8.556a1.834 1.834 0 0 0-3.667 0v8.556a1.834 1.834 0 0 0 3.667 0zm-10.12-6.51C9.92 14.966 9 17.369 9 20.015 9 25.4 13.122 31 20.003 31 26.903 31 31 25.467 31 20.015c0-2.684-.901-5.089-2.671-7.138a1.834 1.834 0 0 0-2.776 2.397c1.197 1.385 1.78 2.94 1.78 4.74 0 3.626-2.735 7.32-7.33 7.32-4.57 0-7.335-3.757-7.335-7.32 0-1.754.596-3.311 1.828-4.742a1.833 1.833 0 1 0-2.779-2.393z"
    />
  </svg>
</template>
