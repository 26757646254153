<template>
  <div class="va-show-more-button-container">
    <va-button
      :class="{ 'transparent-text': loading }"
      :type="type"
      :text="text"
      :disabled="disabled || loading"
      @click="onClick"
    />
    <div v-if="loading" class="va-loader-container">
      <va-loader />
    </div>
  </div>
</template>

<script>
import VaButton from '../framework/va-button.vue';
import VaLoader from '../framework/va-loader.vue';

export default {
  components: {
    VaButton,
    VaLoader,
  },
  props: {
    text: {
      type: String,
      default: '',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'cancel',
    },
  },
  emits: ['click'],
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss" scoped>
.va-show-more-button-container {
  position: relative;
  display: flex;
  justify-content: center;

  .va-loader-container {
    position: absolute;
    height: 100%;
    top: 0;
    width: 22px;
    left: 50%;
    transform: translateX(-50%);
  }

  .transparent-text {
    :deep(.va-button) {
      color: transparent;
    }
  }
}
</style>
