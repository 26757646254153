<template>
  <svg
    width="17px"
    height="15px"
    viewBox="0 0 17 15"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icons-sp"
        transform="translate(-1063.000000, -702.000000)"
        fill="currentColor"
      >
        <g id="Group-3" transform="translate(1041.000000, 679.000000)">
          <g id="list" transform="translate(22.000000, 23.000000)">
            <g id="Group-2-Copy">
              <rect id="Rectangle" x="0" y="0" width="17" height="3" rx="1.5" />
              <rect id="Rectangle" x="0" y="6" width="17" height="3" rx="1.5" />
              <rect
                id="Rectangle"
                x="0"
                y="12"
                width="17"
                height="3"
                rx="1.5"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
