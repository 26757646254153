import axios from 'axios';
import Env from '@/utils/env';

export default {
  fetchComponents(inApplicationName) {
    let applicationName = inApplicationName
      ? inApplicationName
      : 'SignagePlayer';

    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/applications/' +
            applicationName +
            '/components',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('fetchComponents', error);
          reject('An error has occurred when fetching component data.');
        });
    });
  },
  saveRole(payload) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/roles/',
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('saveRole', error);
          reject('An error has occurred when fetching component data.');
        });
    });
  },
  updateRole(roleCode, payload) {
    return new Promise((resolve, reject) => {
      if (!roleCode) {
        resolve();
      }

      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/roles/' + roleCode,
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('updateRole', error);
          reject('An error has occurred when fetching component data.');
        });
    });
  },
  fetchRole(roleCode) {
    if (!roleCode) {
      return;
    }

    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/roles/' + roleCode,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('fetchRole', error);
          reject('An error has occurred when fetching role data.');
        });
    });
  },
  fetchRoles() {
    return new Promise((resolve, reject) => {
      axios
        .get(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/roles/')
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error('fetchRoles', error);
          reject('An error has occurred when fetching all roles.');
        });
    });
  },
};
