<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.3322 4.16667C3.11118 4.16667 2.89922 4.25446 2.74294 4.41074C2.58666 4.56702 2.49886 4.77899 2.49886 5V6.66667C2.49886 7.1269 2.12577 7.5 1.66553 7.5C1.20529 7.5 0.832195 7.1269 0.832195 6.66667V5C0.832195 4.33696 1.09559 3.70107 1.56443 3.23223C2.03327 2.76339 2.66915 2.5 3.3322 2.5H16.6655C17.3286 2.5 17.9645 2.76339 18.4333 3.23223C18.9021 3.70107 19.1655 4.33696 19.1655 5V15C19.1655 15.663 18.9021 16.2989 18.4333 16.7678C17.9645 17.2366 17.3286 17.5 16.6655 17.5H11.6655C11.2053 17.5 10.8322 17.1269 10.8322 16.6667C10.8322 16.2064 11.2053 15.8333 11.6655 15.8333H16.6655C16.8865 15.8333 17.0985 15.7455 17.2548 15.5893C17.4111 15.433 17.4989 15.221 17.4989 15V5C17.4989 4.77899 17.4111 4.56702 17.2548 4.41074C17.0985 4.25446 16.8865 4.16667 16.6655 4.16667H3.3322ZM0.837302 9.94955C0.888176 9.49214 1.30023 9.16257 1.75764 9.21344C3.638 9.42257 5.39123 10.2653 6.72905 11.6031C8.06686 12.941 8.90962 14.6942 9.11875 16.5746C9.16963 17.032 8.84006 17.444 8.38264 17.4949C7.92523 17.5458 7.51317 17.2162 7.4623 16.7588C7.29499 15.2545 6.62079 13.8519 5.55053 12.7817C4.48028 11.7114 3.0777 11.0372 1.57341 10.8699C1.116 10.819 0.786428 10.407 0.837302 9.94955ZM0.849003 13.2501C0.940973 12.7992 1.3811 12.5082 1.83205 12.6001C2.79295 12.7961 3.675 13.2703 4.36844 13.9638C5.06187 14.6572 5.53609 15.5393 5.73205 16.5001C5.82402 16.9511 5.53301 17.3912 5.08205 17.4832C4.6311 17.5752 4.19097 17.2841 4.099 16.8332C3.96836 16.1926 3.65222 15.6046 3.18992 15.1423C2.72763 14.68 2.1396 14.3638 1.499 14.2332C1.04805 14.1412 0.757034 13.7011 0.849003 13.2501Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.832031 16.6673C0.832031 16.2071 1.20513 15.834 1.66536 15.834H1.6737C2.13394 15.834 2.50703 16.2071 2.50703 16.6673C2.50703 17.1276 2.13394 17.5007 1.6737 17.5007H1.66536C1.20513 17.5007 0.832031 17.1276 0.832031 16.6673Z"
      fill="currentColor"
    />
  </svg>
</template>
