<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <g id="Group_1" data-name="Group 1" transform="translate(-797 -573)">
      <circle
        id="Ellipse_1"
        data-name="Ellipse 1"
        cx="10"
        cy="10"
        r="10"
        transform="translate(797 573)"
        fill="currentColor"
      />
      <circle
        id="Ellipse_2"
        data-name="Ellipse 2"
        cx="2"
        cy="2"
        r="2"
        transform="translate(805 581)"
        fill="#1d252c"
      />
      <circle
        id="Ellipse_4"
        data-name="Ellipse 4"
        cx="2"
        cy="2"
        r="2"
        transform="translate(810 581)"
        fill="#1d252c"
      />
      <circle
        id="Ellipse_3"
        data-name="Ellipse 3"
        cx="2"
        cy="2"
        r="2"
        transform="translate(800 581)"
        fill="#1d252c"
      />
    </g>
  </svg>
</template>
