import mediaPermissions from './mediaPermissions';

const getItemImagesActions = (media, app) => {
  const translate = app.$translate;
  const hasPermission = app.$hasPermission;
  let returnItemActions = [];
  // TODO Remove hide object when edit image is available for item images (This is a temporary solution)
  const { allow, mediaType, hide } = media;
  if (allow.edit && mediaType === 'Photo' && !hide?.EDIT_IMAGE) {
    returnItemActions.push({
      Label: translate('media.itemActions.editImage'),
      EmitAction: 'EDIT_IMAGE',
    });
  }
  if (allow.edit && (mediaType === 'HtmlAd' || mediaType === 'WebPage')) {
    returnItemActions.push({
      Label: translate('media.itemActions.editSign'),
      EmitAction: 'EDIT_SIGN',
    });
  }
  if (
    mediaPermissions.hasPermissionToUploadMedia(hasPermission) &&
    mediaType === 'HtmlAd'
  ) {
    returnItemActions.push({
      Label: translate('media.itemActions.duplicateSign'),
      EmitAction: 'DUPLICATE_SIGN',
    });
  }
  if (allow.edit) {
    returnItemActions.push({
      Label: translate('media.itemActions.tags'),
      EmitAction: 'TAGS',
    });
  }
  if (allow.edit && mediaType === 'Photo') {
    returnItemActions.push({
      Label: translate('media.itemActions.replace'),
      EmitAction: 'REPLACE',
    });
  }
  if (mediaPermissions.hasPermissionToModifyAIAttributes(hasPermission)) {
    returnItemActions.push({
      Label: translate('media.itemActions.aiAttributes'),
      EmitAction: 'AI_ATTRIBUTES',
    });
  }
  if (
    allow.edit &&
    mediaType === 'HtmlAd' &&
    mediaPermissions.hasPermissionToDownloadPreview(hasPermission)
  ) {
    returnItemActions.push({
      Label: translate('media.itemActions.downloadPreview'),
      EmitAction: 'DOWNLOAD_PREVIEW',
    });
  }
  if (mediaType === 'File') {
    returnItemActions.push({
      Label: translate('media.itemActions.download'),
      EmitAction: 'DOWNLOAD',
    });
  }
  if (allow.delete) {
    returnItemActions.push({
      Label: translate('media.itemActions.delete'),
      EmitAction: 'DELETE',
      IsDanger: true,
    });
  }
  return returnItemActions;
};

export const getMediaColumns = ($translate, $hasPermission, enabled = {}) => {
  return [
    {
      id: 'checkbox',
      name: '',
      enabled: true,
      locked: true,
      checkbox: true,
    },
    {
      id: 'thumbnail',
      name: '',
      enabled: true,
      locked: true,
      defaultWidth: '54px',
      grid: {
        show: true,
        columnSpan: 5,
      },
    },
    {
      id: 'mediaName',
      name: $translate('media.listHeadingLabels.name'),
      attributeName: 'displayName',
      enabled: true,
      orderBy: 'MediaName',
      grid: {
        show: true,
        columnSpan: 4,
      },
    },
    {
      id: 'uploaded',
      name: $translate('media.listHeadingLabels.uploaded'),
      attributeName: 'registeredDateTime',
      enabled: true,
      orderBy: 'RegisteredDateTime',
    },
    {
      id: 'modified',
      name: $translate('media.listHeadingLabels.modified'),
      attributeName: 'modifiedDateTime',
      enabled: true,
      orderBy: 'ModifiedDateTime',
    },
    {
      id: 'template',
      name: $translate('media.listHeadingLabels.template'),
      enabled: enabled.template || false,
    },
    {
      id: 'tags',
      name: $translate('media.listHeadingLabels.tags'),
      enabled: true,
    },
    {
      id: 'aiTags',
      name: $translate('media.listHeadingLabels.aiTags'),
      enabled: true,
      permission:
        mediaPermissions.hasPermissionToAIAttributesColumn($hasPermission),
    },
    {
      id: 'layout',
      name: $translate('media.listHeadingLabels.layout'),
      enabled: true,
      orderBy: 'Layout',
    },
    {
      id: 'resolution',
      name: $translate('media.listHeadingLabels.resolution'),
      attributeName: 'resolution',
      enabled: true,
      orderBy: 'Resolution',
    },
    {
      id: 'options',
      name: '',
      options: getItemImagesActions,
      enabled: true,
      locked: true,
      grid: {
        show: true,
      },
    },
  ];
};

export const getMediaCheckboxActions = (
  $translate,
  $hasPermission,
  show = {},
) => {
  let list = [];
  if (
    show.downloadPreview &&
    mediaPermissions.hasPermissionToDownloadPreview($hasPermission)
  )
    list.push({
      label: $translate('media.downloadSignPreviews'),
      emitAction: 'DOWNLOAD_PREVIEW',
    });
  if (mediaPermissions.hasPermissionToModifyTags($hasPermission))
    list.push({
      label: $translate('media.removeTags'),
      emitAction: 'REMOVE_TAGS',
    });
  if (mediaPermissions.hasPermissionToModifyTags($hasPermission))
    list.push({
      label: $translate('media.addTags'),
      emitAction: 'ADD_TAGS',
    });
  if (mediaPermissions.hasPermissionToDeleteMedia($hasPermission))
    list.push({
      label: $translate('generic.delete'),
      emitAction: 'DELETE_MEDIA',
    });
  return list;
};

export const getOrientationByLang = ($translate, orientation) => {
  switch (orientation) {
    case 'Landscape':
      return $translate('generic.orientations.landscape');
    case 'Portrait':
      return $translate('generic.orientations.portrait');
    case 'Ultrawide':
      return $translate('generic.orientations.ultrawide');
    default:
      return orientation;
  }
};

export const getTabBarItems = ($translate, settings = {}, active = 'all') => {
  let items = [
    {
      label: $translate('media.all'),
      emitAction: 'all',
      default: active === 'all',
    },
  ];
  if (settings.hasImages) {
    items.push({
      label: $translate('media.images'),
      emitAction: 'images',
      default: active === 'images',
    });
  }
  if (settings.hasVideos) {
    items.push({
      label: $translate('media.videos'),
      emitAction: 'videos',
      default: active === 'videos',
    });
  }
  if (settings.hasFiles) {
    items.push({
      label: $translate('media.files'),
      emitAction: 'files',
      default: active === 'files',
    });
  }
  if (settings.hasSigns) {
    items.push({
      label: $translate('media.signs'),
      emitAction: 'signs',
      default: active === 'signs',
    });
  }

  return items;
};
