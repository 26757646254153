<template>
  <div class="va-field va-checkbox">
    <label class="checkbox-label" for="">{{ computedLabel }}</label>
    <label class="checkbox">
      <input
        ref="checkbox"
        type="checkbox"
        :value="fieldModel.fieldName"
        :checked="isChecked"
        @change="select"
      />
      <span class="checkbox-style" />
    </label>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    isChecked() {
      if (this.data[this.fieldModel.fieldName]) {
        return true;
      }
      return false;
    },
  },
  methods: {
    select: function () {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = this.$refs.checkbox.checked;
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss" scoped>
.va-checkbox {
  position: relative;
  text-align: left;
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    background: #000;
    text-transform: none;
  }

  .checkbox-label {
    color: $color-text-secondary;
    font-size: 13px;
    letter-spacing: 0.33px;
    padding-left: calc(1.4rem + 10px);
    position: absolute;
  }
}

.checkbox {
  display: block;
  position: relative;
  padding-left: 1rem;
  margin-bottom: 1.5rem;
  cursor: pointer;
  user-select: none;

  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }

  .checkbox-style {
    position: absolute;
    top: 0;
    left: 0;
    height: 1.4rem;
    width: 1.4rem;
    background-color: #4c4b51;
    border-radius: 0.2rem;
  }

  input:checked ~ .checkbox-style {
    background-color: $color-ui-primary;
  }

  .checkbox-style::after {
    content: '';
    position: absolute;
    display: none;
    left: 0.5rem;
    top: 0.2rem;
    width: 0.3rem;
    height: 0.6rem;
    border: solid #fafafa;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }

  input:checked ~ .checkbox-style::after {
    display: block;
  }
}
</style>
