const BroadcastView = () => import('@/views/broadcasts/BroadcastView.vue');
const BroadcastDetails = () =>
  import('@/views/broadcasts/broadcastDetails/BroadcastDetails.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const broadcastRoutes = [
  {
    path: '/broadcast',
    name: 'Broadcast',
    components: { default: BroadcastView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Broadcast', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ translate }) => {
        return [{ name: translate('sidebar.broadcast') }];
      },
    },
  },
  {
    path: '/broadcast/new/details',
    name: 'NewBroadcastDetails',
    components: { fullscreen: BroadcastDetails },
    meta: {
      permission: {
        resources: [{ name: 'Broadcast', action: 'AddAndEdit' }],
        config: {
          noAccessRedirect: 'Broadcast',
        },
      },
      breadcrumbs: ({ translate, isInteractive }) => {
        return [
          {
            name: isInteractive
              ? translate('sidebar.localSelections')
              : translate('sidebar.broadcast'),
            route: { name: isInteractive ? 'LocalSelectionView' : 'Broadcast' },
          },
          {
            name: isInteractive
              ? translate(
                  'broadcast.broadcastDetails.breadcrumbs.newLocalSelection',
                )
              : translate(
                  'broadcast.broadcastDetails.breadcrumbs.newBroadcast',
                ),
          },
        ];
      },
    },
  },
  {
    path: '/broadcast/:broadcastCode/details/:isOpenFromStart',
    name: 'EditBroadcastDetails',
    components: { fullscreen: BroadcastDetails },
    meta: {
      permission: {
        resources: [{ name: 'Broadcast', action: 'AddAndEdit' }],
        config: {
          noAccessRedirect: 'Broadcast',
        },
      },
      breadcrumbs: ({ translate, isInteractive }) => {
        return [
          {
            name: isInteractive
              ? translate('sidebar.localSelections')
              : translate('sidebar.broadcast'),
            route: { name: isInteractive ? 'LocalSelectionView' : 'Broadcast' },
          },
          {
            name: isInteractive
              ? translate(
                  'broadcast.broadcastDetails.breadcrumbs.editLocalSelection',
                )
              : translate(
                  'broadcast.broadcastDetails.breadcrumbs.editBroadcast',
                ),
          },
        ];
      },
    },
  },
];
export default broadcastRoutes;
