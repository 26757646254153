<template>
  <div class="dropdown">
    <va-popup
      data-testid="va-dropdown"
      :opened="popupOpened"
      :right="!center"
      :center="center"
      :up="up"
      @blur="popupOpened = false"
    >
      <template #toggler>
        <button
          ref="dropdown-button"
          type="button"
          data-testid="va-dropdown--toggle-button"
          aria-haspopup="listbox"
          aria-expanded="false"
          :class="{ open: popupOpened }"
          @keydown.down.prevent
          @keydown="onButtonKeydown"
          @click="popupOpened = true"
        >
          <slot />
        </button>
      </template>
      <ul
        data-testid="va-dropdown--content"
        role="listbox"
        class="dropdown-content"
        :class="{ active: popupOpened }"
      >
        <li
          v-for="(action, index) in dropdownActions"
          :id="`option${index}`"
          :key="action.Label"
          tabindex="-1"
          role="option"
          :data-testid="action.EmitAction"
          :class="{ checkbox: checkboxes, danger: action.IsDanger }"
          @keydown.prevent="onListKeydown($event, action.EmitAction)"
          @click="onDropdownItemClick(action.EmitAction)"
        >
          <va-checkbox
            v-if="checkboxes"
            v-model="action.Checked"
            data-testid="va-dropdown--content-checkbox"
            :label="action.Label"
            @click.stop
            @update:model-value="onDropdownItemClick(action.EmitAction)"
          />
          <span v-else data-testid="va-dropdown--content-label">
            {{ action.Label }}
          </span>
        </li>
      </ul>
    </va-popup>
  </div>
</template>

<script>
import VaCheckbox from '@/components/framework/va-checkbox.vue';
import VaPopup from '@/components/framework/vaPopup/VaPopup.vue';

export default {
  components: {
    VaCheckbox,
    VaPopup,
  },
  props: {
    dropdownActions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    checkboxes: {
      type: Boolean,
    },
    center: {
      type: Boolean,
      default: false,
    },
    up: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['DROPDOWN_SELECTED'],
  data() {
    return {
      popupOpened: false,
    };
  },
  methods: {
    onButtonKeydown(e) {
      if (e.key === 'Enter' || e.key === 'ArrowDown') {
        this.popupOpened = true;
        this.$nextTick(() => {
          document.getElementById('option0').focus();
        });
      } else if (e.key === 'ArrowUp') {
        this.popupOpened = true;
        this.$nextTick(() => {
          document
            .getElementById(`option${this.dropdownActions.length - 1}`)
            .focus();
        });
      } else if (e.key === 'Escape' || e.key === 'Tab') {
        this.popupOpened = false;
      }
    },
    onListKeydown(e, emitAction) {
      if (e.key === 'ArrowDown') {
        const activeOption = document.activeElement;
        const nextOption = activeOption.nextElementSibling;
        if (nextOption) {
          nextOption.focus();
        }
      } else if (e.key === 'ArrowUp') {
        const activeOption = document.activeElement;
        const prevOption = activeOption.previousElementSibling;
        if (prevOption) {
          prevOption.focus();
        }
      } else if (e.key === 'Enter') {
        this.onDropdownItemClick(emitAction);
      } else if (e.key === 'Escape' || e.key === 'Tab') {
        this.popupOpened = false;
      } else if (e.key === ' ' && this.checkboxes) {
        this.onDropdownItemClick(emitAction);
      }
    },
    onDropdownItemClick(emitAction) {
      if (!this.checkboxes) {
        this.popupOpened = false;
      }
      this.$emit('DROPDOWN_SELECTED', emitAction);
    },
  },
};
</script>

<style lang="scss" scoped>
.dropdown {
  position: relative;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  color: $color-text-secondary;
  line-height: normal;
  transition: color 0.2s ease-out;

  * {
    box-sizing: border-box;
  }

  &:hover {
    color: #fafafa;
  }

  button {
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 100%;
    background: transparent;
    border: 0;
    color: inherit;
    font: inherit;
    user-select: none;

    &:focus-visible {
      border: 1px solid $active-border-primary;
    }

    * {
      cursor: inherit;
    }

    :deep(.svg-icon) {
      display: flex;
    }

    i,
    :deep(.svg-icon) {
      padding: 5px;
    }

    &:hover i {
      color: #fafafa;
    }

    &:hover :deep(.svg-icon) {
      color: #fafafa;
    }

    &:focus {
      outline: none;
    }

    &.open {
      i,
      .svg-icon {
        color: $color-text-secondary;
        outline: #bababa solid 1px;
      }
    }
  }

  ul {
    &.dropdown-content {
      line-height: normal;
      min-width: 200px;
      width: 100%;
      padding: 0;
      margin: 0;
      list-style: none;
      background-color: $color-container-primary;
      background-clip: padding-box;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      letter-spacing: 0.3px;
      text-align: left;
      color: $color-text-secondary;
      border-radius: 4px;
      pointer-events: none;
      box-shadow: 0 3px 10px $color-container-box-shadow;

      li {
        display: block;
        width: 100%;
        cursor: pointer;
        pointer-events: none;
        padding: 10px 10px 10px 20px;
        transition:
          background-color 0.2s ease-out,
          color 0.2s ease-out;

        &.danger {
          color: $color-text-danger;

          &:hover,
          &:focus {
            background-color: $hover-container-danger;
            color: $color-text-danger;
          }

          &:focus-visible {
            outline: $active-border-danger solid 1px;
          }
        }

        &.checkbox {
          cursor: default;
        }

        &:first-child {
          border-top-right-radius: 4px;
          border-top-left-radius: 4px;
        }

        &:last-child {
          border-bottom-right-radius: 4px;
          border-bottom-left-radius: 4px;
        }

        &:hover,
        &:focus {
          background-color: $hover-container-primary;
          color: #fafafa;
        }

        &:focus-visible {
          outline: $active-border-primary solid 1px;
        }
      }

      &.active {
        opacity: 1;
        transform: translate3d(0, 0, 0);

        li {
          pointer-events: all;
        }
      }
    }
  }
}
</style>
