import type { ComputedRef, Ref } from 'vue';
import type { AxiosResponse } from 'axios';
import { ref, computed } from 'vue';
import { upperFirst } from '../../helpers/strings';
import { apiStatus } from '../constants/apiStatus';
const { IDLE, SUCCESS, PENDING, ERROR } = apiStatus;

/**
 * Create an object of computed statuses
 *
 * @param {Symbol} status
 * @param {String} apiName
 */
const createNormalizedApiStatuses = (status: Ref<symbol>, apiName: string) => {
  const normalizedApiStatuses: { [key: string]: ComputedRef<boolean> } = {};
  for (const [statusKey, statusValue] of Object.entries(apiStatus)) {
    let propertyName = '';
    // Create a property name for each computed status
    if (apiName) {
      propertyName = `${apiName}Status${upperFirst(statusKey.toLowerCase())}`;
    } else {
      propertyName = `status${upperFirst(statusKey.toLowerCase())}`;
    }
    // Create a computed that returns true/false based on
    // the currently selected status
    normalizedApiStatuses[propertyName] = computed(
      () => statusValue === status.value,
    );
  }
  return normalizedApiStatuses;
};

export const useApi = <U extends unknown[]>(
  apiName: string,
  fn: (...args: U) => Promise<AxiosResponse>,
  config: { initialData?: AxiosResponse; responseAdapter?: unknown } = {},
) => {
  const { initialData, responseAdapter } = config;
  // Reactive values to store data and API status
  const data = ref(initialData);
  const status = ref(IDLE);
  const error: Ref<unknown | null> = ref(null);
  /**
   * Initialize the api request
   */
  const exec = async (...args: U) => {
    try {
      // Clear current error value
      error.value = null;
      // API request starts
      status.value = PENDING;
      const response = await fn(...args);
      // Before assigning the response, check if a responseAdapter
      // was passed, if yes, then use it
      data.value =
        typeof responseAdapter === 'function'
          ? responseAdapter(response)
          : response;
      // Check success object if available
      if (data.value?.data.success?.ok === false) {
        status.value = ERROR;
      } else {
        // Done!
        status.value = SUCCESS;
      }
    } catch (err: unknown) {
      // Oops, there was an error
      error.value = err;
      status.value = ERROR;
    }
  };

  // TODO Type normalizedApiStatuses should be changed to return the actual available statuses, for example "apiNameStatusPending"
  const normalizedApiStatuses = createNormalizedApiStatuses(status, apiName);

  return {
    data,
    status,
    error,
    exec,
    normalizedApiStatuses,
  };
};
