const HelpView = () => import('@/views/help/helpView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const helpRoutes = [
  {
    path: '/help',
    name: 'HelpView',
    components: { default: HelpView, 'side-bar': SideBar },
    meta: {
      breadcrumbs: ({ translate }) => {
        return [{ name: translate('sidebar.help') }];
      },
    },
  },
];
export default helpRoutes;
