<template>
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_155_4523)">
      <g clip-path="url(#clip1_155_4523)">
        <path
          d="M9 18C13.9706 18 18 13.9706 18 9C18 4.02944 13.9706 0 9 0C4.02944 0 0 4.02944 0 9C0 13.9706 4.02944 18 9 18Z"
          fill="currentColor"
        />
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M9.72443 3.54877C9.96396 3.65293 10.1063 3.90283 10.0739 4.16241L9.66634 7.42858H13.4118C13.64 7.42858 13.8476 7.56085 13.9445 7.76792C14.0413 7.97498 14.0098 8.21946 13.8637 8.39511L8.9617 14.2879C8.79453 14.4889 8.51511 14.5554 8.27557 14.4512C8.03604 14.3471 7.89372 14.0972 7.92611 13.8376L8.33366 10.5714H4.58824C4.36 10.5714 4.15235 10.4391 4.05554 10.2321C3.95873 10.025 3.99023 9.78054 4.13635 9.60489L9.0383 3.71207C9.20547 3.5111 9.48489 3.4446 9.72443 3.54877ZM5.84415 9.39285H9C9.16872 9.39285 9.32932 9.46543 9.44099 9.59215C9.55265 9.71886 9.60462 9.88751 9.58369 10.0552L9.34236 11.9894L12.1559 8.60714H9C8.83128 8.60714 8.67068 8.53457 8.55901 8.40785C8.44735 8.28114 8.39538 8.11249 8.41631 7.94477L8.65764 6.01064L5.84415 9.39285Z"
          fill="#1E1E1E"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_155_4523">
        <rect width="18" height="18" fill="white" />
      </clipPath>
      <clipPath id="clip1_155_4523">
        <rect width="18" height="18" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
