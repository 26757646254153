<template>
  <div class="va-slider">
    <label v-if="label">{{ label }}</label>
    <div class="slider-row" :class="{ 'slider-row-2': displaySliderValue }">
      <div class="va-slider-container">
        <input
          v-model="sliderValue"
          type="range"
          :min="min"
          :max="max"
          class="slider"
          @change="onChange"
        />
        <div
          class="filled"
          :style="'width: ' + (sliderValue / max) * 100 + '%;'"
        />
        <div
          class="empty"
          :style="'width: ' + (100 - (sliderValue / max) * 100) + '%;'"
        />
        <div class="thumb-container">
          <div
            class="thumb"
            :style="'left: ' + (sliderValue / max) * 100 + '%;'"
          />
        </div>
      </div>
      <span v-if="displaySliderValue" class="text">
        {{ sliderValue }}{{ suffix }}
      </span>
    </div>
    <div v-if="minLabel || maxLabel" class="label-container">
      <span>{{ minLabel }}</span>
      <span>{{ maxLabel }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 100,
    },
    suffix: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    minLabel: {
      type: String,
      default: '',
    },
    maxLabel: {
      type: String,
      default: '',
    },
    displaySliderValue: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      sliderValue: null,
    };
  },
  watch: {
    modelValue(newValue) {
      this.sliderValue = newValue;
    },
  },
  created() {
    this.sliderValue = this.modelValue;
  },
  methods: {
    onChange() {
      this.$emit('update:modelValue', this.sliderValue);
    },
  },
};
</script>

<style lang="scss">
$thumb-size: 1.5rem;

.va-slider {
  display: block;

  label {
    display: block;
    font-size: 0.8rem;
    padding-bottom: 0.5rem;
  }

  .slider-row {
    display: grid;
    grid-template-columns: 1fr;
    position: relative;

    &.slider-row-2 {
      grid-template-columns: 1fr 2.2rem;
    }

    .va-slider-container {
      height: $thumb-size;
      position: relative;
      display: flex;
      align-items: center;

      .slider {
        appearance: none;
        width: 100%;
        position: absolute;
        opacity: 0;
        cursor: pointer;
      }

      .thumb-container,
      .thumb {
        cursor: pointer;
        pointer-events: none;
      }

      .empty,
      .filled {
        pointer-events: none;
        position: absolute;
        height: 6px;
        border-radius: 4px;
      }

      .filled {
        background: #bababa;
      }

      .empty {
        background: rgba(255, 255, 255, 0.1);
        right: 0;
      }

      .thumb {
        position: absolute;
        background: #bababa;
        height: $thumb-size;
        width: $thumb-size;
        border-radius: 50%;
        box-shadow: 0 2px 4px 0 #00000059;
      }

      .thumb-container {
        position: absolute;
        width: calc(100% - #{$thumb-size});
        height: 100%;
      }
    }

    .text {
      font-size: 11px;
      color: $color-text-secondary;
      text-align: right;
      line-height: $thumb-size;
    }
  }

  .label-container {
    display: flex;
    justify-content: space-between;
    font-size: 0.7rem;

    span {
      padding-top: 0.5rem;
    }
  }
}
</style>
