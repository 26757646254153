<template>
  <div class="va-toggle-container" @click="onToggleClick">
    <div v-if="label" class="va-toggle-label" :class="{ disabled: isDisabled }">
      {{ label }}
    </div>
    <div class="va-toggle" :class="{ on: toggle, disabled: isDisabled }">
      <div class="toggle-animation" :class="{ on: toggle }" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    isDisabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  data() {
    return {
      toggle: false,
    };
  },
  watch: {
    modelValue(newVal) {
      this.toggle = newVal;
    },
  },
  created() {
    this.toggle = this.modelValue;
  },
  methods: {
    onToggleClick() {
      if (!this.isDisabled) {
        this.toggle = !this.toggle;
        this.$emit('update:modelValue', this.toggle);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.va-toggle-container {
  user-select: none;
  position: relative;
  margin: 5px;
  display: flex;
  cursor: default;

  .va-toggle-label {
    display: inline-block;
    line-height: 24px;
    height: 24px;
    text-align: center;
    margin-right: 10px;
    cursor: pointer;
  }

  .va-toggle {
    display: inline-block;
    width: 45px;
    height: 20px;
    background-color: #4b4b51;
    border-radius: 19.5px;
    padding: 2px;
    position: relative;
    cursor: pointer;
    box-sizing: content-box;

    .off {
      margin: 0 2px 2px 1px;
      float: left;
      background-color: #181e24;
      width: 20px;
      height: 20px;
      border-radius: 19.5px;
      text-align: center;

      i {
        margin-top: 3px;
        color: #4b4b51;
      }
    }

    .on {
      margin: 0 2px 2px 1px;
      float: right;
      background-color: #5edc88;
      width: 20px;
      height: 20px;
      border-radius: 19.5px;
      text-align: center;

      i {
        margin-top: 3px;
        color: white;
      }
    }

    .toggle-animation {
      position: absolute;
      width: 20px;
      height: 20px;
      top: 2px;
      left: 2px;
      border-radius: 19.5px;
      background-color: #181e24;
      transition: 0.2s;

      &::before {
        position: absolute;
        content: '';
        width: 60%;
        height: 10%;
        border-radius: 5px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        background: #4b4b51;
        transition: 0.2s;
      }

      &::after {
        position: absolute;
        content: '';
        width: 60%;
        height: 10%;
        border-radius: 5px;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        background: #4b4b51;
        transition: 0.2s;
      }

      &.on {
        top: 2px;
        left: 26px;
        background-color: #5edc88;

        &::after {
          transform: translate(-35%, -20%) rotate(-45deg);
          background: #fafafa;
        }

        &::before {
          width: 30%;
          height: 10%;
          left: 15%;
          top: 55%;
          transform: rotate(45deg);
          background: #fafafa;
        }
      }
    }
  }

  .disabled {
    opacity: 0.3;
    cursor: default;
  }
}
</style>
