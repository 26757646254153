<template>
  <confirm-modal
    ref="confirm-modal"
    :title="$translate('generic.delete')"
    :message="$translate('media.deleteItems.body')"
    :ok-button="$translate('generic.delete')"
    :cancel-button="$translate('generic.cancel')"
    :loading="deleteMediaStatusPending"
    type="warning"
    width="500"
    @confirm="deleteMedia"
    @cancel="$emit('close')"
  />
</template>

<script>
import ConfirmModal from '@/components/common/ConfirmModal.vue';
import { removeMultipleMedia } from '@/api/mediaApi';
import { withAsync } from '@/helpers/withAsync';
import { apiStatus } from '@/api/constants/apiStatus';
import { apiStatusComputedFactory } from '@/api/helpers/apiStatusComputedFactory';

const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default {
  components: {
    ConfirmModal,
  },
  props: {
    media: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  emits: ['close', 'deleted'],
  data() {
    return {
      deleteMediaStatus: IDLE,
    };
  },
  computed: {
    ...apiStatusComputedFactory('deleteMediaStatus'),
  },
  methods: {
    async deleteMedia() {
      this.deleteMediaStatus = PENDING;
      const { error } = await withAsync(removeMultipleMedia, this.media);
      if (error) {
        this.deleteMediaStatus = ERROR;
        return;
      }
      this.deleteMediaStatus = SUCCESS;
      this.$emit('deleted');
    },
  },
};
</script>

<style lang="scss" scoped></style>
