<template>
  <va-modal ref="va-modal" :type="type" :width="width" @close="$emit('cancel')">
    <template #header>
      <h3>
        {{ title }}
      </h3>
    </template>
    <template #body>
      <div>
        <p v-if="message" class="body-message">{{ message }}</p>
        <slot v-else name="body" />
      </div>
    </template>
    <template #footer>
      <div class="footer-buttons">
        <div class="extra-footer-button">
          <slot name="extra-footer-button"></slot>
        </div>
        <!-- translate can not be used in props, it fallbacks to English -->
        <va-show-more-button
          ref="cancel-button"
          :text="cancelButton || translate('generic.cancel')"
          data-testid="cancel-button"
          @click="
            $emit('cancel');
            $emit('secondaryConfirm');
          "
        />
        <va-show-more-button
          ref="confirm-button"
          :text="okButton || translate('generic.confirm')"
          :type="type"
          :loading="loading"
          :disabled="disabled"
          data-testid="confirm-button"
          @click="$emit('confirm')"
        />
      </div>
    </template>
  </va-modal>
</template>

<script>
import VaModal from '@/components/framework/va-modal.vue';
import VaShowMoreButton from '@/components/framework/va-show-more-button.vue';
import { translate } from '@/helpers/translationHelper';

export default {
  components: {
    VaModal,
    VaShowMoreButton,
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    okButton: {
      type: String,
      default: '',
    },
    cancelButton: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: null,
    },
    width: {
      type: [String, Number],
      default: 800,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['cancel', 'confirm', 'secondaryConfirm'],
  methods: {
    translate,
  },
};
</script>

<style lang="scss" scoped>
.body-message {
  overflow-wrap: break-word;
}

.footer-buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .extra-footer-button {
    margin: 10px;
  }
}
</style>
