<template>
  <div class="metadata-layer-list-row" :title="'Index: ' + newLayer.rowNumber">
    <div class="fields">
      <va-checkbox ref="is-selected" v-model="isSelected" />
      <va-input
        ref="layer-name"
        v-model="newLayer.layerName"
        @update:model-value="emitUpdateLayer"
      />
      <va-number-picker
        ref="x-pos"
        v-model="newLayer.x"
        @update:model-value="emitUpdateLayer"
      />
      <va-number-picker
        ref="y-pos"
        v-model="newLayer.y"
        @update:model-value="emitUpdateLayer"
      />
      <div class="options field-last">
        <svg-icon
          ref="options"
          icon="optionsCircle"
          class="icon clickable"
          @click="showModal = true"
        />
        <svg-icon
          ref="copy"
          icon="copy"
          color="blueberry"
          class="icon clickable"
          @click="emitCopy"
        />
        <svg-icon
          ref="add"
          icon="add"
          color="green"
          class="icon clickable"
          @click="emitAdd"
        />
        <svg-icon
          v-if="removeOption"
          ref="remove"
          icon="remove"
          color="pink"
          class="icon clickable"
          @click="emitRemove"
        />
      </div>
    </div>
    <metadata-layer-modal
      v-if="showModal"
      ref="metadata-layer-modal"
      v-model:layer="newLayer"
      :fields="modalFields"
      @close="showModal = false"
    />
  </div>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon.vue';
import VaInput from '@/components/framework/va-input.vue';
import VaNumberPicker from '@/components/framework/va-number-picker.vue';
import MetadataLayerModal from './metadata-layer-modal.vue';
import VaCheckbox from '@/components/framework/va-checkbox.vue';

export default {
  components: {
    MetadataLayerModal,
    SvgIcon,
    VaInput,
    VaNumberPicker,
    VaCheckbox,
  },
  props: {
    layer: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
    },
    modalFields: {
      type: Object,
      required: true,
    },
    removeOption: {
      type: Boolean,
    },
  },
  emits: ['update:layer', 'update:selected', 'copy', 'add', 'remove'],
  data() {
    return {
      showModal: false,
    };
  },
  computed: {
    newLayer: {
      get() {
        return this.layer;
      },
      set(newValue) {
        this.$emit('update:layer', newValue);
      },
    },
    isSelected: {
      get() {
        return this.selected;
      },
      set(newValue) {
        this.$emit('update:selected', newValue);
      },
    },
  },
  methods: {
    emitCopy() {
      this.$emit('copy', this.newLayer);
    },
    emitAdd() {
      this.$emit('add');
    },
    emitRemove() {
      this.$emit('remove');
    },
    emitUpdateLayer() {
      this.$emit('update:layer', this.newLayer);
    },
  },
};
</script>

<style lang="scss" scoped>
.metadata-layer-list-row {
  .options {
    font-size: 20px;
    display: grid;
    grid-template-columns: 20px 20px 20px 20px;
    grid-gap: 10px;
    align-items: center;
  }
}
</style>
