<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.6654 16.6673L16.6654 10.0007L3.33203 10.0007L3.33203 16.6673L16.6654 16.6673ZM18.332 10.0007C18.332 9.08018 17.5858 8.33398 16.6654 8.33398L3.33203 8.33398C2.41156 8.33398 1.66537 9.08018 1.66537 10.0007L1.66537 16.6673C1.66537 17.5878 2.41156 18.334 3.33203 18.334L16.6654 18.334C17.5858 18.334 18.332 17.5878 18.332 16.6673L18.332 10.0007Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.5 3.33333C12.5 2.8731 12.8731 2.5 13.3333 2.5H15C16.3807 2.5 17.5 3.61929 17.5 5V6.66667C17.5 7.1269 17.1269 7.5 16.6667 7.5C16.2064 7.5 15.8333 7.1269 15.8333 6.66667V5C15.8333 4.53976 15.4602 4.16667 15 4.16667H13.3333C12.8731 4.16667 12.5 3.79357 12.5 3.33333Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16797 2.50065C3.70773 2.50065 3.33464 2.87375 3.33464 3.33398V6.66732C3.33464 7.12755 2.96154 7.50065 2.5013 7.50065C2.04106 7.50065 1.66797 7.12755 1.66797 6.66732V3.33398C1.66797 1.95327 2.78726 0.833984 4.16797 0.833984H9.16797C10.5487 0.833984 11.668 1.95327 11.668 3.33398V6.66732C11.668 7.12755 11.2949 7.50065 10.8346 7.50065C10.3744 7.50065 10.0013 7.12755 10.0013 6.66732V3.33398C10.0013 2.87375 9.62821 2.50065 9.16797 2.50065H4.16797Z"
      fill="currentColor"
    />
  </svg>
</template>
