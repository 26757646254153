<template>
  <div class="va-field va-form-slider">
    <label class="title-label">{{ computedLabel }}</label>
    <va-slider
      v-model="value"
      :min="fieldModel.minValue"
      :max="fieldModel.maxValue"
      :max-label="fieldModel.maxValueLabel"
      :min-label="fieldModel.minValueLabel"
      :display-slider-value="true"
      @update:model-value="onChange"
    />
  </div>
</template>

<script>
import VaSlider from '@/components/framework/va-slider.vue';

export default {
  components: {
    VaSlider,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      value: '',
    };
  },
  computed: {
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
  },
  mounted() {
    if (!this.data[this.fieldModel.fieldName] && this.fieldModel.defaultValue) {
      this.onChange(this.fieldModel.defaultValue.toString());
    }
    this.value = this.data[this.fieldModel.fieldName];
  },
  methods: {
    onChange(updatedValue) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = updatedValue;
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss"></style>
