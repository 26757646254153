<template>
  <svg
    width="57px"
    height="58px"
    viewBox="0 0 57 58"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="dayparts" fill="currentColor">
        <g id="Group-16" transform="translate(0.000000, 2.000000)">
          <g id="contact-circle-copy-7">
            <path
              id="Combined-Shape"
              d="M9.769,7.53769231 L9.76923077,11.7692308 C9.76923077,14.5306545 12.007807,16.7692308 14.7692308,16.7692308 C17.4579855,16.7692308 19.6510489,14.6469241 19.7646113,11.9861204 L19.7692308,11.7692308 L19.769,7.53769231 L35.615,7.53769231 L35.6153846,11.7692308 C35.6153846,14.4579855 37.7376913,16.6510489 40.398495,16.7646113 L40.6153846,16.7692308 C43.3041393,16.7692308 45.4972027,14.6469241 45.6107651,11.9861204 L45.6153846,11.7692308 L45.615,7.53769231 L50.7692308,7.53846154 C53.3182373,7.53846154 55.3846154,9.60483962 55.3846154,12.1538462 L55.3852082,36.8841319 C54.6141797,34.373676 53.1562825,32.1638584 51.2307871,30.4739497 L51.2307692,20.0615387 L4.23076923,20.0615387 L4.23076923,47.7538464 C4.23076923,48.9752454 5.17965291,49.9750207 6.38046276,50.0562148 L6.53846154,50.0615387 L31.3280085,50.0618168 C32.6265567,51.5895983 34.2473354,52.8349777 36.0852918,53.6929023 L4.61538462,53.6923077 C2.06637808,53.6923077 5.40221331e-12,51.6259296 5.40367751e-12,49.0769231 L5.40367751e-12,12.1538462 C5.40336534e-12,9.60483962 2.06637808,7.53846154 4.61538462,7.53846154 L9.769,7.53769231 Z M55.3851192,45.1161581 L55.3846154,49.0769231 C55.3846154,51.6259296 53.3182373,53.6923077 50.7692308,53.6923077 L47.9147082,53.6929023 C51.4728548,52.0320242 54.2170609,48.9190403 55.3851192,45.1161581 Z"
            />
            <rect
              id="Rectangle"
              x="12.4615385"
              y="0.615384615"
              width="5"
              height="13.8461538"
              rx="2.5"
            />
            <rect
              id="Rectangle"
              x="37.9230769"
              y="0.615384615"
              width="5"
              height="13.8461538"
              rx="2.5"
            />
          </g>
          <g id="Group-14" transform="translate(27.000000, 26.000000)">
            <path
              id="Oval"
              d="M15,-1.5 C5.88730163,-1.5 -1.5,5.88730163 -1.5,15 C-1.5,24.1126984 5.88730163,31.5 15,31.5 C24.1126984,31.5 31.5,24.1126984 31.5,15 C31.5,5.88730163 24.1126984,-1.5 15,-1.5 Z M15,1.5 C22.4558441,1.5 28.5,7.54415588 28.5,15 C28.5,22.4558441 22.4558441,28.5 15,28.5 C7.54415588,28.5 1.5,22.4558441 1.5,15 C1.5,7.54415588 7.54415588,1.5 15,1.5 Z"
              fill-rule="nonzero"
            />
            <path
              id="Combined-Shape"
              d="M8.5,7 C9.32842712,7 10,7.67157288 10,8.5 L10,12.999 L17.5,13 C18.3284271,13 19,13.6715729 19,14.5 C19,15.3284271 18.3284271,16 17.5,16 L8.5,16 L8.5,16 C7.67157288,16 7,15.3284271 7,14.5 L7,8.5 C7,7.67157288 7.67157288,7 8.5,7 Z"
              transform="translate(13.000000, 11.500000) rotate(-90.000000) translate(-13.000000, -11.500000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
