const _weekdays = [
  { id: 0, name: 'monday', shortName: 'Mon' },
  { id: 1, name: 'tuesday', shortName: 'Tue' },
  { id: 2, name: 'wednesday', shortName: 'Wed' },
  { id: 3, name: 'thursday', shortName: 'Thu' },
  { id: 4, name: 'friday', shortName: 'Fri' },
  { id: 5, name: 'saturday', shortName: 'Sat' },
  { id: 6, name: 'sunday', shortName: 'Sun' },
];

const _specialDayCombinations = [
  { value: 127, name: 'everyday' },
  { value: 31, name: 'weekdays' },
  { value: 96, name: 'weekends' },
];

const getDateFromIsoFormat = (inputDate) => {
  if (inputDate && inputDate.length > 10) {
    return inputDate.substring(0, 10);
  }
};

const convertWeekdayFlagIntToText = (
  weekdayFlagInt,
  specialDayCombinations = true,
) => {
  if (!weekdayFlagInt) {
    return '';
  }

  // For special combinations
  if (
    specialDayCombinations &&
    _specialDayCombinations.map((x) => x.value).includes(weekdayFlagInt)
  ) {
    return _specialDayCombinations.find((x) => x.value == weekdayFlagInt).name;
  }

  // Combinations not covered
  const resultArray = [];

  const NUM_DAYS_IN_WEEK_0_INDEXED = 6;
  let binaryDays = weekdayFlagInt.toString(2);

  binaryDays = binaryDays.padStart(NUM_DAYS_IN_WEEK_0_INDEXED + 1, '0');

  for (let i = 0; i <= NUM_DAYS_IN_WEEK_0_INDEXED; i++) {
    if (binaryDays[NUM_DAYS_IN_WEEK_0_INDEXED - i] === '1') {
      resultArray.push(_weekdays.find((x) => x.id == i).name);
    }
  }
  // return resultArray.join(', ');
  return resultArray;
};

const formatToHHMM = (inputTimeString) => {
  if (!inputTimeString) {
    return '';
  }

  //Expecting hh:mm:ss or hh:mm
  let timeToken = inputTimeString.trim().split(':');

  if (timeToken.length !== 2 && timeToken.length !== 3) {
    return '';
  }

  return `${timeToken[0]}:${timeToken[1]}`;
};

const getWeekdayName = (id) => {
  let startOnMonday = id === 0 ? 6 : id - 1;
  return _weekdays.find((day) => day.id === startOnMonday)?.name || '';
};

const padTo2Digits = (num) => {
  return num.toString().padStart(2, '0');
};

// yyyy-mm-dd hh:mm
export const formatDate = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    ' ' +
    [padTo2Digits(date.getHours()), padTo2Digits(date.getMinutes())].join(':')
  );
};

// yyyy-mm-dd-hhmmss
export const dateTimeSeconds = (date) => {
  return (
    [
      date.getFullYear(),
      padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
    ].join('-') +
    '-' +
    padTo2Digits(date.getHours()) +
    padTo2Digits(date.getMinutes()) +
    padTo2Digits(date.getSeconds())
  );
};

export default {
  getDateFromIsoFormat,
  convertWeekdayFlagIntToText,
  formatToHHMM,
  getWeekdayName,
};
