export const getWeek = (inDate) => {
  const date = new Date(inDate.getTime());
  date.setHours(0, 0, 0, 0);
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  const firstWeek = new Date(date.getFullYear(), 0, 4);

  return (
    1 +
    Math.round(
      ((date.getTime() - firstWeek.getTime()) / 86400000 -
        3 +
        ((firstWeek.getDay() + 6) % 7)) /
        7,
    )
  );
};

export const getDay = (date) => {
  return date.getDay() === 0 ? 7 : date.getDay();
};

export const validateDate = (date) => {
  if (/^\d{4}-\d{2}-\d{2}$/.test(date)) {
    date = date.split('-');
    return checkDateIsRightDate(
      new Date(date[0] + '-' + date[1] + '-' + date[2]),
      date[2],
    );
  } else if (
    /^\d{8}$/.test(date) &&
    date.length === 8 &&
    parseInt(date.substring(0, 4)) > 2000 &&
    parseInt(date.substring(4, 6)) < 13
  ) {
    return checkDateIsRightDate(
      new Date(
        date.substring(0, 4) +
          '-' +
          date.substring(4, 6) +
          '-' +
          date.substring(6, 8),
      ),
      date.substring(6, 8),
    );
  } else if (
    /^\d{6}$/.test(date) &&
    date.length === 6 &&
    parseInt(date.substring(0, 2)) > 12 &&
    parseInt(date.substring(2, 4)) < 13
  ) {
    return checkDateIsRightDate(
      new Date(
        '20' +
          date.substring(0, 2) +
          '-' +
          date.substring(2, 4) +
          '-' +
          date.substring(4, 6),
      ),
      date.substring(4, 6),
    );
  }
  return false;
};

const displayValue = (date) => {
  if (/^\d{8}$/.test(date) && date.length === 8) {
    return (
      date.substring(0, 4) +
      '-' +
      date.substring(4, 6) +
      '-' +
      date.substring(6, 8)
    );
  } else if (/^\d{6}$/.test(date) && date.length === 6) {
    return (
      '20' +
      date.substring(0, 2) +
      '-' +
      date.substring(2, 4) +
      '-' +
      date.substring(4, 6)
    );
  }
  return date;
};

const checkDateIsRightDate = (date, dateToCompare) => {
  //date returns wrong date if you enter ex feb 30 or sep 31, whit this check we avoid that
  if (date.getDate() !== parseInt(dateToCompare)) {
    return false;
  }
  return date;
};

export const hasSomeParentTheClass = (element, className) => {
  if (element.className?.split(' ').indexOf(className) >= 0) {
    return true;
  }
  return (
    element.parentNode && hasSomeParentTheClass(element.parentNode, className)
  );
};

export const value = (year, month, day) => {
  return (
    year +
    '-' +
    ('0' + String(month)).slice(-2) +
    '-' +
    ('0' + String(day)).slice(-2)
  );
};

export const updateValue = (dValue, dateTime, defaultTime) => {
  if (dValue) {
    dValue = displayValue(dValue);
    let dTime = dateTime ? dateTime : defaultTime ? defaultTime : '00:00';
    return dValue + ' ' + dTime;
  }
  return '';
};
