export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const iterateTargetKey = (obj, targetKey, callback) => {
  Object.keys(obj).forEach((key) => {
    if (key === targetKey && obj[key]) {
      callback(obj);
    }
    if (obj[key] && typeof obj[key] === 'object') {
      iterateTargetKey(obj[key], targetKey, callback);
    }
  });
};
