<template>
  <svg
    width="9px"
    height="10px"
    viewBox="0 0 9 10"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
      stroke-linecap="round"
    >
      <g
        id="icons-sp"
        transform="translate(-985.000000, -538.000000)"
        stroke="currentColor"
        stroke-width="1.5"
      >
        <g id="Group-Copy-19" transform="translate(959.000000, 513.000000)">
          <g id="sort" transform="translate(27.000000, 26.000000)">
            <g
              id="sort-arrow-upp-copy"
              transform="translate(3.500000, 4.000000) rotate(-180.000000) translate(-3.500000, -4.000000) "
            >
              <g id="Group-2">
                <path id="Line-2" d="M3.5,7.5 L3.5,0.5" />
                <path id="Line-2" d="M0.671572875,3.32842712 L3.5,0.5" />
                <path id="Line-2" d="M6.32842712,3.32842712 L3.5,0.5" />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
