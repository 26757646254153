<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.2315 0.74877C11.6388 0.925539 11.8807 1.34961 11.8256 1.79011L11.1328 7.33274H17.5C17.888 7.33274 18.241 7.5572 18.4056 7.90858C18.5702 8.25997 18.5166 8.67484 18.2682 8.97292L9.9349 18.9729C9.65071 19.314 9.1757 19.4268 8.76849 19.25C8.36127 19.0733 8.11934 18.6492 8.1744 18.2087L8.86723 12.6661H2.50001C2.11199 12.6661 1.759 12.4416 1.59442 12.0902C1.42984 11.7388 1.48339 11.324 1.73179 11.0259L10.0651 1.02589C10.3493 0.684853 10.8243 0.572001 11.2315 0.74877ZM4.63505 10.6661H10C10.2868 10.6661 10.5599 10.7892 10.7497 11.0043C10.9395 11.2193 11.0279 11.5055 10.9923 11.7901L10.582 15.0723L15.365 9.33274H10C9.71318 9.33274 9.44016 9.20957 9.25033 8.99454C9.06051 8.77951 8.97216 8.49332 9.00773 8.2087L9.41801 4.92653L4.63505 10.6661Z"
      fill="currentColor"
    />
  </svg>
</template>
