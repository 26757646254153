<template>
  <div class="va-field va-multiple-select">
    <label class="title-label">{{ computedLabel }}</label>
    <va-dropdown-search
      :placeholder="computedPlaceholder"
      :dropdown-options="computedOptions"
      :single-select="false"
      :initial-values="value"
      @selection_updated="onSelectionUpdated"
    />
  </div>
</template>

<script>
import VaDropdownSearch from '@/components/framework/va-dropdown-search.vue';

export default {
  components: {
    VaDropdownSearch,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  computed: {
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    computedPlaceholder: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.placeholder === 'object' &&
        this.fieldModel.placeholder !== null
      ) {
        if (this.fieldModel.placeholder[this.$getUserlanguage()]) {
          return this.fieldModel.placeholder[this.$getUserlanguage()];
        }
        return this.fieldModel.placeholder['en_US'];
      } else {
        return this.fieldModel.placeholder;
      }
    },
    computedOptions: function () {
      let computed = {};
      if (this.fieldModel.options) {
        Object.keys(this.fieldModel.options).map((key) => {
          if (
            typeof this.fieldModel.options[key] === 'object' &&
            this.fieldModel.options[key] !== null
          ) {
            if (this.fieldModel.options[key][this.$getUserlanguage()]) {
              computed[key] =
                this.fieldModel.options[key][this.$getUserlanguage()];
              return computed[key];
            } else {
              computed[key] = this.fieldModel.options[key]['en_US'];
              return computed[key];
            }
          } else {
            return (computed[key] = this.fieldModel.options[key]);
          }
        });
      }
      return Object.entries(computed).map(([key, value]) => ({
        id: key,
        displayName: value,
      }));
    },
    value() {
      return this.data?.[this.fieldModel.fieldName] || [];
    },
  },
  methods: {
    onSelectionUpdated(updatedSelection) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = updatedSelection.map((s) => s.id);
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss">
.va-multiple-select {
  text-align: left;
}
</style>
