<template>
  <div class="va-multiple-media">
    <label class="title-label">{{ computedLabel }}</label>
    <div class="wrapper">
      <div
        v-for="media in selectedMedia"
        :key="media.media"
        class="media-container"
      >
        <va-img
          :src="media.thumbnailUrl.replace('thumbnail', 'preview')"
          media-type="Image"
          class="thumbnail"
        />
        <va-dropdown
          :dropdown-actions="dropdownActions"
          class="media-dropdown"
          @DROPDOWN_SELECTED="onDropdownItemClick($event, media)"
        >
          <svg-icon icon="options" class="options" />
        </va-dropdown>
        <div v-if="media.isDefault" class="media-is-default">
          {{ $translate('generic.default') }}
        </div>
        <div class="media-tooltip">
          <div class="media-tooltip-row">
            {{ $translate('media.metadata.displayName') }}:
            {{ getMetadataAttribute(media.media, 'displayName') }}
          </div>
          <div class="media-tooltip-row">
            {{ $translate('media.metadata.uploadDate') }}:
            {{ getMetadataAttribute(media.media, 'uploadDate') }}
          </div>
          <div class="media-tooltip-row">
            {{ $translate('media.metadata.description') }}:
            {{ getMetadataAttribute(media.media, 'description') }}
          </div>
        </div>
      </div>
      <div class="media-container">
        <va-button
          type="cancel"
          :text="$translate('media.browseMedia')"
          class="browse-button"
          :data-testid="`va-multiple-media--browse-button-${fieldModel.id}`"
          @click="browseMedia"
        />
      </div>
    </div>

    <va-media-picker
      v-if="showMediaPicker"
      :library="fieldModel.mediaLibrary"
      @close="showMediaPicker = false"
      @selectedMedia="onSelectedMedia"
    />
    <input
      id="file"
      ref="myFiles"
      type="file"
      name="file"
      multiple
      style="display: none"
      @change="onUploadMedia($event.target.files)"
    />

    <va-modal
      v-if="showEditInfoModal"
      type="confirm"
      :width="720"
      @confirm="onSaveMetadata"
      @close="showEditInfoModal = false"
    >
      <template #header>
        <h3>
          {{ $translate('multipleMedia.modals.edit.title') }}
        </h3>
      </template>
      <template #body>
        <div>
          <div class="edit-info-field">
            <span>{{ $translate('multipleMedia.modals.edit.imageName') }}</span>
            <input v-model="selectedMetadata.displayName" />
          </div>
          <div class="edit-info-field">
            <span>{{
              $translate('multipleMedia.modals.edit.imageDescription')
            }}</span>
            <textarea
              v-model="selectedMetadata.description"
              cols="30"
              rows="3"
              maxlength="256"
            />
          </div>
          <label class="edit-info-label"
            >{{ $translate('multipleMedia.modals.edit.uploadDate') }}:
            {{ selectedMetadata.uploadDate }}</label
          >
        </div>
      </template>
      <template #footer>
        <button
          class="modal-default-button confirm-button"
          @click="onSaveMetadata"
        >
          <p>{{ $translate('generic.save') }}</p>
        </button>
        <button
          class="modal-default-button cancel-button"
          @click="showEditInfoModal = false"
        >
          <p>{{ $translate('generic.cancel') }}</p>
        </button>
      </template>
    </va-modal>
  </div>
</template>

<script>
import VaModal from '@/components/framework/va-modal.vue';
import VaImg from '@/components/framework/va-img.vue';
import VaButton from '@/components/framework/va-button.vue';
import VaMediaPicker from '@/components/framework/va-media-picker.vue';
import VaDropdown from '@/components/framework/va-dropdown.vue';
import SvgIcon from '@/components/icons/SvgIcon.vue';

import ImageService from '@/services/image';
import { withAsync } from '@/helpers/withAsync';
import {
  editMetadata,
  getMetadata,
  mediaLookup,
  mediaUpload,
} from '@/api/mediaApi';

export default {
  components: {
    VaModal,
    VaImg,
    VaButton,
    VaMediaPicker,
    VaDropdown,
    SvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      showMediaPicker: false,
      showEditInfoModal: false,
      metadata: [],
      selectedMetadata: {},
    };
  },
  computed: {
    computedLabel() {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
    selectedMedia() {
      if (!this.data?.[this.fieldModel.fieldName]) {
        return [];
      }

      return (
        this.data[this.fieldModel.fieldName].map((m) => ({
          media: m.media,
          thumbnailUrl: `${this.$getEnv(
            'VITE_API_URL_SIGNAGE_BACKEND',
          )}/api/media/${m.media}/thumbnail`,
          previewUrl: `${this.$getEnv(
            'VITE_API_URL_SIGNAGE_BACKEND',
          )}/api/media/${m.media}`,
          isDefault: m.isDefault,
        })) || []
      );
    },
    dropdownActions() {
      return [
        {
          Label: this.$translate('items.imageActions.default'),
          EmitAction: 'DEFAULT_IMAGE',
        },
        {
          Label: this.$translate('items.imageActions.download'),
          EmitAction: 'DOWNLOAD_IMAGE',
        },
        {
          Label: this.$translate('items.imageActions.editInfo'),
          EmitAction: 'EDIT_INFO',
        },
        {
          Label: this.$translate('items.imageActions.remove'),
          EmitAction: 'REMOVE_IMAGE',
        },
      ];
    },
  },
  watch: {
    selectedMedia: {
      handler(newValue) {
        newValue.forEach((m) => {
          this.getMetadata(m.media, this.addMetadata);
        });
      },
      deep: true,
    },
  },
  methods: {
    browseMedia() {
      switch (this.type?.toLowerCase()) {
        case 'library':
          this.showMediaPicker = true;
          break;
        case 'disk':
          this.$refs.myFiles.value = ''; // Reset files
          this.$refs.myFiles.click();
          break;
      }
    },
    onSelectedMedia(mediaList = []) {
      this.addMedia(mediaList);
      this.showMediaPicker = false;
    },
    async onUploadMedia(files = []) {
      const mediaLibrary = this.fieldModel.mediaLibrary;

      for (let i = 0; i < files.length; i++) {
        const { response } = await withAsync(mediaLookup, {
          searchText: files[i].name,
          lookupBy: 'FileName',
          mediaLibrary: mediaLibrary,
        });
        if (response?.data) {
          const item = response.data;

          var fileList = new FormData();
          if (item.found) {
            fileList.append(
              item.proposedFileName,
              files[i],
              item.proposedFileName,
            );
          } else {
            fileList.append(files[i].name, files[i], files[i].name);
          }
          this.uploadMedia(fileList, mediaLibrary);
        }
      }
    },
    async uploadMedia(fileList, mediaLibrary) {
      const { response } = await withAsync(
        mediaUpload,
        fileList,
        this.$translate,
        {
          mediaLibrary: mediaLibrary,
        },
      );
      if (response?.data?.Medias) {
        this.addMedia(response.data.Medias);
      }
    },
    addMedia(mediaList = []) {
      // TODO Add type
      let addedMedia = mediaList.map((m) => ({
        media: m.mediaCode,
        isDefault: false,
        type: null,
      }));
      let currentMedia = this.data[this.fieldModel.fieldName];

      if (!currentMedia) {
        currentMedia = addedMedia;
      }

      addedMedia.forEach((newItem) => {
        if (!currentMedia.find((m) => m.media === newItem.media)) {
          currentMedia.push(newItem);
        }
      });

      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = currentMedia;
      this.$emit('updatedData');
    },
    removeMedia(media = {}) {
      let index = this.data[this.fieldModel.fieldName].findIndex(
        (m) => m.media === media.media,
      );
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName].splice(index, 1);
      this.$emit('updatedData');
    },
    setDefaultMedia(media = {}) {
      this.data[this.fieldModel.fieldName].forEach((m) => {
        m.isDefault = m.media === media.media ? true : false;
      });
      this.$emit('updatedData');
    },
    downloadImage(media = {}) {
      ImageService.getImage(media.previewUrl)
        .then((img) => {
          // Create an invisible A element
          const a = document.createElement('a');
          a.style.display = 'none';
          document.body.appendChild(a);

          // Set the HREF to a Blob representation of the data to be downloaded
          a.href = URL.createObjectURL(img.data);

          // Use download attribute to set desired file name
          a.setAttribute(
            'download',
            this.metadata.find((m) => m.mediaCode === media.media)
              ?.displayName || 'image',
          );

          // Trigger the download by simulating click
          a.click();

          // Cleanup
          window.URL.revokeObjectURL(a.href);
          document.body.removeChild(a);
        })
        .catch((error) => {
          console.error(error);
        });
    },
    onDropdownItemClick(action, media) {
      switch (action) {
        case 'DEFAULT_IMAGE':
          this.setDefaultMedia(media);
          break;
        case 'REMOVE_IMAGE':
          this.removeMedia(media);
          break;
        case 'DOWNLOAD_IMAGE':
          this.downloadImage(media);
          break;
        case 'EDIT_INFO':
          this.getMetadata(media.media, this.editInfo);
      }
    },
    editInfo(metadata) {
      this.selectedMetadata = metadata;
      this.showEditInfoModal = true;
    },
    addMetadata(metadata) {
      let index = this.metadata.findIndex(
        (m) => m.mediaCode === metadata.mediaCode,
      );
      if (index >= 0) {
        this.metadata[index] = metadata;
      } else {
        this.metadata.push(metadata);
      }
    },
    async getMetadata(mediaCode, callback) {
      const { response } = await withAsync(getMetadata, mediaCode);
      if (response?.data) {
        callback(response.data);
      }
    },
    async onSaveMetadata() {
      const { response } = await withAsync(
        editMetadata,
        this.selectedMetadata.mediaCode,
        this.selectedMetadata,
      );
      if (response) {
        this.getMetadata(this.selectedMetadata.mediaCode, this.addMetadata);
      }

      this.showEditInfoModal = false;
    },
    getMetadataAttribute(mediaCode, attribute) {
      return this.metadata.find((m) => m.mediaCode === mediaCode)?.[attribute];
    },
  },
};
</script>

<style lang="scss">
.va-multiple-media {
  text-align: left;

  .wrapper {
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    .media-container {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px dashed #4c4b51;
      margin-right: 20px;
      margin-bottom: 20px;
      width: 174px;
      height: 175px;
      background-color: #181e24;

      .thumbnail {
        width: 100%;
        height: 100%;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        opacity: 1;
        transition: opacity 0.2s;

        :deep(.va-img) {
          object-fit: cover;
        }
      }

      .media-dropdown {
        position: absolute;
        right: 0;
        top: 0;
      }

      .media-is-default {
        position: absolute;
        top: 5px;
        left: 5px;
        color: $color-text-secondary;
      }

      .media-tooltip {
        position: absolute;
        background-color: #181e24;
        padding: 10px;
        opacity: 0;
        display: none;
        transition: opacity 0.2s;
        font-size: 13px;
        line-height: 15px;
        color: $color-text-secondary;
        max-width: 300px;
        bottom: 184px;
        left: -2px;

        .media-tooltip-row {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }

      &:hover {
        .thumbnail {
          opacity: 0.3;
        }

        .media-tooltip {
          opacity: 1;
          display: block;
        }
      }
    }
  }

  .edit-info-field {
    position: relative;
    margin-bottom: 20px;

    input,
    textarea {
      width: 100%;
      background-color: #4b4b51;
      color: white;
      border: none;
      outline: none;
      box-sizing: border-box;
      padding-left: 12px;
      font-family: Roboto, sans-serif;
      font-size: 14px;
      letter-spacing: 0.35px;
      line-height: 19px;
    }

    input {
      height: 50px;
      padding-top: 10px;
    }

    textarea {
      height: 90px;
      padding-top: 22px;
      resize: none;
      overflow-y: scroll;
    }

    span {
      color: $color-text-secondary;
      position: absolute;
      left: 12px;
      font-family: Roboto, sans-serif;
      font-size: 10px;
      letter-spacing: 0.25px;
      line-height: 13px;
      top: 8px;
      z-index: 20;
    }
  }

  :deep(.va-modal) .modal-body p {
    padding-bottom: 0;
  }

  .edit-info-label {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    letter-spacing: 0.35px;
    line-height: 19px;
  }
}
</style>
