<template>
  <svg
    width="21px"
    height="14px"
    viewBox="0 0 21 14"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="horizontal-rule-icon" fill="#BABABA">
        <g id="Group-7">
          <rect id="Rectangle-Copy-3" x="0" y="6" width="21" height="2.5" />
          <rect id="Rectangle-Copy-17" x="4" y="13" width="13" height="1" />
          <g id="Group-8" transform="translate(4.000000, 0.500000)">
            <rect id="Rectangle-Copy-35" x="0" y="0" width="13" height="1" />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
