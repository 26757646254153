import timezoneServices from '@/services/timezone';

const SET_TIMEZONES = 'SET_TIMEZONES';

// state
const state = {
  timeZones: [],
};

// getters
const getters = {
  availableTimeZones: (state) => state.timeZones,
};

// actions
const actions = {
  searchTimeZones({ commit }, { searchText }) {
    timezoneServices
      .searchTimeZones(searchText)
      .then((timezoneData) => {
        commit(SET_TIMEZONES, { timeZones: timezoneData });
      })
      .catch((error) => {
        console.error(error);
      });
  },
};

// mutations
const mutations = {
  [SET_TIMEZONES](state, { timeZones }) {
    state.timeZones = timeZones;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
