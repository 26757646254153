import axios from 'axios';
import Env from '@/utils/env';
const CancelToken = axios.CancelToken;
let cancelSearchPlayers;
let cancelSearchPlayersWithBroadcastCode;
let cancelSearchBroadcastPlayers;

export default {
  getPlayers(searchText, orderBy, order, maxItemsInResult, skipItemsInResult) {
    return new Promise((resolve, reject) => {
      axios
        .get(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/players', {
          params: {
            SearchText: searchText,
            OrderBy: orderBy,
            Order: order,
            MaxItemsInResult: maxItemsInResult,
            SkipItemsInResult: skipItemsInResult,
          },
        })
        .then((response) => {
          resolve(response.data.Players);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when fetching your players.');
        });
    });
  },

  getPlayerSettings(playerCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/settings',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when fetching your player settings.');
        });
    });
  },

  getPlayerInformation(playerCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/information',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when fetching your player information.');
        });
    });
  },

  getPlayerPermissions(playerCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/permissions',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when fetching your player information.');
        });
    });
  },

  editPlayerSettings(playerCode, settings) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/settings',
          { settings },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player settings.');
        });
    });
  },

  editMultiplePlayerSettings(settings) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/modifymultiple',
          settings,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player settings.');
        });
    });
  },

  editPlayerSecret(playerCode, playerSecret) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/settings',
          { pair: { playerSecret: playerSecret } },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player settings.');
        });
    });
  },

  editPlayerPermissions(playerCode, permissionTags) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/permissions',
          { permissionTags },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player settings.');
        });
    });
  },

  editPlayerFormat(playerCode, format) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/settings',
          { format },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player settings.');
        });
    });
  },

  editPlayerLocation(playerCode, location) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/settings',
          { location },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player settings.');
        });
    });
  },

  editMultiplePlayerLocation(playerLocation) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/players/location',
          playerLocation,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your players location.');
        });
    });
  },

  addNewPlayer(
    playerSecret,
    playerName,
    screenId,
    mountedDirection,
    playerFormatCode,
    pairScreenWithAccount,
    tags,
    locationCode,
  ) {
    return new Promise((resolve, reject) => {
      axios
        .post(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/players', {
          playerSecret: playerSecret,
          name: playerName,
          playerFormatCode: playerFormatCode,
          mountedDirection: mountedDirection,
          pairScreenWithAccount: pairScreenWithAccount,
          tags: tags,
          locationCode: locationCode,
          externalId: screenId,
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player settings.');
        });
    });
  },

  editPlayerData(playerCode, data) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/settings',
          data,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  deletePlayer(playerCode) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when deleting your player.');
        });
    });
  },

  deleteMultiplePlayers(playerCodes) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/deletemultiple',
          playerCodes,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when deleting your players.');
        });
    });
  },

  updateShowNameInfo(playerCode, showNameInfo) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/shownameinfo',
          { showNameInfo },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when updating show name info for your player.',
          );
        });
    });
  },

  lookupPlayerByPlayerSecret(playerSecret) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/lookupPlayerByPlayerSecret/' +
            playerSecret,
          {},
        )
        .then((response) => {
          resolve({ valid: true, player: response.data });
        })
        .catch((error) => {
          reject(error);
        });
    });
  },

  rebootPlayer(playerCode) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/reboot',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject({
            message: 'An error has occured when trying to reboot your player.',
            error: error,
          });
        });
    });
  },

  rebootMultiplePlayers(playerCodes) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/rebootplayers',
          playerCodes,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          reject({
            message: 'An error has occured when trying to reboot your players.',
            error: error,
          });
        });
    });
  },

  orderPlayerLogs(playerCode) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/logs',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject("An error has occured when ordering your player's logs.");
        });
    });
  },

  orderPlayerScreenshot(playerCode) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/screenshot',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            "An error has occured when ordering your player's screenshot.",
          );
        });
    });
  },

  getPlayerFormats() {
    return new Promise((resolve) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/playerformats/',
          {},
        )
        .then((response) => {
          resolve({ valid: true, playerFormats: response.data.PlayerFormats });
        })
        .catch((error) => {
          console.error(error);
          resolve({ valid: false, playerFormats: [] });
        });
    });
  },

  editMultiplePlayerTags(playerTags) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/players/tags',
          playerTags,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player tags.');
        });
    });
  },

  searchPlayers(
    condition,
    searchText,
    orderBy,
    order,
    maxItemsInResult,
    skipItemsInResult,
    now,
    playerStatus,
  ) {
    return new Promise((resolve, reject) => {
      if (cancelSearchPlayers) {
        cancelSearchPlayers();
      }
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/players/search',
          {
            Condition: condition,
            SearchText: searchText,
            OrderBy: orderBy,
            Order: order,
            MaxItemsInResult: maxItemsInResult,
            SkipItemsInResult: skipItemsInResult,
            Now: now,
            PlayerStatus: playerStatus,
          },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancelSearchPlayers = c;
            }),
          },
        )
        .then((response) => {
          cancelSearchPlayers = null;
          resolve(response.data);
        })
        .catch((error) => {
          cancelSearchPlayers = null;
          if (axios.isCancel(error)) {
            console.warn('Request canceled, triggered by new search');
          } else {
            reject('An error has occured when searching players.');
          }
        });
    });
  },

  searchPlayersWithBroadcastCode(
    broadcastCode,
    condition,
    searchText,
    orderBy,
    order,
    maxItemsInResult,
    skipItemsInResult,
    contentTypeCodes,
    playoutFormatLayerCodes,
  ) {
    return new Promise((resolve, reject) => {
      if (cancelSearchPlayersWithBroadcastCode) {
        cancelSearchPlayersWithBroadcastCode();
      }
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/broadcasts/' +
            broadcastCode +
            '/players',
          {
            Condition: condition,
            SearchText: searchText,
            OrderBy: orderBy,
            Order: order,
            MaxItemsInResult: maxItemsInResult,
            SkipItemsInResult: skipItemsInResult,
            ContentTypeCodes: contentTypeCodes,
            PlayoutFormatLayerCodes: playoutFormatLayerCodes,
          },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancelSearchPlayersWithBroadcastCode = c;
            }),
          },
        )
        .then((response) => {
          cancelSearchPlayersWithBroadcastCode = null;
          resolve(response.data);
        })
        .catch((error) => {
          cancelSearchPlayersWithBroadcastCode = null;
          if (axios.isCancel(error)) {
            console.warn('Request canceled, triggered by new search');
          } else {
            reject('An error has occured when searching players.');
          }
        });
    });
  },

  searchBroadcastPlayers(
    condition,
    searchText,
    orderBy,
    order,
    maxItemsInResult,
    skipItemsInResult,
    contentTypeCodes,
    playoutFormatLayerCodes,
  ) {
    return new Promise((resolve, reject) => {
      if (cancelSearchBroadcastPlayers) {
        cancelSearchBroadcastPlayers();
      }
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/broadcasts/players',
          {
            Condition: condition,
            SearchText: searchText,
            OrderBy: orderBy,
            Order: order,
            MaxItemsInResult: maxItemsInResult,
            SkipItemsInResult: skipItemsInResult,
            ContentTypeCodes: contentTypeCodes,
            PlayoutFormatLayerCodes: playoutFormatLayerCodes,
          },
          {
            cancelToken: new CancelToken(function executor(c) {
              // An executor function receives a cancel function as a parameter
              cancelSearchBroadcastPlayers = c;
            }),
          },
        )
        .then((response) => {
          cancelSearchBroadcastPlayers = null;
          resolve(response.data);
        })
        .catch((error) => {
          cancelSearchBroadcastPlayers = null;
          if (axios.isCancel(error)) {
            console.warn('Request canceled, triggered by new search');
          } else {
            reject('An error has occured when searching players.');
          }
        });
    });
  },

  getPlayerTags(playerCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/tags',
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when getting your player tags.');
        });
    });
  },

  editPlayerTags(playerCode, payload) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/tags',
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when editing your player tags.');
        });
    });
  },

  replacePlayer(playerCode, playerSecret) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerCode +
            '/replace/' +
            playerSecret,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when replacing your player.');
        });
    });
  },
};
