const RoleView = () => import('@/views/roles/RoleView.vue');

const overviewRoutes = [
  {
    path: '/roles/new',
    name: 'NewRoleView',
    components: { fullscreen: RoleView },
    meta: {
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.users'),
            route: { name: 'UserView' },
          },
          {
            name: translate('roles.new'),
          },
        ];
      },
    },
  },
  {
    path: '/roles/edit/:roleCode',
    name: 'EditRoleView',
    components: { fullscreen: RoleView },
    meta: {
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.users'),
            route: { name: 'UserView' },
          },
          {
            name: translate('roles.edit'),
          },
        ];
      },
    },
  },
];
export default overviewRoutes;
