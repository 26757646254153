import axios from 'axios';
import Env from '@/utils/env';
import { formatDate } from '@/helpers/dateTimeHelper';

export default {
  getPlayerChannels(playerCode) {
    const now = formatDate(new Date());
    return new Promise((resolve, reject) => {
      if (!playerCode) {
        resolve({});
      }
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/player/' +
            playerCode,
          { params: { now: now } },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when fetching the broadcasts for the player: ' +
              playerCode,
          );
        });
    });
  },

  updateChannelStatus(playerCode, channelCode, isActive, isTakeover) {
    return new Promise((resolve, reject) => {
      if (!playerCode || !channelCode) {
        resolve({});
      }

      let payload = {
        PlayerCode: playerCode,
        ChannelCode: channelCode,
        IsActive: isActive,
        IsTakeover: isTakeover,
      };

      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/' +
            channelCode,
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when fetching the broadcasts for the player: ' +
              playerCode,
          );
        });
    });
  },

  updateMultipleChannelStatus(channels) {
    return new Promise((resolve, reject) => {
      if (!channels) {
        resolve({});
      }

      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/modifyMultiple',
          channels,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured when modifying channels');
        });
    });
  },

  addMediaToChannel(channelCode, mediaCode, desiredPosition) {
    return new Promise((resolve, reject) => {
      if (!channelCode || !mediaCode) {
        resolve({});
      }

      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/' +
            channelCode +
            '/media/' +
            mediaCode,
          { desiredPosition: desiredPosition },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when adding media to a channel: ' +
              channelCode,
          );
        });
    });
  },

  setBroadcastMediaScheduleInChannel(channelCode, broadcastMediaCode, periods) {
    return new Promise((resolve, reject) => {
      if (!channelCode || !broadcastMediaCode || !periods) {
        resolve({});
      }

      let payload = {
        channelCode: channelCode,
        broadcastMediaCode: broadcastMediaCode,
        periods: periods,
      };

      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/' +
            channelCode +
            '/media',
          payload,
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when setting the schedule of a media: ' +
              broadcastMediaCode,
          );
        });
    });
  },

  setBroadcastMediaDurationInChannel(
    channelCode,
    broadcastMediaCode,
    durationInSeconds,
  ) {
    return new Promise((resolve, reject) => {
      if (!channelCode || !broadcastMediaCode || !status) {
        resolve({});
      }

      let payload = {
        channelCode: channelCode,
        broadcastMediaCode: broadcastMediaCode,
        durationSeconds: {
          durationSeconds: durationInSeconds,
        },
      };

      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/' +
            channelCode +
            '/media',
          payload,
        )
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when setting the duration of a media: ' +
              broadcastMediaCode,
          );
        });
    });
  },

  reorderBroadcastMediaInChannel(channelCode, mediaRows) {
    return new Promise((resolve, reject) => {
      if (!channelCode || !mediaRows || mediaRows.length === 0) {
        resolve({});
      }

      let payload = {
        channelCode: channelCode,
        mediaRows: mediaRows.map((mediaRow, idx) => ({
          orderN: idx,
          broadcastMediaCode: mediaRow.broadcastMediaCode,
          displayName: mediaRow.displayName,
        })),
      };

      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/' +
            channelCode +
            '/media/',
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when reordering the broadcast media from a channel: ' +
              channelCode,
          );
        });
    });
  },

  removeMediaFromChannel(channelCode, broadcastMediaCode) {
    return new Promise((resolve, reject) => {
      if (!channelCode || !broadcastMediaCode) {
        resolve({});
      }

      axios
        .delete(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/' +
            channelCode +
            '/media/' +
            broadcastMediaCode,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when removing a broadcast media from a channel: ' +
              channelCode,
          );
        });
    });
  },

  removeMultipleMediaFromChannel(channelCode, broadcastMediaCodes) {
    return new Promise((resolve, reject) => {
      if (!channelCode || !broadcastMediaCodes) {
        resolve({});
      }

      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/' +
            channelCode +
            '/media/deletemultiple',
          broadcastMediaCodes,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when removing a broadcast media from a channel: ' +
              channelCode,
          );
        });
    });
  },

  setMultipleBroadcastMediaStatusInChannel(channelCode, broadcastMediaStatus) {
    return new Promise((resolve, reject) => {
      if (!channelCode || !broadcastMediaStatus) {
        resolve({});
      }

      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/' +
            channelCode +
            '/modifymultiplemedia',
          broadcastMediaStatus,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when setting the status of a media: ' +
              channelCode,
          );
        });
    });
  },

  getMultiplePlayerChannels({ filterCode }) {
    return new Promise((resolve, reject) => {
      let payload = {
        FilterCode: filterCode,
      };

      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/channels/player/multiple',
          payload,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject(
            'An error has occured when searching for multiple player channels',
          );
        });
    });
  },
};
