// ! Don't use this, should be removed when all references has been removed
// Use TagType enum instead (src/enums/tag.ts)
export const tagType = {
  PLAYER: 'Player',
  MEDIA: 'Media',
  CONTENT_TYPE: 'ContentType',
  AI: 'AI',
};

// ! Don't use this, should be removed when all references has been removed
// Use TagType enum instead (src/enums/tag.ts)
export const TAG_TYPE_PLAYER = 'Player';
export const TAG_TYPE_MEDIA = 'Media';
export const TAG_TYPE_CONTENT_TYPE = 'ContentType';
export const TAG_TYPE_AI = 'AI';

// ! Don't use this, should be removed when all references has been removed
// Use TagType enum instead (src/enums/tag.ts)
export const getTagTypes = () => {
  return {
    PLAYER: 'Player',
    MEDIA: 'Media',
    CONTENT_TYPE: 'ContentType',
    AI: 'AI',
  };
};
