<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="icons-sp" transform="translate(-150.000000, -525.000000)">
        <g id="Group-Copy-29" transform="translate(130.000000, 505.000000)">
          <g id="remove" transform="translate(20.000000, 20.000000)">
            <g id="contact-circle-copy-4">
              <g id="Group-8">
                <circle
                  id="circle"
                  fill="currentColor"
                  cx="10"
                  cy="10"
                  r="10"
                />
                <g
                  id="Group-Copy"
                  transform="translate(10.171573, 9.778175) rotate(-315.000000) translate(-10.171573, -9.778175) translate(4.671573, 4.278175)"
                  fill="#181E24"
                  fill-rule="nonzero"
                >
                  <path
                    id="cross"
                    d="M6.96356691,9.9562076 L6.96356691,1.7062076 C6.96356691,0.946816071 6.34795845,0.331207602 5.58856691,0.331207602 C4.82917538,0.331207602 4.21356691,0.946816071 4.21356691,1.7062076 L4.21356691,9.9562076 C4.21356691,10.7155991 4.82917538,11.3312076 5.58856691,11.3312076 C6.34795845,11.3312076 6.96356691,10.7155991 6.96356691,9.9562076 Z"
                    transform="translate(5.588567, 5.831208) rotate(-315.000000) translate(-5.588567, -5.831208) "
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
