const MediaView = () => import('@/views/media/MediaView.vue');
const AiImageGeneratorView = () =>
  import('@/views/media/aiGenerator/AiImageGeneratorView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');
import { translate } from '@/helpers/translationHelper';
import { Permission } from '@/helpers/constants';
import { ValidPermission } from '@/enums/permission';

const mediaRoutes = [
  {
    path: '/media',
    name: 'MediaView',
    components: { default: MediaView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Media', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ translate }) => {
        return [{ name: translate('sidebar.media') }];
      },
    },
  },
  {
    path: '/media/generate-image',
    name: 'AiImageGeneratorView',
    components: { fullscreen: AiImageGeneratorView },
    meta: {
      permission: {
        resources: [
          {
            name: Permission.MediaLibrary.GenerateAiImage,
            action: ValidPermission.EDIT,
            isClient: true,
          },
        ],
        config: {
          noAccessRedirect: 'MediaView',
        },
      },
      breadcrumbs: [
        {
          name: translate('generic.mediaLib'),
          route: { name: 'MediaView' },
        },
        {
          name: translate('media.aiGenerator.generateImage'),
        },
      ],
    },
  },
];
export default mediaRoutes;
