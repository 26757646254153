export enum ValidPermission {
  HIDE = 'Hide',
  DISABLED = 'Disabled',
  VIEW = 'ViewOnly',
  EDIT = 'AddAndEdit',
  ENABLED = 'Enabled',
}

export enum ComponentId {
  ACCOUNT_CLIENT_HTML_TEMPLATE = 'AccountClientHtmlTemplate',
  ACCOUNT_CLIENT_PLAYOUT_FORMAT = 'AccountClientPlayoutFormat',
  ACCOUNT_FEATURE = 'AccountFeature',
  SUPPORT_FEATURE = 'SupportFeature',
  MULTI_ACCOUNT_ACCESS = 'MultiAccountAccess',
}
