<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 60.1 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="contact-circle-copy-6-support-login-circle">
        <circle id="circle" fill="currentColor" cx="10" cy="10" r="10" />
        <rect
          id="Rectangle"
          fill="#181E24"
          x="4"
          y="5"
          width="7"
          height="2"
          rx="1"
        />
        <rect
          id="Rectangle-Copy"
          fill="#181E24"
          x="4"
          y="13"
          width="7"
          height="2"
          rx="1"
        />
        <rect
          id="Rectangle-Copy-2"
          fill="#181E24"
          x="8"
          y="9"
          width="8.5"
          height="2"
          rx="1"
        />
        <rect
          id="Rectangle-Copy-2"
          fill="#181E24"
          transform="translate(14.439340, 11.060660) rotate(-45.000000) translate(-14.439340, -11.060660) "
          x="11.9393396"
          y="10.0606604"
          width="5"
          height="2"
          rx="1"
        />
        <rect
          id="Rectangle-Copy-2"
          fill="#181E24"
          transform="translate(14.439340, 8.939340) rotate(-315.000000) translate(-14.439340, -8.939340) "
          x="11.9393396"
          y="7.93933964"
          width="5"
          height="2"
          rx="1"
        />
        <rect
          id="Rectangle-Copy"
          fill="#181E24"
          transform="translate(5.000000, 10.000000) rotate(-270.000000) translate(-5.000000, -10.000000) "
          x="-8.8817842e-16"
          y="9"
          width="10"
          height="2"
          rx="1"
        />
      </g>
    </g>
  </svg>
</template>
