const ADD_MESSAGE = 'ADD_MESSAGE';
const EDIT_MESSAGE = 'EDIT_MESSAGE';
const REMOVE_MESSAGE = 'REMOVE_MESSAGE';

// state
const state = {
  messages: { fields: [] },
};

// getters
const getters = {
  messages: (state) => state.messages,
};

// actions
const actions = {
  addMessage({ commit }, { message, time, id }) {
    if (id) {
      message.id = id;
    } else {
      var newId = ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (
          c ^
          (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
        ).toString(16),
      );
      message.id = newId;
    }
    commit(ADD_MESSAGE, message);
    if (time)
      setTimeout(function () {
        commit(REMOVE_MESSAGE, message.id);
      }, time);
  },
  editMessage({ commit }, { id, message, time }) {
    commit(EDIT_MESSAGE, { id: id, message: message });
    if (time)
      setTimeout(function () {
        commit(REMOVE_MESSAGE, id);
      }, time);
  },
  removeMessage({ commit }, { id }) {
    commit(REMOVE_MESSAGE, id);
  },
};

// mutations
const mutations = {
  [ADD_MESSAGE](state, field) {
    state.messages.fields.push(field);
  },
  [EDIT_MESSAGE](state, field) {
    for (var i in state.messages.fields) {
      if (state.messages.fields[i].id == field.id) {
        if (field.message.text) {
          state.messages.fields[i].text = field.message.text;
        }
        if (field.message.type) {
          state.messages.fields[i].type = field.message.type;
        }
        if (field.message.progress) {
          state.messages.fields[i].progress = field.message.progress;
        }
      }
    }
  },
  [REMOVE_MESSAGE](state, id) {
    for (var i in state.messages.fields) {
      if (state.messages.fields[i].id == id) {
        state.messages.fields.splice(i, 1);
      }
    }
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
