/* eslint-disable @typescript-eslint/no-unused-vars */
// TODO Fix no-unused-vars problem by removing actions and call methods inside component instead
import playerServices from '@/services/player';
import playlistServices from '@/services/playlist';
import channelServices from '@/services/channels';

const SET_PLAYERS = 'SET_PLAYERS';
const ADD_PLAYERS = 'ADD_PLAYERS';
const SET_TOTAL_PLAYER_COUNT = 'SET_TOTAL_PLAYER_COUNT';
const SET_PLAYLIST_ITEMS = 'SET_PLAYLIST_ITEMS';
const SET_CHANNEL_ITEMS = 'SET_CHANNEL_ITEMS';
const SET_PLAYER_INFORMATION = 'SET_PLAYER_INFORMATION';
const REMOVE_PLAYER = 'REMOVE_PLAYER';
const SET_PLAYERS_CHANNELS = 'SET_PLAYERS_CHANNELS';

// state
const state = {
  players: [],
  playlistItems: {}, // deprecated because we're now going over to channels
  channels: [],
  playerInformation: {},
  currentlyPlaying: {},
  totalPlayerCount: 0,
  playersChannels: [],
};

// getters
const getters = {
  players: (state) => state.players,
  playlistItems: (state) => state.playlistItems,
  playerInformation: (state) => state.playerInformation,
  channels: (state) => state.channels,
  totalPlayerCount: (state) => state.totalPlayerCount,
  playersChannels: (state) => state.playersChannels,
};

// actions
const actions = {
  getPlayers({ commit }, parameters) {
    return new Promise((resolve, reject) => {
      if (parameters) {
        playerServices
          .getPlayers(
            parameters.searchText,
            parameters.orderBy,
            parameters.order,
            parameters.maxItemsInResult,
            parameters.skipItemsInResult,
          )
          .then((players) => {
            if (parameters.skipItemsInResult) {
              commit(ADD_PLAYERS, { players });
            } else {
              commit(SET_PLAYERS, { players });
            }
            resolve(players);
          })
          .catch((error) => {
            console.error(error);
            reject('Error getting players');
          });
      } else {
        playerServices
          .getPlayers()
          .then((players) => {
            commit(SET_PLAYERS, { players });
            resolve(players);
          })
          .catch((error) => {
            console.error(error);
            reject('Error getting players');
          });
      }
    });
  },

  searchPlayers(
    { commit },
    {
      condition,
      searchText,
      orderBy,
      order,
      maxItemsInResult,
      skipItemsInResult,
      now,
      playerStatus,
    },
  ) {
    return new Promise((resolve, reject) => {
      // TODO Temporary fix for emptying list - should be removed from store and called directly in components with api.js
      if (!skipItemsInResult) {
        commit(SET_PLAYERS, { player: [] });
      }
      playerServices
        .searchPlayers(
          condition,
          searchText,
          orderBy,
          order,
          maxItemsInResult,
          skipItemsInResult,
          now,
          playerStatus,
        )
        .then((responseData) => {
          let players = responseData.Players;
          let totalPlayerCount = responseData.TotalCount;
          if (skipItemsInResult) {
            commit(ADD_PLAYERS, { players });
          } else {
            commit(SET_PLAYERS, { players });
          }
          commit(SET_TOTAL_PLAYER_COUNT, { totalPlayerCount });
          resolve(responseData);
        })
        .catch((error) => {
          console.error(error);
          reject('Error searching players');
        });
    });
  },

  searchPlayersWithBroadcastCode(
    { commit },
    {
      broadcastCode,
      condition,
      searchText,
      orderBy,
      order,
      maxItemsInResult,
      skipItemsInResult,
      contentTypeCodes,
      playoutFormatLayerCodes,
    },
  ) {
    return new Promise((resolve, reject) => {
      playerServices
        .searchPlayersWithBroadcastCode(
          broadcastCode,
          condition,
          searchText,
          orderBy,
          order,
          maxItemsInResult,
          skipItemsInResult,
          contentTypeCodes,
          playoutFormatLayerCodes,
        )
        .then((responseData) => {
          let players = responseData.Players;
          let totalPlayerCount = responseData.TotalCount;
          if (skipItemsInResult) {
            commit(ADD_PLAYERS, { players });
          } else {
            commit(SET_PLAYERS, { players });
          }
          commit(SET_TOTAL_PLAYER_COUNT, { totalPlayerCount });
          resolve(responseData);
        })
        .catch((error) => {
          console.error(error);
          reject('Error searching players');
        });
    });
  },

  searchBroadcastPlayers(
    { commit },
    {
      condition,
      searchText,
      orderBy,
      order,
      maxItemsInResult,
      skipItemsInResult,
      contentTypeCodes,
      playoutFormatLayerCodes,
    },
  ) {
    return new Promise((resolve, reject) => {
      playerServices
        .searchBroadcastPlayers(
          condition,
          searchText,
          orderBy,
          order,
          maxItemsInResult,
          skipItemsInResult,
          contentTypeCodes,
          playoutFormatLayerCodes,
        )
        .then((responseData) => {
          let players = responseData.Players;
          let totalPlayerCount = responseData.TotalCount;
          if (skipItemsInResult) {
            commit(ADD_PLAYERS, { players });
          } else {
            commit(SET_PLAYERS, { players });
          }
          commit(SET_TOTAL_PLAYER_COUNT, { totalPlayerCount });
          resolve(responseData);
        })
        .catch((error) => {
          console.error(error);
          reject('Error searching players');
        });
    });
  },

  getPlaylistItems({ commit }, { playerCode }) {
    if (playerCode) {
      channelServices
        .getPlayerChannels(playerCode)
        .then((responseObject) => {
          if (responseObject) {
            commit(SET_CHANNEL_ITEMS, { channelData: responseObject });
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  updateChannelStatus(
    { dispatch },
    { playerCode, channelCode, isActive, isTakeover },
  ) {
    if (playerCode && channelCode) {
      channelServices
        .updateChannelStatus(playerCode, channelCode, isActive, isTakeover)
        .then(() => {
          dispatch('getPlaylistItems', { playerCode: playerCode });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  updateMultipleChannelStatus(
    { dispatch },
    { playerCode, channelCodes, isActive, isTakeover },
  ) {
    return new Promise((resolve, reject) => {
      if (playerCode && channelCodes) {
        let payload = {
          Channels: [],
        };
        channelCodes.forEach((channelCode) => {
          payload.Channels.push({
            ChannelCode: channelCode,
            PlayerCode: playerCode,
            IsActive: isActive,
            IsTakeover: isTakeover,
          });
        });
        channelServices
          .updateMultipleChannelStatus(payload)
          .then((responseData) => {
            if (responseData.success.ok) {
              resolve(true);
            }
            resolve(false);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        resolve(false);
      }
    });
  },

  addMediaToChannel(
    { dispatch },
    { playerCode, channelCode, mediaCode, desiredPosition },
  ) {
    if (playerCode && channelCode && mediaCode) {
      channelServices
        .addMediaToChannel(channelCode, mediaCode, desiredPosition)
        .then(() => {
          dispatch('getPlaylistItems', { playerCode: playerCode });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  setBroadcastMediaDuration(
    { dispatch },
    { playerCode, channelCode, broadcastMediaCode, durationInSeconds },
  ) {
    if (playerCode && channelCode && broadcastMediaCode && durationInSeconds) {
      channelServices
        .setBroadcastMediaDurationInChannel(
          channelCode,
          broadcastMediaCode,
          durationInSeconds,
        )
        .then(() => {
          dispatch('getPlaylistItems', { playerCode: playerCode });
        })
        .catch((error) => {
          console.error(error);
          dispatch('addMessage', {
            message: {
              text: 'Error setting item duration.',
              buttonFunction: this.onAlertError,
              buttonParameters: error,
              buttonText: 'Show more',
              type: 'error',
            },
            time: 10000,
          });
        });
    }
  },

  setBroadcastMediaSchedule(
    { dispatch },
    { playerCode, channelCode, broadcastMediaCode, periods },
  ) {
    if (playerCode && channelCode && broadcastMediaCode && periods) {
      channelServices
        .setBroadcastMediaScheduleInChannel(
          channelCode,
          broadcastMediaCode,
          periods,
        )
        .then(() => {
          dispatch('getPlaylistItems', { playerCode: playerCode });
        })
        .catch((error) => {
          console.error(error);
          dispatch('addMessage', {
            message: {
              text: 'Error setting item duration.',
              buttonFunction: this.onAlertError,
              buttonParameters: error,
              buttonText: 'Show more',
              type: 'error',
            },
            time: 10000,
          });
        });
    }
  },

  reorderBroadcastMediaInChannel(
    { state, dispatch },
    { playerCode, channelCode, oldIndex, newIndex },
  ) {
    if (playerCode && channelCode) {
      let channelSet = state.channels.find((c) => c.playerCode === playerCode);
      let allChannels = Array.from(channelSet.extraChannels);
      channelSet.defaultChannel.forEach((defaultChannel) => {
        allChannels.push(defaultChannel);
      });

      let mediaRows = Array.from(
        allChannels.find((c) => c.channelCode === channelCode).mediaRows,
      );

      let movedElement = mediaRows.splice(oldIndex, 1);
      mediaRows.splice(newIndex, 0, movedElement[0]);

      channelServices
        .reorderBroadcastMediaInChannel(channelCode, mediaRows)
        .then(() => {
          dispatch('getPlaylistItems', { playerCode: playerCode });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  removeBroadcastMedia(
    { dispatch },
    { playerCode, channelCode, broadcastMediaCode },
  ) {
    if (channelCode && broadcastMediaCode) {
      channelServices
        .removeMediaFromChannel(channelCode, broadcastMediaCode)
        .then(() => {
          dispatch('getPlaylistItems', { playerCode: playerCode });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  removeMultipleBroadcastMedia(
    { dispatch },
    { channelCode, broadcastMediaCodes },
  ) {
    return new Promise((resolve, reject) => {
      if (channelCode && broadcastMediaCodes) {
        let payload = {
          broadcastMediaCodes: broadcastMediaCodes,
        };
        channelServices
          .removeMultipleMediaFromChannel(channelCode, payload)
          .then((responseData) => {
            if (responseData.success.ok) {
              resolve(true);
            }
            resolve(false);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        resolve(false);
      }
    });
  },

  setMultipleBroadcastMediaStatusInChannel(
    { dispatch },
    { channelCode, broadcastMediaCodes, status },
  ) {
    return new Promise((resolve, reject) => {
      if (channelCode && broadcastMediaCodes) {
        let payload = {
          broadcastMediaCodes: broadcastMediaCodes,
          status: {
            status: status,
          },
        };
        channelServices
          .setMultipleBroadcastMediaStatusInChannel(channelCode, payload)
          .then((responseData) => {
            if (responseData.success.ok) {
              resolve(true);
            }
            resolve(false);
          })
          .catch((error) => {
            console.error(error);
            reject(error);
          });
      } else {
        resolve(false);
      }
    });
  },

  addMediaToPlaylist({ commit }, { playerId, mediaCode }) {
    if (playerId && mediaCode) {
      playlistServices
        .addMediaToPlaylist(playerId, mediaCode)
        .then((playlistItems) => {
          commit(SET_PLAYLIST_ITEMS, { playerId, playlistItems });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  deleteMediaFromPlaylist({ commit }, { playerId, mediaCode }) {
    if (playerId && mediaCode) {
      playlistServices
        .deleteMediaFromPlaylist(playerId, mediaCode)
        .then((playlistItems) => {
          commit(SET_PLAYLIST_ITEMS, { playerId, playlistItems });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  getPlayerInformation({ commit }, { playerId }) {
    if (playerId) {
      playerServices
        .getPlayerInformation(playerId)
        .then((playerInformation) => {
          commit(SET_PLAYER_INFORMATION, { playerId, playerInformation });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  schedulePlaylistItem({ commit }, { playerId, mediaCode, changedItem }) {
    if (playerId && mediaCode && changedItem) {
      playlistServices
        .schedulePlaylistItem(playerId, mediaCode, changedItem)
        .then((playlistItems) => {
          commit(SET_PLAYLIST_ITEMS, { playerId, playlistItems });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  deletePlayer({ commit }, { playerId }) {
    if (playerId) {
      playerServices
        .deletePlayer(playerId)
        .then(() => {
          commit(REMOVE_PLAYER, { playerId });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  },

  getPlayerTags({ dispatch }, { playerCode }) {
    return new Promise((resolve, reject) => {
      playerServices
        .getPlayerTags(playerCode)
        .then((data) => {
          resolve(data.tags);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  editPlayerTags({ dispatch }, { playerCode, payload }) {
    playerServices
      .editPlayerTags(playerCode, payload)
      .then(() => {
        //TODO get all tags;
      })
      .catch((error) => {
        console.error(error);
      });
  },

  addMultiplePlayerTags({ commit, state }, { tagCodes, playerCodes }) {
    return new Promise((resolve, reject) => {
      playerServices
        .editMultiplePlayerTags({
          tags: tagCodes,
          added: true,
          playerCodes: playerCodes,
        })
        .then((responseData) => {
          if (responseData.success.ok) {
            resolve(true);
          }
          resolve(false);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  removeMultiplePlayerTags({ commit, state }, { tagCodes, playerCodes }) {
    return new Promise((resolve, reject) => {
      playerServices
        .editMultiplePlayerTags({
          tags: tagCodes,
          added: false,
          playerCodes: playerCodes,
        })
        .then((responseData) => {
          if (responseData.success.ok) {
            resolve(true);
          }
          resolve(false);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  rebootMultiplePlayers({ commit, state }, { playerCodes }) {
    return new Promise((resolve, reject) => {
      playerServices
        .rebootMultiplePlayers({
          PlayerCodes: playerCodes,
        })
        .then(() => {
          resolve(true);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  deleteMultiplePlayers({ commit, state }, { playerCodes }) {
    return new Promise((resolve, reject) => {
      playerServices
        .deleteMultiplePlayers({
          playerCodes: playerCodes,
        })
        .then((responseData) => {
          if (responseData.success.ok) {
            resolve(true);
          }
          resolve(false);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  editMultiplePlayerLocation({ commit, state }, { playerCodes, locationCode }) {
    return new Promise((resolve, reject) => {
      playerServices
        .editMultiplePlayerLocation({
          playerCodes: playerCodes,
          locationCode: locationCode,
        })
        .then((responseData) => {
          if (responseData.success.ok) {
            resolve(true);
          }
          resolve(false);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  editMultiplePlayerSettings({ commit, state }, { playerCodes, settings }) {
    return new Promise((resolve, reject) => {
      playerServices
        .editMultiplePlayerSettings({
          playerCodes: playerCodes,
          settings: settings,
        })
        .then((responseData) => {
          if (responseData.success.ok) {
            resolve(true);
          }
          resolve(false);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },

  getMultiplePlayerChannels({ commit }, { filterCode }) {
    return new Promise((resolve, reject) => {
      channelServices
        .getMultiplePlayerChannels({
          filterCode: filterCode,
        })
        .then((responseData) => {
          let playersChannels = responseData.playerChannels;
          commit(SET_PLAYERS_CHANNELS, { playersChannels });
          resolve(responseData);
        })
        .catch((error) => {
          console.error(error);
          reject(error);
        });
    });
  },
};

// mutations
const mutations = {
  [SET_PLAYERS](state, { players }) {
    state.players = players || [];
  },
  [ADD_PLAYERS](state, { players }) {
    // Do nothing is there are no items to add
    if (!players) {
      return;
    }

    // Add all items directly if there state is empty
    if (players && !state.players) {
      state.players = players;
    }

    players.forEach((newPlayer) => {
      if (!state.players.map((player) => player.id).includes(newPlayer.id)) {
        state.players.push(newPlayer);
      }
    });
  },
  [SET_TOTAL_PLAYER_COUNT](state, { totalPlayerCount }) {
    state.totalPlayerCount = totalPlayerCount;
  },
  [SET_PLAYLIST_ITEMS](state, { playerId, playlistItems }) {
    state.playlistItems[playerId] = playlistItems;
  },
  [SET_CHANNEL_ITEMS](state, { channelData }) {
    // structure of the channel state
    // [
    // 	{
    // 		playerCode: 'code1',
    // 		defaultChannel: {...},
    // 		extraChannels: [{...}, {...}]
    // 	},
    //  {...}
    // ]

    let idx = state.channels.findIndex(
      (channel) => channel.playerCode === channelData.playerCode,
    );

    if (idx === -1) {
      state.channels.push({
        playerCode: channelData.playerCode,
        layers: channelData.layers,
        commixes: channelData.commixes,
        playerFormatWidth: channelData.playerFormatWidth,
        playerFormatHeight: channelData.playerFormatHeight,
        playerFormatName: channelData.playerFormatName,
        defaultChannel: channelData.channels
          .filter(
            (channel) => channel.isDefaultChannel == true && channel.media,
          )
          .map((channel) => ({
            channelCode: channel.channelCode,
            channelName: '',
            groupName: '',
            mediaRows: channel.media ? channel.media : [],
            isEditable: channel.isEditable,
            layerCode: channel.layerCode,
          })),
        extraChannels: channelData.channels
          .filter(
            (channel) => channel.isDefaultChannel == false && channel.media,
          )
          .filter(
            (channel) =>
              channel.isManuallyGenerated || channel.broadcasts.length > 0,
          ) // To check for deleted broadcasts (note that channels are not deleted themselve)
          .map((channel) => ({
            channelCode: channel.channelCode,
            channelName: channel.name,
            groupName: channel.groupName,
            mediaRows: channel.media,
            isActive: channel.isActive,
            isMyChannel: channel.isMyChannel,
            isTakeOver: channel.isTakeOver,
            isManuallyGenerated: channel.isManuallyGenerated,
            isEditable: channel.isEditable,
            layerCode: channel.layerCode,
            broadcasts: channel.broadcasts,
            hasPermission: channel.hasPermission,
          })),
      });
    } else {
      state.channels.find((c) => c.playerCode === channelData.playerCode)[
        'defaultChannel'
      ] = channelData.channels
        .filter((channel) => channel.isDefaultChannel == true && channel.media)
        .map((channel) => ({
          channelCode: channel.channelCode,
          channelName: '',
          groupName: '',
          mediaRows: channel.media,
          isEditable: channel.isEditable,
          layerCode: channel.layerCode,
        }));

      state.channels.find((c) => c.playerCode === channelData.playerCode)[
        'extraChannels'
      ] = channelData.channels
        .filter((channel) => channel.isDefaultChannel == false && channel.media)
        .filter(
          (channel) =>
            channel.isManuallyGenerated || channel.broadcasts.length > 0,
        )
        .map((channel) => ({
          channelCode: channel.channelCode,
          channelName: channel.name,
          groupName: channel.groupName,
          mediaRows: channel.media ? channel.media : [],
          isActive: channel.isActive,
          isTakeOver: channel.isTakeOver,
          isManuallyGenerated: channel.isManuallyGenerated,
          isEditable: channel.isEditable,
          layerCode: channel.layerCode,
          broadcasts: channel.broadcasts,
          hasPermission: channel.hasPermission,
        }));

      state.channels.find((c) => c.playerCode === channelData.playerCode)[
        'layers'
      ] = channelData.layers;

      state.channels.find((c) => c.playerCode === channelData.playerCode)[
        'commixes'
      ] = channelData.commixes;
    }
  },
  [SET_PLAYER_INFORMATION](state, { playerId, playerInformation }) {
    state.playerInformation[playerId] = playerInformation;
  },
  [REMOVE_PLAYER](state, { playerId }) {
    if (state.players) {
      let index = state.players.findIndex((obj) => obj.id === playerId);
      state.players.splice(index, 1);
    }
  },
  [SET_PLAYERS_CHANNELS](state, { playersChannels }) {
    state.playersChannels = playersChannels;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
