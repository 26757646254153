<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
      <g id="broadcast-list" transform="translate(-1206.000000, -399.000000)">
        <g
          id="contact-circle-copy-4-play-circle"
          transform="translate(1206.000000, 399.000000)"
        >
          <g id="Group">
            <circle id="Combined-Shape" cx="10" cy="10" r="10" />
          </g>
          <path
            id="Play"
            d="M7.11375937,13.3871128 C7.17615222,14.1800757 8.16583185,14.7788027 8.85430464,14.3653006 C10.631425,13.3110204 12.5634518,12.0204534 14.4900998,10.8430236 C15.1699667,10.4285203 15.1699667,9.57147966 14.4900998,9.15697635 C12.5634518,7.97954664 10.631425,6.68897956 8.85430464,5.63469939 C8.16583185,5.22119729 7.17615222,5.8199243 7.11375937,6.61288716 C6.96208021,8.87062865 6.96208021,11.1293714 7.11375937,13.3871128 Z"
            fill="#181E24"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
