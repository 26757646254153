<template>
  <div class="sign-preview">
    <div v-if="error" class="preview-unavailable">
      <img src="@/assets/images/sad-browser.svg" />
      <p class="preview-unavailable-message">
        {{ $translate('generic.previewUnavailable') }}
      </p>
    </div>
    <div v-if="!previewLoaded && !error" class="sign-loader-container">
      <va-loader class="sign-loader" />
    </div>
    <sign-preview-orientation-picker
      v-if="previewLoaded && orientations"
      ref="orientation-picker"
      :value="previewPixels"
      :orientations="orientations"
      @change="changeOrientation"
    />
    <div v-if="!error" class="sign-iframe-container">
      <iframe
        v-if="previewUrl"
        ref="preview"
        :src="previewUrl"
        class="sign-iframe"
        :class="{ loaded: previewLoaded }"
        @load="onIframeLoaded"
      />
    </div>
  </div>
</template>

<script>
import { userComputed } from '@/services/auth.service';
import VaLoader from '@/components/framework/va-loader.vue';
import { getSign } from '@/api/signsApi';
import { withAsync } from '@/helpers/withAsync';
import { getPreviewSize } from './SignPreviewUtils';
import SignPreviewOrientationPicker from './SignPreviewOrientationPicker.vue';

export default {
  components: {
    VaLoader,
    SignPreviewOrientationPicker,
  },
  props: {
    mediaCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      previewLoaded: false,
      previewPixels: '',
      previewAspectRatio: '',
      error: false,
      sign: {},
    };
  },
  computed: {
    ...userComputed,
    previewUrl() {
      return this.sign.PreviewUrl
        ? `${this.sign.PreviewUrl}?access_token=${this.user?.access_token}`
        : '';
    },
    formModel() {
      return this.sign.FormModel ? JSON.parse(this.sign.FormModel) : {};
    },
    orientations() {
      return this.formModel.orientations.map((orientation) => ({
        name: orientation.orientation_name,
        dimensions: orientation.orientation_pixels,
        aspectRatio: orientation.orientation,
      }));
    },
  },
  created() {
    this.getSign(this.mediaCode);
  },
  methods: {
    async getSign(mediaCode) {
      const { response, error } = await withAsync(getSign, mediaCode);
      if (error) {
        this.error = true;
        return;
      }
      this.sign = response.data;
    },
    onIframeLoaded() {
      this.previewPixels = this.orientations[0].dimensions;
      this.previewAspectRatio = this.orientations[0].aspectRatio;
      this.previewLoaded = true;
      this.$nextTick(this.setPreviewSize);
    },
    previewPostMessage(message) {
      this.$refs.preview.contentWindow.postMessage(message, '*');
    },
    setPreviewSize() {
      const preview = getPreviewSize(
        document.getElementsByClassName('sign-iframe-container')[0],
        this.previewPixels,
      );
      this.$refs.preview.style.height = preview.height + 'px';
      this.$refs.preview.style.width = preview.width + 'px';
      this.previewPostMessage({
        orientation: this.previewAspectRatio,
        scale: preview.scale,
        orientation_pixels: this.previewPixels,
      });
    },
    changeOrientation(orientation) {
      this.previewPixels = orientation.dimensions;
      this.previewAspectRatio = orientation.aspectRatio;
      this.setPreviewSize();
    },
  },
};
</script>

<style lang="scss" scoped>
.sign-preview {
  position: relative;
  width: 100%;
  height: 100%;

  .preview-unavailable {
    position: absolute;
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .preview-unavailable-message {
      padding: 20px 0;
    }
  }

  .sign-iframe-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: calc(100% - 40px);
  }

  .sign-iframe {
    width: 0;
    height: 0;

    &.loaded {
      width: 100%;
      height: 100%;
    }
  }

  .sign-loader-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    .sign-loader {
      width: 100px;
    }
  }
}
</style>
