const UserView = () => import('@/views/users/UserView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const userRoutes = [
  {
    path: '/users',
    name: 'UserView',
    components: { default: UserView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'User', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ translate }) => {
        return [{ name: translate('sidebar.users') }];
      },
    },
  },
];
export default userRoutes;
