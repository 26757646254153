<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5.00016 23.333C5.92064 23.333 6.66683 24.0792 6.66683 24.9997V31.6663C6.66683 32.1084 6.84242 32.5323 7.15498 32.8449C7.46755 33.1574 7.89147 33.333 8.3335 33.333H31.6668C32.1089 33.333 32.5328 33.1574 32.8453 32.8449C33.1579 32.5323 33.3335 32.1084 33.3335 31.6663V24.9997C33.3335 24.0792 34.0797 23.333 35.0002 23.333C35.9206 23.333 36.6668 24.0792 36.6668 24.9997V31.6663C36.6668 32.9924 36.14 34.2642 35.2024 35.2019C34.2647 36.1396 32.9929 36.6663 31.6668 36.6663H8.3335C7.00741 36.6663 5.73564 36.1396 4.79796 35.2019C3.86028 34.2642 3.3335 32.9924 3.3335 31.6663V24.9997C3.3335 24.0792 4.07969 23.333 5.00016 23.333Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.4882 15.4882C11.139 14.8373 12.1943 14.8373 12.8452 15.4882L20 22.643L27.1548 15.4882C27.8057 14.8373 28.861 14.8373 29.5118 15.4882C30.1627 16.139 30.1627 17.1943 29.5118 17.8452L21.1785 26.1785C20.5276 26.8294 19.4724 26.8294 18.8215 26.1785L10.4882 17.8452C9.83728 17.1943 9.83728 16.139 10.4882 15.4882Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0002 3.33301C20.9206 3.33301 21.6668 4.0792 21.6668 4.99967V24.9997C21.6668 25.9202 20.9206 26.6663 20.0002 26.6663C19.0797 26.6663 18.3335 25.9202 18.3335 24.9997V4.99967C18.3335 4.0792 19.0797 3.33301 20.0002 3.33301Z"
      fill="currentColor"
    />
  </svg>
</template>
