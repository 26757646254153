<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="icons-sp" transform="translate(-70.000000, -525.000000)">
        <g id="Group-Copy-26" transform="translate(50.000000, 505.000000)">
          <g id="add" transform="translate(20.000000, 20.000000)">
            <g id="contact-circle-copy-4">
              <g id="Group-8">
                <circle
                  id="circle"
                  fill="currentColor"
                  cx="10"
                  cy="10"
                  r="10"
                />
                <g
                  id="Group-Copy"
                  transform="translate(10.171573, 10.485281) rotate(-270.000000) translate(-10.171573, -10.485281) translate(4.171573, 4.485281)"
                  fill="#181E24"
                  fill-rule="nonzero"
                >
                  <path
                    id="cross"
                    d="M5.51471863,0.671572875 C6.27411016,0.671572875 6.88971863,1.28718134 6.88971863,2.04657288 L6.88971863,2.04657288 L6.88871863,4.79657288 L9.63971863,4.79657288 C10.3544401,4.79657288 10.9417966,5.34188695 11.0084243,6.0391512 L11.0147186,6.17157288 C11.0147186,6.93096441 10.3991102,7.54657288 9.63971863,7.54657288 L9.63971863,7.54657288 L6.88871863,7.54657288 L6.88971863,10.2965729 C6.88971863,11.0112943 6.34440455,11.5986508 5.6471403,11.6652785 L5.51471863,11.6715729 C4.75532709,11.6715729 4.13971863,11.0559644 4.13971863,10.2965729 L4.13971863,10.2965729 L4.13871863,7.54657288 L1.38971863,7.54657288 C0.674997185,7.54657288 0.0876406708,7.0012588 0.021012989,6.30399455 L0.0147186258,6.17157288 C0.0147186258,5.41218134 0.630327095,4.79657288 1.38971863,4.79657288 L1.38971863,4.79657288 L4.13871863,4.79657288 L4.13971863,2.04657288 C4.13971863,1.33185143 4.6850327,0.74449492 5.38229695,0.677867238 Z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
