import axios from 'axios';
import Env from '@/utils/env';

export default {
  getLayers(searchText, orderBy, order, maxItemsInResult, skipItemsInResult) {
    return new Promise((resolve, reject) => {
      axios
        .get(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/layers', {
          params: {
            SearchText: searchText,
            OrderBy: orderBy,
            Order: order,
            MaxItemsInResult: maxItemsInResult,
            SkipItemsInResult: skipItemsInResult,
          },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while fetching your layers.');
        });
    });
  },
};
