<template>
  <svg
    width="40"
    height="40"
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M19.9998 5.00033C11.7156 5.00033 4.99984 11.7161 4.99984 20.0003C4.99984 28.2846 11.7156 35.0003 19.9998 35.0003C28.2841 35.0003 34.9998 28.2846 34.9998 20.0003C34.9998 11.7161 28.2841 5.00033 19.9998 5.00033ZM1.6665 20.0003C1.6665 9.8751 9.87462 1.66699 19.9998 1.66699C30.1251 1.66699 38.3332 9.8751 38.3332 20.0003C38.3332 30.1255 30.1251 38.3337 19.9998 38.3337C9.87462 38.3337 1.6665 30.1255 1.6665 20.0003Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M20.0002 11.667C20.9206 11.667 21.6668 12.4132 21.6668 13.3337V26.667C21.6668 27.5875 20.9206 28.3337 20.0002 28.3337C19.0797 28.3337 18.3335 27.5875 18.3335 26.667V13.3337C18.3335 12.4132 19.0797 11.667 20.0002 11.667Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M11.6665 19.9997C11.6665 19.0792 12.4127 18.333 13.3332 18.333H26.6665C27.587 18.333 28.3332 19.0792 28.3332 19.9997C28.3332 20.9201 27.587 21.6663 26.6665 21.6663H13.3332C12.4127 21.6663 11.6665 20.9201 11.6665 19.9997Z"
      fill="currentColor"
    />
  </svg>
</template>
