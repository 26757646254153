const VariablesView = () => import('@/views/variables/VariablesView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const variableRoutes = [
  {
    path: '/variables/locations/:locationCode/:locationName/:tab?',
    name: 'LocationVariablesView',
    components: { default: VariablesView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Variable', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ route, translate }) => {
        return [
          {
            name: translate('settings.locations.tab'),
            route: { name: 'LocationSettingsView' },
          },
          { name: route.params.locationName },
          { name: translate('variables.variables') },
        ];
      },
      type: 'location',
    },
  },
  {
    path: '/variables/players/:playerCode/:playerName/:tab?',
    name: 'PlayerVariablesView',
    components: { default: VariablesView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Variable', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ route, translate }) => {
        return [
          { name: translate('sidebar.screens'), route: '/screens' },
          { name: route.params.playerName },
          { name: translate('variables.variables') },
        ];
      },
      type: 'player',
    },
  },
];
export default variableRoutes;
