<template>
  <label class="va-radio-container" :class="{ disabled: disabled }">
    {{ label }}
    <input
      type="radio"
      :checked="isChecked"
      :value="value"
      @change="updateInput"
    />
    <span class="checkmark" />
  </label>
</template>
<script>
export default {
  props: {
    value: {
      type: [String, Boolean],
      default: '',
    },
    modelValue: {
      type: [Boolean, String],
      default: false,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
  },
  methods: {
    updateInput() {
      this.$emit('update:modelValue', this.value);
    },
  },
};
</script>
<style lang="scss">
/* Customize the label (the container) */
.va-radio-container {
  display: inline-block;
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  height: 20px;
  line-height: 20px;
  margin-right: 20px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #4b4b51;
    border-radius: 50%;
    transition: background-color 0.2s ease-in-out;
  }

  /* On mouse-over, add a grey background color */
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the radio button is checked, add a blue background */
  input:checked ~ .checkmark {
    background-color: $color-ui-primary !important;
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark::after {
    content: '';
    position: absolute;
    display: none;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #fafafa;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark::after {
    display: block;
  }
}
</style>
