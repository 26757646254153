/**
 * Defaults the popup downwards and aligns left side with the toggler
 * @param { HTMLScriptElement } toggler the "button" that opens the popup
 * @param { HTMLScriptElement } popup the popup container
 * @param { Boolean } right positions the popup to align with the right side of the toggler
 * @param { Boolean } center positions the popup to center the toggler
 * @param { Boolean } up change the default position of the toggler upwards
 * @returns Popup css position to be added as css variable: top right bottom left
 */
export const popupPosition = (toggler, popup, right, center, up) => {
  const left = leftPosition(toggler, popup, right, center);
  const direction = popupDirection(toggler, popup, up);

  return `${direction.top} auto ${direction.bottom} ${left}`;
};

/**
 * @param { HTMLScriptElement } toggler the "button" that opens the popup
 * @param { HTMLScriptElement } popup the popup container
 * @param { Boolean } up change the default position of the toggler upwards
 * @returns Popup css position bottom and top
 *          bottom auto -> the dropdown go downwards
 *          top auto    -> the dropdown go upwards
 */
const popupDirection = (toggler, popup, up) => {
  const popupHeight = popup.height;
  const spaceOverToggler = toggler.top;
  const spaceUnderToggler = window.innerHeight - toggler.bottom;

  if (
    (up &&
      (popupHeight <= spaceOverToggler ||
        spaceOverToggler >= spaceUnderToggler)) ||
    (popupHeight > spaceUnderToggler && spaceOverToggler > spaceUnderToggler)
  ) {
    return { bottom: `${window.innerHeight - toggler.top}px`, top: 'auto' };
  }

  return { bottom: 'auto', top: `${toggler.top + toggler.height}px` };
};

/**
 * Calculates and set the height of the dropdown container
 * @param { HTMLScriptElement } toggler the "button" that opens the popup
 * @param { HTMLScriptElement } popup the popup container
 * @param { Boolean } up change the default position of the toggler upwards
 * @returns the height of the dropdown, default auto
 */
export const calculatePopupHeight = (toggler, popup, up) => {
  const directionDown = popupDirection(toggler, popup, up).bottom === 'auto';
  const spaceUnderToggler = Math.round(window.innerHeight - toggler.bottom);
  const spaceOverToggler = Math.round(toggler.top);

  if (directionDown && spaceUnderToggler <= popup.height) {
    return `${spaceUnderToggler}px`;
  } else if (!directionDown && spaceOverToggler <= popup.height) {
    return `${spaceOverToggler}px`;
  }

  return 'auto';
};

/**
 *
 * @param { HTMLScriptElement } toggler the "button" that opens the popup
 * @param { HTMLScriptElement } popup the popup container
 * @param { Boolean } right positions the popup to align with the right side of the toggler
 * @param { Boolean } center positions the popup to center the toggler
 * @returns left in position of the toggler, sets to 0 if position is outside of the window
 */
const leftPosition = (toggler, popup, right, center) => {
  // -4 to compensate for the padding that is for the box-shadow
  let left = toggler.left - 4;

  if (right) {
    // +4 to compensate for the padding that is for the box-shadow
    left = toggler.right + 4 - popup.width;
  } else if (center) {
    left = toggler.left + toggler.width / 2 - popup.width / 2;
  }

  if (left < 0) {
    left = -4;
  }

  return `${left}px`;
};
