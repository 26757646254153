import { createRouter, createWebHistory } from 'vue-router';
import store from '../store/index';
import routes from './routes';
import { app } from '../main';
import { authenticate, userComputed } from '@/services/auth.service';
import { withAsync } from '@/helpers/withAsync';
import { getUserSettings } from '@/api/userApi';
import * as Sentry from '@sentry/browser';

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition !== null) {
      store.dispatch('updateBrowserState', {
        data: true,
        key: 'back',
      });
    } else {
      store.dispatch('updateBrowserState', {
        data: false,
        key: 'back',
      });
    }
  },
  routes: routes,
});

router.beforeEach((to, from, next) => {
  if (userComputed.isAuthenticated.get()) {
    checkPermission(to, next);
  } else if (
    to.name != 'CallbackView' &&
    to.name != 'ConfirmNewPassword' &&
    to.name != 'ConfirmUser' &&
    to.name != 'PasswordForgot' &&
    to.name != 'LoginWithAccountCode'
  ) {
    //authentication is required. Trigger the sign in process, including the return URI
    authenticate(to.path).then(() => {
      store
        .dispatch('getUser')
        .then((user) => {
          Sentry.setUser({
            id: user.UserDetails.id,
            username: `${user.UserDetails.firstName} ${user.UserDetails.lastName}`,
            email: user.UserDetails.email,
            clientCode: user.UserDetails.clientCode,
            clientName: user.UserDetails.clientName,
          });
          storeUserSettings(user.UserDetails).then(() => {
            checkPermission(to, next);
          });
        })
        .catch(() => {
          next();
        });
    });
  } else {
    //No auth required. We can navigate
    next();
  }
});

const storeUserSettings = async (user) => {
  const { response } = await withAsync(getUserSettings, user.id);
  if (response?.data.settings) {
    const settings = response.data.settings.map((setting) => ({
      id: setting.id,
      data: parseJSON(setting.data),
    }));
    store.dispatch('updateUserSettings', settings);
  }
};

const parseJSON = (data) => {
  try {
    return JSON.parse(data);
  } catch {
    return data;
  }
};

const checkPermission = (to, next) => {
  // If there are no permissions to check then proceed
  if (!to.meta.permission) return next();

  const { resources = [], config = {} } = to.meta.permission;
  // If there are no resources then proceed
  if (!resources.length) return next();
  // Check if user should have access to the next page
  const hasAccess = resources.every((resource) =>
    app.config.globalProperties.$hasPermission(resource.name, resource.action, {
      isClient: resource.isClient,
    }),
  );
  // Access granted
  if (hasAccess) {
    return next();
  }
  // No access!
  next({ name: config.noAccessRedirect || 'AccessDeniedView' });
};

router.afterEach((to) => {
  window.gtag('set', 'page_path', to.path);
  window.gtag('event', 'page_view');
});

router.onError((error) => {
  if (
    error.message.includes('Failed to fetch dynamically imported module') ||
    error.message.includes('Importing a module script failed')
  ) {
    window.location.reload();
  }
});

export default router;
