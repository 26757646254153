<template>
  <svg
    width="12px"
    height="12px"
    viewBox="0 0 12 12"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icons-sp"
        transform="translate(-1062.000000, -370.000000)"
        fill="currentColor"
      >
        <g id="Group-4-Copy" transform="translate(1038.000000, 346.000000)">
          <g id="close" transform="translate(14.000000, 14.000000)">
            <path
              id="Combined-Shape"
              d="M16,8 C16.5522847,8 17,8.44771525 17,9 L17,15 L23,15 C23.5522847,15 24,15.4477153 24,16 C24,16.5522847 23.5522847,17 23,17 L17,17 L17,23 C17,23.5522847 16.5522847,24 16,24 C15.4477153,24 15,23.5522847 15,23 L15,17 L9,17 C8.44771525,17 8,16.5522847 8,16 C8,15.4477153 8.44771525,15 9,15 L15,14.999 L15,9 C15,8.44771525 15.4477153,8 16,8 Z"
              transform="translate(16.000000, 16.000000) rotate(-315.000000) translate(-16.000000, -16.000000) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
