<template>
  <div class="content-loader" :class="animation" />
</template>

<script>
export default {
  props: {
    animation: {
      type: String,
      default: 'shine',
    },
  },
};
</script>

<style lang="scss">
.content-loader {
  width: 100%;
  height: 100%;
  background: rgb(186, 186, 186);
  background: linear-gradient(
    90deg,
    rgba(186, 186, 186, 0) 0%,
    rgba(186, 186, 186, 0.5046) 50%,
    rgba(186, 186, 186, 0) 100%
  );
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: -100% 0%;
  animation-iteration-count: infinite;

  &.shimmer {
    animation-name: load-shimmer;
    animation-duration: 2.5s;
  }

  &.fade {
    animation-name: load-fade;
    animation-duration: 2s;
  }

  &.shine {
    animation-name: load-shine;
    animation-duration: 1.5s;
  }
}

@keyframes load-shine {
  0% {
    background-position: -100% 0%;
  }

  100% {
    background-position: 200% 0%;
  }
}

@keyframes load-fade {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes load-shimmer {
  0% {
    background-position: -468px 0;
    opacity: 1;
  }

  50% {
    background-position: 468px 0;
  }

  50.1% {
    background-position: -468px 0;
    opacity: 0;
  }

  100% {
    background-position: 468px 0;
    opacity: 1;
  }
}
</style>
