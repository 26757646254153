import type { RouteRecordRaw } from 'vue-router';
const TagsView = () => import('@/views/tags/TagsView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');
import { translate } from '@/helpers/translationHelper';
import { Permission } from '@/helpers/constants';
import { ValidPermission } from '@/enums/permission';

const tagsRoutes: RouteRecordRaw[] = [
  {
    path: '/tags',
    name: 'TagsView',
    components: { default: TagsView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [
          { name: Permission.Components.Tags, action: ValidPermission.VIEW },
        ],
      },
      breadcrumbs: () => {
        return [{ name: translate('sidebar.tags') }];
      },
    },
  },
];

export default tagsRoutes;
