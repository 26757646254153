import { MultiAccountAccessType } from '@/views/overview/clients/accountSettings/enums/multiAccountAccessType';

const PermissionsPlugin = {
  actionPoints: {
    None: 0,
    Hide: 0,
    Disabled: 0,
    ViewOnly: 10,
    ViewOnSameLocationOnly: 10,
    AddAndEdit: 50,
    AddAndEditOnSameLocationOnly: 50,
    Enabled: 1000, // Full access
  },
  install(app, { store, getterNameUser, getterNameClient, isDebugModeActive }) {
    if (!store) {
      console.error('Please provide a VueX store');
    }

    function _convertToPermissionsToPoints(actionPoints, inPermissionValue) {
      if (!inPermissionValue) {
        return -1;
      }

      const returnPoints = actionPoints[inPermissionValue];
      return returnPoints;
    }

    function _hasEnoughActionPoints(currentRoleActionPoints, valueToBeat) {
      return currentRoleActionPoints >= valueToBeat;
    }

    function _hasResourceName(userAuthorization, inResourceName) {
      if (!userAuthorization || userAuthorization.length === 0) {
        return false;
      }

      return userAuthorization[inResourceName] ? true : false;
    }

    app.config.globalProperties.$hasPermission = (
      inResourceName,
      inAction,
      { isClient = false } = {},
    ) => {
      const isAdmin = store.getters['isAdmin'] || false;
      const userAuthorization = store.getters[getterNameUser] || {};
      const clientAuthorization = store.getters[getterNameClient] || {};
      const currentUser = store.getters.currentUser || {};

      if (isAdmin && inResourceName === 'Admin') {
        return true;
      }

      // Special permission for overview.
      if (inResourceName === 'Overview') {
        const { permissionToClients, accountAccessType } = currentUser;
        if (
          permissionToClients?.length > 1 &&
          (isAdmin ||
            accountAccessType ===
              MultiAccountAccessType.ENVIRONMENT_ADMINISTRATOR)
        ) {
          return true;
        }
      }

      if (!_hasResourceName(userAuthorization, inResourceName)) {
        return false;
      }
      if (isClient && !_hasResourceName(clientAuthorization, inResourceName)) {
        return false;
      }
      if (!inAction) {
        return false;
      }

      // This is the main guts of the permission system
      // The _hasEnoughActionValue function converts the desired Action to points.
      // If the user's role has more rights (points), then the user is allow to perform action.

      // This points calculation system is made for when the User's rights are easily comparable to the desired actions.
      // For example: if the desired action is "ViewOnly" and the user's role allows for "AddAndEdit".
      // Then the user should be able "ViewOnly" as well.

      // In other words, "currentPoints" must beat or match "targetPoints".

      const currentUserPoints = _convertToPermissionsToPoints(
        this.actionPoints,
        userAuthorization[inResourceName],
      );

      let currentPoints = currentUserPoints;

      if (isClient) {
        //when isClient we are using the lowest points to set the right permission
        const currentClientPoints = _convertToPermissionsToPoints(
          this.actionPoints,
          clientAuthorization[inResourceName],
        );
        currentPoints = Math.min(currentUserPoints, currentClientPoints);
      }

      const targetPoints = _convertToPermissionsToPoints(
        this.actionPoints,
        inAction,
      );
      const hasPermission = _hasEnoughActionPoints(currentPoints, targetPoints);

      if (isDebugModeActive) {
        console.group('HasPermission');
        console.log('inResourceName', inResourceName, currentPoints);
        console.log('inAction', inAction, targetPoints);
        console.groupEnd();
      }

      return hasPermission;
    };
  },
};

export default PermissionsPlugin;
