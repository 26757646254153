<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0013 1.66667C8.23319 1.66667 6.5375 2.36905 5.28726 3.61929C4.03701 4.86953 3.33464 6.56522 3.33464 8.33333C3.33464 10.915 5.01442 13.4202 6.85626 15.3644C7.76014 16.3185 8.66686 17.0996 9.34853 17.6424C9.60223 17.8444 9.82382 18.0127 10.0013 18.1438C10.1788 18.0127 10.4004 17.8444 10.6541 17.6424C11.3357 17.0996 12.2425 16.3185 13.1463 15.3644C14.9882 13.4202 16.668 10.915 16.668 8.33333C16.668 6.56522 15.9656 4.86953 14.7153 3.61929C13.4651 2.36905 11.7694 1.66667 10.0013 1.66667ZM10.0013 19.1667C9.53905 19.86 9.53884 19.8599 9.5386 19.8597L9.53622 19.8582L9.53066 19.8544L9.51157 19.8415C9.49534 19.8305 9.4722 19.8147 9.4426 19.7942C9.38341 19.7532 9.29835 19.6934 9.19113 19.616C8.97676 19.4612 8.67327 19.2352 8.31032 18.9462C7.58574 18.3692 6.61746 17.5357 5.64634 16.5106C3.73819 14.4965 1.66797 11.585 1.66797 8.33333C1.66797 6.1232 2.54594 4.00358 4.10874 2.44078C5.67155 0.877974 7.79116 0 10.0013 0C12.2114 0 14.3311 0.877974 15.8939 2.44078C17.4567 4.00358 18.3346 6.1232 18.3346 8.33333C18.3346 11.585 16.2644 14.4965 14.3563 16.5106C13.3851 17.5357 12.4169 18.3692 11.6923 18.9462C11.3293 19.2352 11.0258 19.4612 10.8115 19.616C10.7042 19.6934 10.6192 19.7532 10.56 19.7942C10.5304 19.8147 10.5073 19.8305 10.491 19.8415L10.4719 19.8544L10.4664 19.8582L10.4646 19.8593C10.4644 19.8595 10.4636 19.86 10.0013 19.1667ZM10.0013 19.1667L10.4636 19.86C10.1836 20.0467 9.81852 20.0464 9.5386 19.8597L10.0013 19.1667Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.0013 6.66667C9.08083 6.66667 8.33464 7.41286 8.33464 8.33333C8.33464 9.25381 9.08083 10 10.0013 10C10.9218 10 11.668 9.25381 11.668 8.33333C11.668 7.41286 10.9218 6.66667 10.0013 6.66667ZM6.66797 8.33333C6.66797 6.49238 8.16035 5 10.0013 5C11.8423 5 13.3346 6.49238 13.3346 8.33333C13.3346 10.1743 11.8423 11.6667 10.0013 11.6667C8.16035 11.6667 6.66797 10.1743 6.66797 8.33333Z"
      fill="currentColor"
    />
  </svg>
</template>
