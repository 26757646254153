<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_117_1469)">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M7.42948 2.02564C7.61355 2.44746 7.42082 2.93864 6.999 3.12271C5.87616 3.6127 4.89045 4.37029 4.12803 5.32923C3.36562 6.28818 2.84973 7.4193 2.62545 8.62369C2.40117 9.82808 2.47534 11.0691 2.84148 12.2382C3.20761 13.4073 3.85456 14.4689 4.72576 15.3302C5.59697 16.1915 6.6659 16.8263 7.83911 17.1791C9.01232 17.5318 10.2541 17.5918 11.4558 17.3538C12.6576 17.1158 13.7827 16.587 14.7329 15.8137C15.6831 15.0404 16.4294 14.0461 16.9065 12.9177C17.0857 12.4938 17.5747 12.2955 17.9986 12.4747C18.4225 12.654 18.6208 13.1429 18.4416 13.5668C17.8584 14.9459 16.9463 16.1612 15.785 17.1064C14.6236 18.0515 13.2484 18.6978 11.7796 18.9887C10.3108 19.2796 8.79312 19.2063 7.3592 18.7751C5.92528 18.344 4.61881 17.5681 3.554 16.5154C2.4892 15.4627 1.69848 14.1652 1.25098 12.7363C0.803485 11.3074 0.712832 9.7906 0.986949 8.31857C1.26107 6.84654 1.89161 5.46407 2.82344 4.29202C3.75528 3.11997 4.96004 2.19403 6.3324 1.59516C6.75422 1.41108 7.2454 1.60381 7.42948 2.02564Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M9.41205 1.07806C9.56833 0.921782 9.78029 0.833984 10.0013 0.833984C11.2051 0.833984 12.3971 1.07109 13.5092 1.53176C14.6214 1.99242 15.6319 2.66764 16.4831 3.51884C17.3343 4.37004 18.0095 5.38057 18.4702 6.49272C18.9309 7.60487 19.168 8.79687 19.168 10.0007C19.168 10.4609 18.7949 10.834 18.3346 10.834H10.0013C9.54106 10.834 9.16797 10.4609 9.16797 10.0007V1.66732C9.16797 1.4463 9.25577 1.23434 9.41205 1.07806ZM10.8346 2.54709V9.16732H17.4549C17.3767 8.46849 17.2006 7.78273 16.9304 7.13052C16.5535 6.22058 16.001 5.39379 15.3046 4.69735C14.6082 4.00091 13.7814 3.44846 12.8714 3.07155C12.2192 2.8014 11.5335 2.62522 10.8346 2.54709Z"
        fill="currentColor"
      />
    </g>
    <defs>
      <clipPath id="clip0_117_1469">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
