/*
    Helps with generating translated error messages for the axios interceptor.
    ------------------------------
    TODO: Enable generic error messages (commented out in this file)
*/

import { errorCodes } from '../constants/errorCodes';
import TranslationHelper from './translationHelper';

const ErrorMessageHelper = {
  renderErrorMessage: (response) => {
    if (response.data) {
      if (response.data.success?.message) {
        const label = getLabelFromErrorCode(response.data.success?.message);
        return label
          ? TranslationHelper.translate(label)
          : response.data.success?.message;
        //: TranslationHelper.translate('generic.errorMessage');
      } else if (typeof response.data !== 'object') {
        const label = getLabelFromErrorCode(response.data);
        return label ? TranslationHelper.translate(label) : response.data;
        //: TranslationHelper.translate('generic.errorMessage');
      }
    } else if (response.success) {
      if (response.success.message) {
        const label = getLabelFromErrorCode(response.success.message);
        return label
          ? TranslationHelper.translate(label)
          : response.success.message;
        //: TranslationHelper.translate('generic.errorMessage');
      }
    } else if (response.message) {
      const label = getLabelFromErrorCode(response.message);
      return label ? TranslationHelper.translate(label) : response.message;
      //: TranslationHelper.translate('generic.errorMessage');
    }

    const label = getLabelFromErrorCode(response);
    return label ? TranslationHelper.translate(label) : response;
    //: TranslationHelper.translate('generic.errorMessage');
  },
};

const getLabelFromErrorCode = (code) => {
  if (Object.keys(errorCodes).find((c) => c === code)) {
    return errorCodes[code];
  }

  return null;
};

export default ErrorMessageHelper;
