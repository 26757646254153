<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->

    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="icons-sp" transform="translate(-310.000000, -351.000000)">
        <g id="Group-Copy-30" transform="translate(290.000000, 331.000000)">
          <g id="warning-circle" transform="translate(20.000000, 20.000000)">
            <g id="Group-8">
              <circle id="circle" fill="currentColor" cx="10" cy="10" r="10" />
              <g
                id="Group-Copy"
                transform="translate(9.994796, 8.894291) rotate(-315.000000) translate(-9.994796, -8.894291) translate(5.744796, 4.394291)"
              />
            </g>
            <path
              id="Rectangle"
              style="fill: #181e24"
              d="M10.0249996,4.75 C10.9778284,4.75 11.7502488,5.52242036 11.7502488,6.47524915 C11.7502488,6.52306915 11.7482606,6.57086848 11.7442893,6.6185233 L11.5141872,9.37974958 C11.4496447,10.1542591 10.8021937,10.75 10.0249996,10.75 C9.24780549,10.75 8.60035454,10.1542591 8.53581208,9.37974958 L8.30570989,6.6185233 C8.22658177,5.6689858 8.93218798,4.83508755 9.88172547,4.75595943 C9.92938029,4.75198819 9.97717962,4.75 10.0249996,4.75 Z"
            />
            <circle
              id="Oval"
              cx="10"
              cy="13.5"
              r="1.75"
              style="fill: #181e24"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
