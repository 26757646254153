import axios from 'axios';
import Env from '@/utils/env';

export default {
  getPlaylistItems(playerId) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerId +
            '/mediarows',
        )
        .then((response) => {
          resolve(response.data.media);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  addMediaToPlaylist(playerId, mediaCode) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerId +
            '/media/' +
            mediaCode,
        )
        .then((response) => {
          resolve(response.data.media);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  deleteMediaFromPlaylist(playerId, mediaCode) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerId +
            '/mediarows/' +
            mediaCode,
        )
        .then((response) => {
          resolve(response.data.media);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  schedulePlaylistItem(playerId, mediaCode, changedItem) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerId +
            '/mediarows/' +
            mediaCode +
            '/schedule',
          changedItem,
        )
        .then((response) => {
          resolve(response.data.media);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },

  setDurationPlaylistItem(playerId, mediaCode, duration) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/players/' +
            playerId +
            '/mediarows/' +
            mediaCode +
            '/duration',
          duration,
        )
        .then((response) => {
          resolve(response.data.media);
        })
        .catch((error) => {
          reject(error.response);
        });
    });
  },
};
