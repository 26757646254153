import axios from 'axios';
import Env from '@/utils/env';

export default {
  getPlayerFilters(
    searchText,
    orderBy,
    order,
    maxItemsInResult,
    skipItemsInResult,
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/playerfilter', {
          params: {
            SearchText: searchText,
            OrderBy: orderBy,
            Order: order,
            MaxItemsInResult: maxItemsInResult,
            SkipItemsInResult: skipItemsInResult,
          },
        })
        .then((response) => {
          resolve(response.data.PlayerFilters);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while fetching your player filters.');
        });
    });
  },

  savePlayerFilter(playerFilterName, condition) {
    return new Promise((resolve, reject) => {
      axios
        .post(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/playerfilter',
          {
            PlayerFilter: { PlayerFilterName: playerFilterName },
            Filter: { Condition: condition },
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while saving your player filter.');
        });
    });
  },

  getPlayerFilter(playerFilterCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/playerfilter/' +
            playerFilterCode,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while getting your player filter.');
        });
    });
  },

  editPlayerFilter(playerFilterCode, playerFilterName, condition) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/playerfilter/' +
            playerFilterCode,
          {
            PlayerFilter: { PlayerFilterName: playerFilterName },
            Filter: { Condition: condition },
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while deleting your player filter.');
        });
    });
  },

  deletePlayerFilter(playerFilterCode) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/playerfilter/' +
            playerFilterCode,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while deleting your player filter.');
        });
    });
  },
};
