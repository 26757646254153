<template>
  <div class="va-field va-switch">
    <va-toggle :label="computedLabel" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      editMode: false,
    };
  },
  computed: {
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
  },
  methods: {
    select: function () {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = this.$refs.checkbox.checked;
      this.$emit('updatedData');
    },
  },
};
</script>

<style lang="scss" scoped>
.va-switch {
  color: $color-text-secondary;
}
</style>
