<template>
  <svg
    width="52px"
    height="63px"
    viewBox="0 0 52 63"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g
      id="Asset-Artboard-Page"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="timer-icon">
        <g id="Group-15">
          <path
            id="Oval"
            d="M26,10.8 C40.3594035,10.8 52,22.485368 52,36.9 C52,51.314632 40.3594035,63 26,63 C11.6405965,63 0,51.314632 0,36.9 C0,22.485368 11.6405965,10.8 26,10.8 Z M26,13.8 C13.2911027,13.8 2.98850575,24.1422223 2.98850575,36.9 C2.98850575,49.6577777 13.2911027,60 26,60 C38.7088973,60 49.0114943,49.6577777 49.0114943,36.9 C49.0114943,24.1422223 38.7088973,13.8 26,13.8 Z"
            fill="currentColor"
            fill-rule="nonzero"
          />
          <path
            id="Oval"
            d="M26.1754543,17.2761292 C37.0687949,17.2761292 45.8995923,26.1408911 45.8995923,37.0761292 C45.8995923,48.0113672 37.0687949,56.8761292 26.1754543,56.8761292 C15.2821137,56.8761292 6.4513164,48.0113672 6.4513164,37.0761292 C6.4513164,26.1408911 15.2821137,17.2761292 26.1754543,17.2761292 Z M26.1754543,19.6761292 C16.6025187,19.6761292 8.84212099,27.4663745 8.84212099,37.0761292 C8.84212099,46.6858838 16.6025187,54.4761292 26.1754543,54.4761292 C35.74839,54.4761292 43.5087877,46.6858838 43.5087877,37.0761292 C43.5087877,27.4663745 35.74839,19.6761292 26.1754543,19.6761292 Z"
            fill="currentColor"
            fill-rule="nonzero"
          />
          <path
            id="Oval"
            d="M26.8965517,36.3 C31.9581039,36.3 36.5404794,36.4 40.6436782,36.6 C40.6436782,28.9784705 34.48888,22.8 26.8965517,22.8 L26.8965517,36.3 Z"
            fill="currentColor"
          />
          <rect
            id="Rectangle"
            fill="currentColor"
            x="25.1034483"
            y="51"
            width="2.3908046"
            height="4.2"
            rx="1.1954023"
          />
          <g
            id="Group-14"
            transform="translate(25.103448, 51.000000)"
            fill="currentColor"
          >
            <rect
              id="Rectangle"
              x="0"
              y="0"
              width="2.3908046"
              height="4.8"
              rx="1.1954023"
            />
          </g>
          <g
            id="Group-14"
            transform="translate(14.887610, 48.655128) rotate(45.000000) translate(-14.887610, -48.655128) translate(13.692208, 46.255128)"
            fill="currentColor"
          >
            <rect
              id="Rectangle"
              x="0"
              y="4.09272616e-13"
              width="2.3908046"
              height="4.8"
              rx="1.1954023"
            />
          </g>
          <g
            id="Group-14"
            transform="translate(10.160920, 37.200000) rotate(90.000000) translate(-10.160920, -37.200000) translate(8.965517, 34.800000)"
            fill="currentColor"
          >
            <rect
              id="Rectangle"
              x="4.07704521e-13"
              y="0"
              width="2.3908046"
              height="4.8"
              rx="1.1954023"
            />
          </g>
          <g
            id="Group-14"
            transform="translate(26.298851, 37.200000) rotate(135.000000) translate(-26.298851, -37.200000) translate(25.103448, 18.600000)"
            fill="currentColor"
          >
            <rect
              id="Rectangle"
              x="-1.35901507e-13"
              y="32.4"
              width="2.3908046"
              height="4.8"
              rx="1.1954023"
            />
            <rect
              id="Rectangle-Copy-3"
              x="1.35901507e-13"
              y="-1.36424205e-13"
              width="2.3908046"
              height="4.8"
              rx="1.1954023"
            />
          </g>
          <path
            id="Combined-Shape"
            d="M32.2597701,0 C34.5793661,2.2384324e-15 36.4597701,1.88040405 36.4597701,4.2 C36.4597701,6.51959595 34.5793661,8.4 32.2597701,8.4 L20.9356322,8.4 C18.6160362,8.4 16.7356322,6.51959595 16.7356322,4.2 C16.7356322,1.88040405 18.6160362,4.26102863e-16 20.9356322,0 L32.2597701,0 Z M32.2689655,2.4 L20.9264368,2.4 C19.9323242,2.4 19.1264368,3.20588745 19.1264368,4.2 C19.1264368,5.19411255 19.9323242,6 20.9264368,6 L20.9264368,6 L32.2689655,6 C33.2630781,6 34.0689655,5.19411255 34.0689655,4.2 C34.0689655,3.20588745 33.2630781,2.4 32.2689655,2.4 L32.2689655,2.4 Z"
            fill="currentColor"
          />
          <rect
            id="Rectangle"
            fill="currentColor"
            x="21.5172414"
            y="7.8"
            width="2.3908046"
            height="4.8"
          />
          <rect
            id="Rectangle-Copy-8"
            fill="currentColor"
            x="29.2873563"
            y="7.8"
            width="2.3908046"
            height="4.8"
          />
        </g>
      </g>
    </g>
  </svg>
</template>
