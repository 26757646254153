<template>
  <svg
    width="20px"
    height="20px"
    viewBox="0 0 20 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill-rule="evenodd">
      <g id="broadcast-list" transform="translate(-814.000000, -600.000000)">
        <g
          id="contact-circle-copy-5-pause-circle"
          transform="translate(814.000000, 600.000000)"
        >
          <g id="Group">
            <circle id="Combined-Shape" cx="10" cy="10" r="10" />
          </g>
          <g
            id="Group-2"
            transform="translate(6.000000, 6.000000)"
            fill="#181E24"
          >
            <rect id="Rectangle" x="0" y="0" width="3" height="8" rx="1.5" />
            <rect
              id="Rectangle-Copy"
              x="5"
              y="0"
              width="3"
              height="8"
              rx="1.5"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
