import axios from 'axios';

export default {
  getImage(url) {
    return new Promise((resolve, reject) => {
      axios
        .get(url, { responseType: 'blob' })
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(
            'An error has occured when fetching: ' +
              url +
              ' Error message: ' +
              error,
          );
        });
    });
  },
};
