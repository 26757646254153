import { Permission } from '@/helpers/constants';

const LocalSelectionView = () =>
  import('@/views/localSelection/LocalSelectionView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const localSelectionRoutes = [
  {
    path: '/localselections',
    name: 'LocalSelectionView',
    components: { default: LocalSelectionView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [
          {
            name: Permission.Components.Broadcast,
            action: Permission.ValidPermissions.View,
          },
          {
            name: Permission.Components.LocalSelections,
            action: Permission.ValidPermissions.View,
            isClient: true,
          },
        ],
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.localSelections'),
          },
        ];
      },
    },
  },
];
export default localSelectionRoutes;
