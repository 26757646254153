<template>
  <div class="va-field va-colorpicker">
    <div class="display-picker" @click="editMode = !editMode">
      <div class="colorpicker-display checker">
        <div v-if="!data[fieldModel.fieldName]" class="empty" />
        <div
          v-if="data[fieldModel.fieldName]"
          class="selected-color"
          :style="'background-color:' + data[fieldModel.fieldName]"
        />
      </div>
      <div class="colorpicker-label">
        {{ computedLabel }}
      </div>
    </div>
    <div v-if="editMode" class="colorpicker" :class="colorSelector">
      <div class="swatches">
        <div class="swatch" @click="clearColor" />
        <div
          v-for="color in computedColors"
          :key="color"
          class="checker swatch"
          @click="selectColor(color)"
        >
          <div class="swatch-color" :style="'background-color:' + color" />
        </div>
      </div>
      <div v-if="fieldModel.customColor" class="advanced-selector">
        <span @click="colorSelector = !colorSelector">{{
          $translate('formFields.vaColorPicker.selectCustomColor')
        }}</span>
        <div v-if="colorSelector" class="color-selector">
          <div class="example-color">
            <div class="swatch checker">
              <div
                v-if="colorSelectorColor"
                class="swatch-color"
                :style="'background-color:' + colorSelectorColor"
              />
            </div>
          </div>
          <div class="selector">
            <color-selector
              ref="picker"
              :default-value="data[fieldModel.fieldName]"
              @colorchange="selectorColor"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      colors: [
        'rgba(255, 255, 255, 1)',
        'rgba(192,192,192,1)',
        'rgba(0,0,0,1)',
        'rgba(255, 0, 0, 1)',
        'rgba(128,0,0,1)',
        'rgba(255,255,0,1)',
        'rgba(128,128,0,1)',
        'rgba(0,255,0,1)',
        'rgba(0, 128, 0, 1)',
        'rgba(0,255,255,1)',
        'rgba(0,128,128,1)',
        'rgba(0,0,255,1)',
        'rgba(0,0,128,1)',
        'rgba(255,0,255,1)',
        'rgba(128,0,128,1)',
      ],
      colorSelector: false,
      colorSelectorColor: '',
      editMode: false,
      debounce: '',
    };
  },
  computed: {
    computedColors: function () {
      if (
        this.fieldModel &&
        this.fieldModel.swatches &&
        Array.isArray(this.fieldModel.swatches)
      ) {
        return this.fieldModel.swatches;
      }

      return this.colors;
    },
    computedLabel: function () {
      if (
        this.fieldModel &&
        typeof this.fieldModel.label === 'object' &&
        this.fieldModel.label !== null
      ) {
        if (this.fieldModel.label[this.$getUserlanguage()]) {
          return this.fieldModel.label[this.$getUserlanguage()];
        }
        return this.fieldModel.label['en_US'];
      } else {
        return this.fieldModel.label;
      }
    },
  },
  mounted: function () {
    this.$nextTick(() => {
      document.addEventListener('mousedown', this.click);
    });
  },
  methods: {
    selectColor: function (e) {
      if (this.$refs.picker) {
        if (e[0] == '#') {
          this.$refs.picker.hexInput(e);
        } else {
          this.$refs.picker.hexInput(this.$refs.picker.rgbatoHex(e));
        }
      } else {
        this.selectorColor(e);
      }
    },
    selectorColor: function (e) {
      this.colorSelectorColor = e;
      clearTimeout(this.debounce);
      this.debounce = setTimeout(() => {
        if (e != this.data[this.fieldModel.fieldName]) {
          // TODO Fix no-mutating-props issue
          // eslint-disable-next-line vue/no-mutating-props
          this.data[this.fieldModel.fieldName] = e;
          this.$emit('updatedData');
        }
      }, 100);
    },
    clearColor() {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = '';
      this.$emit('updatedData');
    },
    click: function (e) {
      let el = this.$el;
      let target = e.target;
      if (el !== target && !el.contains(target)) {
        this.colorSelector = false;
        this.editMode = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.va-colorpicker {
  display: block;
  white-space: nowrap;
  text-align: left;
  -webkit-touch-callout: none;
  user-select: none;

  div {
    vertical-align: middle;
  }

  .select-color-btn {
    cursor: pointer;
    margin-top: 0.6rem;
    position: relative;
    display: inline-block;
    border: 0;
    border-radius: 19.5px;
    background-color: #5edc88;
    height: 1rem;
    width: 4rem;
    vertical-align: middle;
    line-height: 1rem;
  }

  .select-color-btn.cancel {
    background-color: #285d39;
  }

  .color-selector {
    padding: 0.1rem;
    margin-top: 0.2rem;
    text-align: center;
  }

  .checker {
    background-color: #fafafa;
    background-image: linear-gradient(
        45deg,
        black 25%,
        transparent 25%,
        transparent 75%,
        black 75%,
        black
      ),
      linear-gradient(
        45deg,
        black 25%,
        transparent 25%,
        transparent 75%,
        black 75%,
        black
      );
    background-size: 20px 20px;
    background-position:
      0 0,
      30px 30px;
  }

  .colorpicker::before {
    position: absolute;
    top: -8px;
    left: 4px;
    content: '';
    width: 0;
    height: 0;
    border-left: 6px dashed transparent;
    border-right: 6px dashed transparent;
    border-bottom: 9px solid #3f3f44;
  }

  .colorpicker {
    border-radius: 4px;
    margin-top: 10px;
    display: block;
    background: #3f3f44;
    position: absolute;
    z-index: 4;
    white-space: default;
    font-size: 0.5rem;
    color: #aaa;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);

    .swatch {
      cursor: pointer;
      position: relative;
      margin: 0.2rem;
      display: inline-block;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
    }

    .example-color {
      position: absolute;
      display: inline-block;
      top: 0.1rem;
      right: 0;
    }

    .selector {
      margin-top: 1rem;
      display: inline-block;
      width: 100%;
      height: 14rem;
    }

    .swatch:empty {
      background-color: #fafafa;
      position: relative;
    }

    .swatch:empty::before {
      position: absolute;
      content: '';
      left: 0;
      top: calc(50% - 2px);
      right: 0;
      width: 100%;
      border-top: 4px solid;
      border-color: red;
      transform: rotate(-45deg);
    }

    .swatch-color {
      width: 110%;
      height: 110%;
      border-radius: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      top: 50%;
      left: 50%;
    }

    .advanced-selector {
      font-size: 0.8rem;
      position: relative;
      white-space: normal;
      margin: 0.5rem;
      padding: 0.5rem;
      border-radius: 3px;
      cursor: pointer;

      > span {
        margin-right: 2rem;
      }
    }

    .swatches {
      font-size: 0;
      white-space: normal;
      max-width: 11.2rem;
      border-bottom: 1px solid rgba(136, 136, 136, 0.2);
      padding: 0.5rem 0.25rem;
      display: flex;
      flex-flow: row wrap;

      &:last-child {
        border-bottom: none;
      }

      .color {
        margin: 0 0.1rem;
        display: inline-block;
        border-radius: 50%;
        width: 1rem;
        height: 1rem;
      }
    }
  }

  .display-picker {
    cursor: pointer;
  }

  .colorpicker-label {
    display: inline-block;
    font-size: 0.8rem;
    color: $color-text-secondary;
  }

  .colorpicker-display {
    display: inline-block;
    border-radius: 50%;
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;

    div.selected-color {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }

    div.empty {
      position: relative;
      border-radius: 50%;
      width: 100%;
      height: 100%;
      background: #fafafa;
    }

    div.empty::before {
      position: absolute;
      content: '';
      left: 0;
      top: calc(50% - 2px);
      right: 0;
      width: 100%;
      border-top: 4px solid;
      border-color: red;
      transform: rotate(-45deg);
    }
  }
}
</style>
