const PlayerView = () => import('@/views/players/PlayerView.vue');
const PlaylistView = () => import('@/views/players/playlist/PlaylistView.vue');
const ForecastView = () => import('@/views/players/forecast/ForecastView.vue');
const RemoteControlView = () =>
  import('@/views/players/remoteControl/RemoteControlView.vue');
const LiveView = () => import('@/views/players/live/LiveView.vue');
const SideBar = () => import('@/components/sideBar/SideBar.vue');

const playerRoutes = [
  {
    path: '/screens',
    name: 'Screens',
    components: { default: PlayerView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Player', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ translate }) => {
        return [{ name: translate('sidebar.screens') }];
      },
    },
  },
  {
    path: '/screens/:playerId/:playerName/playlist',
    name: 'Playlist',
    components: { default: PlaylistView, 'side-bar': SideBar },
    props: { default: true },
    meta: {
      permission: {
        resources: [{ name: 'Player', action: 'ViewOnly' }],
        config: {
          noAccessRedirect: 'Screens',
        },
      },
      breadcrumbs: ({ route, translate }) => {
        return [
          {
            name: translate('sidebar.screens'),
            route: '/screens',
          },
          { name: route.params.playerName },
        ];
      },
    },
  },
  {
    path: '/screens/forecast/:filterCode',
    name: 'ForecastView',
    components: { default: ForecastView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'Player', action: 'ViewOnly' }],
        config: {
          noAccessRedirect: 'Screens',
        },
      },
      breadcrumbs: ({ translate }) => {
        return [
          {
            name: translate('sidebar.screens'),
            route: '/screens',
          },
          { name: translate('forecast.title') },
        ];
      },
    },
  },
  {
    path: '/screens/:playerId/:playerName/remote-control',
    name: 'RemoteControlView',
    components: { default: RemoteControlView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'RemoteControlWebPage', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ route, translate }) => {
        return [
          {
            name: translate('sidebar.screens'),
            route: '/screens',
          },
          { name: route.params.playerName },
          { name: translate('remoteControl.title') },
        ];
      },
    },
  },
  {
    path: '/screens/:playerId/:playerName/live-view',
    name: 'LiveView',
    components: { default: LiveView, 'side-bar': SideBar },
    meta: {
      permission: {
        resources: [{ name: 'RemoteControlLiveView', action: 'ViewOnly' }],
      },
      breadcrumbs: ({ route, translate }) => {
        return [
          {
            name: translate('sidebar.screens'),
            route: '/screens',
          },
          { name: route.params.playerName },
          { name: translate('liveView.title') },
        ];
      },
    },
  },
];
export default playerRoutes;
