<template>
  <svg
    width="18px"
    height="4px"
    viewBox="0 0 18 4"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icons-sp"
        transform="translate(-71.000000, -851.000000)"
        fill="currentColor"
      >
        <g id="Group-Copy-11" transform="translate(50.000000, 823.000000)">
          <g id="option" transform="translate(21.000000, 28.000000)">
            <path
              id="Path"
              d="M2,-8.8817842e-16 C2.55757855,-8.8817842e-16 3.03030109,0.193937455 3.41818182,0.581818182 C3.80606255,0.969698909 4,1.44242145 4,2 C4,2.533336 3.80606255,2.999998 3.41818182,3.4 C3.03030109,3.800002 2.55757855,4 2,4 C1.44242145,4 0.969698909,3.800002 0.581818182,3.4 C0.193937455,2.999998 0,2.533336 0,2 C0,1.44242145 0.193937455,0.969698909 0.581818182,0.581818182 C0.969698909,0.193937455 1.44242145,-8.8817842e-16 2,-8.8817842e-16 L2,-8.8817842e-16 Z"
            />
            <path
              id="Path"
              d="M9,-8.8817842e-16 C9.55757855,-8.8817842e-16 10.0303011,0.193937455 10.4181818,0.581818182 C10.8060625,0.969698909 11,1.44242145 11,2 C11,2.533336 10.800002,2.999998 10.4,3.4 C9.999998,3.800002 9.533336,4 9,4 C8.466664,4 8.000002,3.800002 7.6,3.4 C7.199998,2.999998 7,2.533336 7,2 C7,1.44242145 7.19393745,0.969698909 7.58181818,0.581818182 C7.96969891,0.193937455 8.44242145,-8.8817842e-16 9,-8.8817842e-16 L9,-8.8817842e-16 Z"
            />
            <path
              id="Path"
              d="M16,0 C16.5575785,0 17.0303011,0.193937455 17.4181818,0.581818182 C17.8060625,0.969698909 18,1.44242145 18,2 C18,2.533336 17.8060625,2.999998 17.4181818,3.4 C17.0303011,3.800002 16.5575785,4 16,4 C15.4424215,4 14.9696989,3.800002 14.5818182,3.4 C14.1939375,2.999998 14,2.533336 14,2 C14,1.44242145 14.1939375,0.969698909 14.5818182,0.581818182 C14.9696989,0.193937455 15.4424215,0 16,0 L16,0 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
