<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.16797 3.33268C3.94695 3.33268 3.73499 3.42048 3.57871 3.57676C3.42243 3.73304 3.33464 3.945 3.33464 4.16602V15.8327C3.33464 16.0537 3.42243 16.2657 3.57871 16.4219C3.73499 16.5782 3.94696 16.666 4.16797 16.666H7.5013C7.96154 16.666 8.33464 17.0391 8.33464 17.4993C8.33464 17.9596 7.96154 18.3327 7.5013 18.3327H4.16797C3.50493 18.3327 2.86904 18.0693 2.4002 17.6004C1.93136 17.1316 1.66797 16.4957 1.66797 15.8327V4.16602C1.66797 3.50297 1.93136 2.86709 2.4002 2.39825C2.86904 1.92941 3.50493 1.66602 4.16797 1.66602H7.5013C7.96154 1.66602 8.33464 2.03911 8.33464 2.49935C8.33464 2.95959 7.96154 3.33268 7.5013 3.33268H4.16797Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M12.7441 5.24408C13.0695 4.91864 13.5972 4.91864 13.9226 5.24408L18.0893 9.41074C18.4147 9.73618 18.4147 10.2638 18.0893 10.5893L13.9226 14.7559C13.5972 15.0814 13.0695 15.0814 12.7441 14.7559C12.4186 14.4305 12.4186 13.9028 12.7441 13.5774L16.3215 10L12.7441 6.42259C12.4186 6.09715 12.4186 5.56951 12.7441 5.24408Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.66797 9.99935C6.66797 9.53911 7.04106 9.16602 7.5013 9.16602H17.5013C17.9615 9.16602 18.3346 9.53911 18.3346 9.99935C18.3346 10.4596 17.9615 10.8327 17.5013 10.8327H7.5013C7.04106 10.8327 6.66797 10.4596 6.66797 9.99935Z"
      fill="currentColor"
    />
  </svg>
</template>
