<template>
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0.833008 1.66634C0.833008 1.2061 1.2061 0.833008 1.66634 0.833008H9.99967C10.2207 0.833008 10.4326 0.920805 10.5889 1.07709L17.749 8.23717C18.2146 8.70558 18.476 9.33921 18.476 9.99967C18.476 10.6601 18.2146 11.2938 17.749 11.7622L17.7473 11.7639L11.7726 17.7386C11.5404 17.971 11.2647 18.1554 10.9612 18.2813C10.6577 18.4071 10.3324 18.4718 10.0038 18.4718C9.6753 18.4718 9.34999 18.4071 9.04649 18.2813C8.74328 18.1556 8.46779 17.9714 8.23575 17.7393C8.23553 17.739 8.23597 17.7395 8.23575 17.7393L1.07743 10.5893C0.920938 10.433 0.833008 10.2209 0.833008 9.99967V1.66634ZM2.49967 2.49967V9.65421L9.41359 16.5601C9.49098 16.6376 9.58356 16.6997 9.68472 16.7416C9.78588 16.7836 9.89432 16.8052 10.0038 16.8052C10.1134 16.8052 10.2218 16.7836 10.323 16.7416C10.4241 16.6997 10.516 16.6382 10.5934 16.5607L16.567 10.5872C16.5673 10.5869 16.5675 10.5866 16.5678 10.5864C16.7225 10.4303 16.8093 10.2195 16.8093 9.99967C16.8093 9.77989 16.7225 9.56902 16.5678 9.41296C16.5675 9.4127 16.5673 9.41244 16.567 9.41217L9.6545 2.49967H2.49967Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M5 5.83333C5 5.3731 5.3731 5 5.83333 5H5.84167C6.3019 5 6.675 5.3731 6.675 5.83333C6.675 6.29357 6.3019 6.66667 5.84167 6.66667H5.83333C5.3731 6.66667 5 6.29357 5 5.83333Z"
      fill="currentColor"
    />
  </svg>
</template>
