const SideBar = () => import('@/components/sideBar/SideBar.vue');
const SettingsView = () => import('@/views/settings/SettingsView.vue');
const VariablesView = () =>
  import('@/views/settings/variables/VariablesView.vue');
const LocationsView = () =>
  import('@/views/settings/locations/LocationsView.vue');
const AccountView = () => import('@/views/settings/account/AccountView.vue');

const settingsRoutes = [
  {
    path: '/settings/',
    name: 'SettingsView',
    components: { default: SettingsView, 'side-bar': SideBar },
    children: [
      {
        path: 'variables',
        name: 'AccountSettingsVariablesView',
        component: VariablesView,
        meta: {
          permission: {
            resources: [{ name: 'Variable', action: 'ViewOnly' }],
          },
          breadcrumbs: ({ translate }) => {
            return [{ name: translate('sidebar.settings') }];
          },
        },
      },
      {
        path: 'locations',
        name: 'LocationSettingsView',
        component: LocationsView,
        meta: {
          permission: {
            resources: [{ name: 'Setting', action: 'ViewOnly' }],
          },
          breadcrumbs: ({ translate }) => {
            return [{ name: translate('sidebar.settings') }];
          },
        },
      },
      {
        path: 'account',
        name: 'AccountSettingsView',
        component: AccountView,
        meta: {
          permission: {
            resources: [{ name: 'Setting', action: 'ViewOnly' }],
          },
          breadcrumbs: ({ translate }) => {
            return [{ name: translate('sidebar.settings') }];
          },
        },
      },
    ],
  },
];
export default settingsRoutes;
