import axios from 'axios';
import Env from '@/utils/env';
import { formatDate } from '@/helpers/dateTimeHelper';

const getBroadcast = (broadcastCode) => {
  const now = formatDate(new Date());

  return new Promise((resolve, reject) => {
    if (!broadcastCode) {
      resolve();
    }

    axios
      .get(
        Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
          '/api/broadcasts/' +
          broadcastCode,
        { params: { now: now } },
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const createBroadcast = (broadcastType, payload) => {
  return new Promise((resolve, reject) => {
    if (!broadcastType) {
      reject('A broadcastType is required.');
    }
    if (!payload) {
      reject('A payload is required.');
    }

    axios
      .post(
        Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
          '/api/broadcasts/' +
          broadcastType,
        payload,
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject('An error has occurred when fetching your broadcasts');
      });
  });
};

const updateBroadcast = (broadcastCode, payload) => {
  return new Promise((resolve, reject) => {
    if (!broadcastCode) {
      reject();
    }

    axios
      .put(
        Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
          '/api/broadcasts/' +
          broadcastCode,
        payload,
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject('An error has occurred when updating your broadcast');
      });
  });
};

const deleteBroadcast = (broadcastCode) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(
        Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
          '/api/broadcasts/' +
          broadcastCode,
        {},
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject('An error has occurred when deleting your broadcast.');
      });
  });
};

const checkPeriodStatus = (periods) => {
  return new Promise((resolve, reject) => {
    periods.now = formatDate(new Date());

    axios
      .post(
        Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
          '/api/broadcasts/periodstatus',
        periods,
      )
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        console.error(error);
        reject('An error has occurred when checking period status.');
      });
  });
};

export default {
  getBroadcast,
  createBroadcast,
  updateBroadcast,
  deleteBroadcast,
  checkPeriodStatus,
};
