<template>
  <div v-if="parentField" class="va-field va-item-media-picker">
    <label class="title-label">{{ title }}</label>
    <div class="item-media-fields">
      <div v-if="fieldModel.multiselect" class="item-media-checkbox-container">
        <div
          v-for="mediaItem in mediaList"
          :key="mediaItem.mediaCode"
          class="item-media-checkbox"
        >
          <va-img
            :src="getThumbnailUrl(mediaItem)"
            :media-type="'none'"
            class="item-media-image"
            :class="{
              selected: checkboxSelectedMedia.includes(mediaItem.mediaCode),
            }"
            @click="onCheckboxImageClicked(mediaItem)"
          />
          <div class="item-select-container">
            <va-checkbox
              :value="mediaItem.mediaCode"
              :model-value="checkboxSelectedMedia"
              class="item-select"
              @update:modelValue="
                onCheckboxSelectionUpdated($event, mediaItem.mediaCode)
              "
            />
          </div>
        </div>
      </div>
      <div
        v-for="mediaItem in mediaList"
        v-else
        :key="mediaItem.mediaCode"
        class="item-media-radio"
      >
        <va-img
          :src="getThumbnailUrl(mediaItem)"
          :media-type="'none'"
          class="item-media-image"
          :class="{ selected: radioSelectedMedia == mediaItem.mediaCode }"
          @click="onRadioImageClicked(mediaItem)"
        />
        <div class="item-select-container">
          <va-radio
            v-model="radioSelectedMedia"
            :value="mediaItem.mediaCode"
            class="item-select"
            @update:model-value="updateData(mediaItem.mediaCode)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VaCheckbox from '@/components/framework/va-checkbox.vue';
import VaRadio from '@/components/framework/va-radio.vue';
import VaImg from '@/components/framework/va-img.vue';
import { getFact } from '@/api/factsApi';
import { withAsync } from '@/helpers/withAsync';
import { getTranslatedLabel } from '@/helpers/form-helper';

export default {
  components: {
    VaCheckbox,
    VaRadio,
    VaImg,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
    parentData: {
      type: Object,
      default: null,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      radioSelectedMedia: '',
      checkboxSelectedMedia: [],
      useFormData: true,
      item: null,
    };
  },
  computed: {
    parentField() {
      return (
        this.parentData?.[this.fieldModel.parentField] ||
        this.data?.[this.fieldModel.parentField] ||
        ''
      );
    },
    title() {
      return getTranslatedLabel(this.fieldModel.label, this.$getUserlanguage());
    },
    mediaList() {
      return (
        this.item?.factAttributes
          ?.filter(
            (attribute) => attribute.Id === this.fieldModel.attributeCode,
          )
          .map((m) => ({
            isDefault: m.IsDefault,
            mediaCode: m.MediaRef?.MediaCode,
          })) || []
      );
    },
  },
  watch: {
    parentField: {
      immediate: true,
      handler(newVal) {
        this.getItemData(newVal);
      },
    },
  },
  mounted() {
    if (this.data && this.fieldModel && this.fieldModel.fieldName) {
      if (this.fieldModel.multiselect) {
        if (Array.isArray(this.data[this.fieldModel.fieldName])) {
          this.checkboxSelectedMedia = this.data[this.fieldModel.fieldName].map(
            (m) => m.media,
          );
        }
      } else {
        this.radioSelectedMedia = this.data[this.fieldModel.fieldName]?.media;
      }
    }
  },
  methods: {
    getThumbnailUrl(mediaItem) {
      return `${this.$getEnv('VITE_API_URL_SIGNAGE_BACKEND')}/api/media/${
        mediaItem.mediaCode
      }/thumbnail`;
    },
    onCheckboxSelectionUpdated(selected, mediaCode) {
      if (selected.includes(mediaCode)) {
        this.checkboxSelectedMedia.push(mediaCode);
      } else {
        let index = this.checkboxSelectedMedia.indexOf(mediaCode);
        if (index > -1) {
          this.checkboxSelectedMedia.splice(index, 1);
        }
      }
      this.updateData(this.checkboxSelectedMedia);
    },
    onCheckboxImageClicked(mediaItem) {
      if (this.checkboxSelectedMedia.includes(mediaItem.mediaCode)) {
        let index = this.checkboxSelectedMedia.indexOf(mediaItem.mediaCode);
        if (index > -1) {
          this.checkboxSelectedMedia.splice(index, 1);
        }
      } else {
        this.checkboxSelectedMedia.push(mediaItem.mediaCode);
      }
      this.updateData(this.checkboxSelectedMedia);
    },
    onRadioImageClicked(mediaItem) {
      this.radioSelectedMedia = mediaItem.mediaCode;
      this.updateData(this.radioSelectedMedia);
    },
    updateData(value) {
      if (Array.isArray(value)) {
        // TODO Fix no-mutating-props issue
        // eslint-disable-next-line vue/no-mutating-props
        this.data[this.fieldModel.fieldName] = value.map((v) => {
          return {
            media: v,
            type: null,
          };
        });
      } else {
        // TODO Fix no-mutating-props issue
        // eslint-disable-next-line vue/no-mutating-props
        this.data[this.fieldModel.fieldName] = {
          media: value,
          type: null,
        };
      }
      this.$emit('updatedData');
    },
    async getItemData(factCode) {
      if (!factCode) {
        this.item = null;
        this.radioSelectedMedia = '';
        this.checkboxSelectedMedia = [];
        // TODO Fix no-mutating-props issue
        // eslint-disable-next-line vue/no-mutating-props
        this.data[this.fieldModel.fieldName] = '';
        this.$emit('updatedData');
        return;
      }
      const { response } = await withAsync(getFact, 'Item', factCode);
      this.item = response.data.fact;
      this.setDefaultMedia();
    },
    setDefaultMedia() {
      if (!this.fieldModel.multiselect) {
        let defaultMedia = this.mediaList.find(
          (media) => media.isDefault,
        )?.mediaCode;
        let formData = this.useFormData ? this.radioSelectedMedia : null; // First time we use form data (if avaliable)
        this.useFormData = false;
        this.radioSelectedMedia =
          formData || defaultMedia || this.mediaList[0]?.mediaCode;
        this.updateData(this.radioSelectedMedia);
      }
    },
  },
};
</script>

<style lang="scss">
.va-item-media-picker {
  .item-media-fields {
    display: inline-flex;
    flex-wrap: wrap;

    .item-media-checkbox-container {
      display: flex;
      flex-wrap: wrap;
    }

    .item-media-checkbox {
      width: 80px;
      padding-right: 25px;

      .item-select {
        width: 16px;
        height: 16px;
      }
    }

    .item-media-radio {
      width: 80px;
      padding-right: 25px;

      .item-select {
        width: 20px;
        height: 20px;
      }
    }

    .item-media-image {
      width: 80px;
      height: 80px;
      background-color: #181b25;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      :deep(.va-img) {
        object-fit: cover;
      }
    }

    .item-select-container {
      display: flex;
      justify-content: center;
      padding: 5px 0 10px;

      .item-select {
        padding: 0;
        margin: 0;
      }
    }
  }
}
</style>
