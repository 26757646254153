<template>
  <div class="va-field va-item-data-picker">
    <label v-if="!getItemStatusIdle" class="title-label">{{ title }}</label>
    <va-content-loader
      v-if="getItemStatusPending"
      animation="shine"
      class="loader"
    />
    <va-dropdown-search
      v-if="getItemStatusSuccess"
      ref="va-dropdown-search"
      :placeholder="placeholder"
      :dropdown-options="options"
      :single-select="!fieldModel.multiselect"
      :initial-values="value"
      @selection_updated="onSelectionUpdated"
    />
    <div v-if="getItemStatusError" class="error" data-testid="error-message">
      <span>{{ $translate('generic.errorMessage') }}</span>
      <SvgIcon
        icon="refresh"
        class="refresh-icon"
        @click="getItemData(parentField)"
      />
    </div>
  </div>
</template>

<script>
import formHelper from '@/helpers/form-helper';
import VaDropdownSearch from '@/components/framework/va-dropdown-search.vue';
import VaContentLoader from '@/components/framework/va-content-loader.vue';
import { getFact } from '@/api/factsApi';
import { apiStatus } from '@/api/constants/apiStatus';
import { withAsync } from '@/helpers/withAsync';
import { apiStatusComputedFactory } from '@/api/helpers/apiStatusComputedFactory';
import SvgIcon from '@/components/icons/SvgIcon.vue';

const { IDLE, PENDING, SUCCESS, ERROR } = apiStatus;

export default {
  components: {
    VaDropdownSearch,
    VaContentLoader,
    SvgIcon,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    fieldModel: {
      type: Object,
      required: true,
    },
    parentData: {
      type: Object,
      default: null,
    },
  },
  emits: ['updatedData'],
  data() {
    return {
      item: null,
      getItemStatus: IDLE,
      useFormData: true,
    };
  },
  computed: {
    ...apiStatusComputedFactory('getItemStatus'),
    parentField() {
      return (
        this.parentData?.[this.fieldModel.parentField] ||
        this.data?.[this.fieldModel.parentField] ||
        ''
      );
    },
    title() {
      return formHelper.getTranslatedLabel(
        this.fieldModel.label,
        this.$getUserlanguage(),
      );
    },
    placeholder() {
      return (
        this.fieldModel.placeholder || this.$translate('generic.select') + '...'
      );
    },
    options() {
      let options = [];
      this.item?.factAttributes
        ?.filter((attribute) => attribute.Id === this.fieldModel.attributeCode)
        .forEach((attribute) => {
          let value = JSON.parse(attribute.Value);
          let displayName = [];
          Object.keys(value).forEach((key) => {
            if (key !== 'id') {
              displayName.push(value[key]);
            }
          });
          value.displayName = displayName.join(' - ');
          options.push(value);
        });
      return options;
    },
    value() {
      return this.data?.[this.fieldModel.fieldName] || [];
    },
  },
  watch: {
    parentField: {
      immediate: true,
      handler(newVal) {
        this.getItemData(newVal);
      },
    },
  },
  methods: {
    onSelectionUpdated(items = []) {
      // TODO Fix no-mutating-props issue
      // eslint-disable-next-line vue/no-mutating-props
      this.data[this.fieldModel.fieldName] = items.map((item) => item.id);
      this.$emit('updatedData');
    },
    async getItemData(factCode) {
      if (!factCode) {
        this.item = null;
        // TODO Fix no-mutating-props issue
        // eslint-disable-next-line vue/no-mutating-props
        this.data[this.fieldModel.fieldName] = [];
        this.getItemStatus = IDLE;
        this.$emit('updatedData');
        return;
      }
      if (!this.useFormData) {
        // TODO Fix no-mutating-props issue
        // eslint-disable-next-line vue/no-mutating-props
        this.data[this.fieldModel.fieldName] = [];
        this.$emit('updatedData');
      }
      this.getItemStatus = PENDING;
      const { response } = await withAsync(getFact, 'Item', factCode);
      if (response?.data?.fact) {
        this.item = response.data.fact;
        this.getItemStatus = SUCCESS;
        // Only use form data first call when loading the sign
        this.useFormData = false;
      } else {
        this.getItemStatus = ERROR;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.va-item-data-picker {
  .loader {
    height: 40px;
  }

  .error {
    color: #ff5c7e;
    display: flex;
    align-items: center;
    gap: 0 0.5rem;

    .refresh-icon {
      color: #bababa;
      cursor: pointer;

      &:hover {
        color: #fafafa;
      }
    }
  }
}
</style>
