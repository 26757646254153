<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icons-sp"
        transform="translate(-980.000000, -368.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <g id="Group-Copy-17" transform="translate(958.000000, 346.000000)">
          <g id="search" transform="translate(22.000000, 22.000000)">
            <path
              d="M6.5,1.59872116e-14 C10.0898509,1.59872116e-14 13,2.91014913 13,6.5 C13,7.63198989 12.710634,8.69639472 12.2018363,9.62328017 L15.7605479,14.3463344 C16.1076806,14.6934671 16.0725048,15.2914562 15.6819805,15.6819805 C15.2914562,16.0725048 14.6934671,16.1076806 14.3463344,15.7605479 L14.3463344,15.7605479 L9.62328017,12.2018363 C8.69639472,12.710634 7.63198989,13 6.5,13 C2.91014913,13 8.79296636e-14,10.0898509 8.79296636e-14,6.5 C8.79296636e-14,2.91014913 2.91014913,1.59872116e-14 6.5,1.59872116e-14 Z M6.5,2 C4.01471863,2 2,4.01471863 2,6.5 C2,8.98528137 4.01471863,11 6.5,11 C8.98528137,11 11,8.98528137 11,6.5 C11,4.01471863 8.98528137,2 6.5,2 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
