const SET_SIDEBAR_STATE = 'SET_SIDEBAR_STATE';
const SET_DRAWER_WIDTH = 'SET_DRAWER_WIDTH';
// state
const state = {
  isSidebarCollapsed: false,
  sideDrawerWidth: 320,
};

// getters
const getters = {
  isSidebarCollapsed: (state) => state.isSidebarCollapsed,
  sideDrawerWidth: (state) => state.sideDrawerWidth,
};

// actions
const actions = {
  toggleSidebarState({ commit }) {
    commit(SET_SIDEBAR_STATE);
  },
  setSideDrawerWidthState({ commit }, { sideDrawerWidth }) {
    commit(SET_DRAWER_WIDTH, { sideDrawerWidth });
  },
};

// mutations
const mutations = {
  [SET_SIDEBAR_STATE](state) {
    state.isSidebarCollapsed = !state.isSidebarCollapsed;
  },
  [SET_DRAWER_WIDTH](state, { sideDrawerWidth }) {
    state.sideDrawerWidth = sideDrawerWidth;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
