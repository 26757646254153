<template>
  <div class="va-search">
    <div class="search-bar">
      <div
        v-if="filterOption"
        data-testid="filter-toggler"
        class="toggle-filter"
        :class="{ active: showFilter }"
        @click="toggleShowFilter"
      >
        <span>Filter</span>
        <svg-icon icon="filterIcon" class="filter-icon" />
      </div>
      <input
        data-testid="search-area-input"
        class="search-area"
        :value="modelValue"
        :placeholder="$translate('generic.search')"
        @keyup="onValueChanged($event.target.value)"
      />
      <svg-icon
        v-if="modelValue"
        class="close-icon"
        icon="close"
        @click="onValueChanged('')"
      />
      <svg-icon v-else class="search-icon" icon="search" />
    </div>
  </div>
</template>

<script>
import SvgIcon from '@/components/icons/SvgIcon.vue';
export default {
  components: {
    SvgIcon,
  },
  props: {
    modelValue: {
      type: String,
      default: '',
    },
    startFilterOpen: {
      type: Boolean,
    },
    filterOption: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'keyup', 'OPEN_FILTER', 'CLOSE_FILTER'],
  data() {
    return {
      showFilter: false,
    };
  },
  watch: {
    startFilterOpen() {
      if (this.startFilterOpen) {
        this.showFilter = true;
        this.$emit('OPEN_FILTER');
      }
    },
  },
  methods: {
    toggleShowFilter() {
      if (this.showFilter) {
        this.$emit('CLOSE_FILTER');
      } else this.$emit('OPEN_FILTER');
      this.showFilter = !this.showFilter;
    },
    onValueChanged(value) {
      this.$emit('update:modelValue', value);
      this.$emit('keyup');
    },
  },
};
</script>

<style lang="scss" scoped>
.va-search {
  font-family: Roboto, sans-serif;
  font-size: 15px;
  letter-spacing: 0.38px;

  .search-bar {
    width: 270px;
    height: 40px;
    border-radius: 19.5px;
    background-color: #4b4b51;
    display: flex;
    align-items: center;
    color: $color-text-secondary;

    .toggle-filter {
      flex-shrink: 0;
      user-select: none;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      width: 83px;
      height: 38px;
      background-color: #323035;
      border: 1px solid #4b4b51;
      border-radius: 19.5px 0 0 19.5px;
      transition:
        background-color 0.2s ease-out,
        color 0.2s ease-out;

      i,
      span,
      .filter-icon {
        transition-property: all;
        transition-duration: 0.2s;
        padding: 4px;
      }

      &.active,
      &:hover {
        background-color: #252d34;
        color: #fafafa;
      }
    }

    .search-area {
      border: none;
      background: none;
      color: $color-text-secondary;
      padding-left: 10px;
      padding-right: 10px;
      width: 100%;
      height: 100%;
      outline: none;
      font-size: 14px;
      letter-spacing: 0.35px;
      appearance: textfield;

      &:focus {
        outline: none;
      }

      &:disabled {
        color: #666;
      }
    }

    .search-icon,
    .close-icon {
      padding: 0 15px 0 10px;
    }

    .close-icon {
      cursor: pointer;
      font-size: 12px;
    }
  }
}
</style>
