<template>
  <div
    ref="va-popup"
    class="va-popup"
    :class="{ out: out }"
    data-testid="va-popup"
    @click="onClose"
  >
    <div
      ref="va-popup-container"
      class="va-popup-container"
      data-testid="popup-container"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Accepts all types of elements
    referenceElement: {
      type: null,
      required: true,
    },
    direction: {
      type: String,
      default: '',
    },
  },
  emits: ['close'],
  data() {
    return {
      out: false,
    };
  },
  mounted() {
    const referenceElementInfo = this.referenceElement.getBoundingClientRect();
    this.checkDirection();
    this.checkSlotFit();
    this.$refs['va-popup-container'].style.width =
      referenceElementInfo.width + 'px';
    this.$refs['va-popup-container'].style.height =
      referenceElementInfo.height + 'px';
  },
  created() {
    document.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    document.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    onClose(e) {
      if (
        e.target !== this.$refs['va-popup'] &&
        e.target !== this.$refs['va-popup-container']
      ) {
        return;
      }
      this.out = true;

      // Wait 300ms for out animations
      setTimeout(() => {
        this.$emit('close');
      }, 300);
    },
    handleScroll() {
      this.checkDirection();
      this.checkSlotFit();
    },
    checkDirection() {
      const referenceElementInfo =
        this.referenceElement.getBoundingClientRect();

      switch (this.direction) {
        case 'bottom':
          this.$refs['va-popup-container'].style.top =
            referenceElementInfo.top + referenceElementInfo.height + 'px';
          this.$refs['va-popup-container'].style.left =
            referenceElementInfo.left + 'px';
          break;
        case 'right':
          this.$refs['va-popup-container'].style.left =
            referenceElementInfo.left + referenceElementInfo.width + 'px';
          this.$refs['va-popup-container'].style.top =
            referenceElementInfo.top + 'px';
          break;
        case 'top':
          this.$refs['va-popup-container'].style.top =
            referenceElementInfo.top - referenceElementInfo.height * 2 + 'px';
          this.$refs['va-popup-container'].style.left =
            referenceElementInfo.left + 'px';
          break;
        default:
          this.$refs['va-popup-container'].style.top =
            referenceElementInfo.top + 'px';
          this.$refs['va-popup-container'].style.left =
            referenceElementInfo.left + 'px';
      }
    },
    checkSlotFit() {
      const referenceElementInfo =
        this.referenceElement.getBoundingClientRect();
      //The popup-content ref needs to bo on the slot object where va-popup are used. If not, the calculation fils and the popup
      const slotElement = this.$slots
        ?.default()[0]
        ?.context?.$refs['popup-content']?.getBoundingClientRect();

      // Check if element is below bottom part of screen
      if (slotElement?.y + slotElement?.height > window.innerHeight) {
        this.$refs['va-popup-container'].style.top =
          slotElement.y -
          slotElement.height -
          referenceElementInfo.height +
          'px';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@use '../../styles/animations';

.va-popup {
  animation: down_in 0.2s ease-out forwards;
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 10000000000;
  backface-visibility: hidden;

  &.out {
    animation: up_out 0.2s ease-out forwards;
  }

  .va-popup-container {
    position: absolute;
  }
}
</style>
