import axios from 'axios';
import Env from '@/utils/env';

export default {
  getMediaFilters(
    searchText,
    maxItemsInResult,
    skipItemsInResult,
    orderBy,
    order,
  ) {
    return new Promise((resolve, reject) => {
      axios
        .get(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/mediafilter', {
          params: {
            SearchText: searchText,
            MaxItemsInResult: maxItemsInResult,
            SkipItemsInResult: skipItemsInResult,
            OrderBy: orderBy,
            Order: order,
          },
        })
        .then((response) => {
          resolve(response.data.MediaFilters);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occurred while fetching your media filters.');
        });
    });
  },

  saveMediaFilter(mediaFilterName, condition) {
    return new Promise((resolve, reject) => {
      axios
        .post(Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') + '/api/mediafilter', {
          MediaFilter: { MediaFilterName: mediaFilterName },
          Filter: { Condition: condition },
        })
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while saving your media filter.');
        });
    });
  },

  getMediaFilter(mediaFilterCode) {
    return new Promise((resolve, reject) => {
      axios
        .get(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/mediafilter/' +
            mediaFilterCode,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while getting your media filter.');
        });
    });
  },

  editMediaFilter(mediaFilterCode, mediaFilterName, condition) {
    return new Promise((resolve, reject) => {
      axios
        .put(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/mediafilter/' +
            mediaFilterCode,
          {
            MediaFilter: { MediaFilterName: mediaFilterName },
            Filter: { Condition: condition },
          },
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while deleting your media filter.');
        });
    });
  },

  deleteMediaFilter(mediaFilterCode) {
    return new Promise((resolve, reject) => {
      axios
        .delete(
          Env.getEnv('VITE_API_URL_SIGNAGE_BACKEND') +
            '/api/mediafilter/' +
            mediaFilterCode,
        )
        .then((response) => {
          resolve(response.data);
        })
        .catch((error) => {
          console.error(error);
          reject('An error has occured while deleting your media filter.');
        });
    });
  },
};
