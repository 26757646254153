const cleanUpImageName = (inputName) => {
  if (inputName) {
    return inputName
      .replace('|', '')
      .replace('&', '')
      .replace('¤', '')
      .replace('#', '')
      .replace('!', '')
      .replace('"', '')
      .replace("'", '')
      .replace('/', '')
      .replace('^', '')
      .replace('~', '')
      .replace('$', '')
      .replace('£', '')
      .replace('`', '')
      .replace('£', '')
      .replace('?', '')
      .replace('§', '')
      .replace('½', '')
      .replace('%', '')
      .replace('>', '')
      .replace('<', '')
      .replace(';', '')
      .replace(':', '')
      .replace('\\', '');
  }
};

export default { cleanUpImageName };
