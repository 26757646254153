<template>
  <svg
    width="14px"
    height="8px"
    viewBox="0 0 14 8"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- Generator: sketchtool 58 (101010) - https://sketch.com -->
    <desc>Created with sketchtool.</desc>
    <g
      id="Page-2"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="icons-sp"
        transform="translate(-313.000000, -849.000000)"
        fill="currentColor"
        fill-rule="nonzero"
      >
        <g id="Group-Copy-12" transform="translate(290.000000, 823.000000)">
          <g id="chevron" transform="translate(23.500000, 26.500000)">
            <path
              d="M1.67223062,0.259658861 C1.26334751,-0.111599866 0.630917588,-0.0810992719 0.259658861,0.327783844 C-0.111599866,0.73666696 -0.0810992719,1.36909688 0.327783844,1.74035561 L5.83449771,6.74035561 C6.21612964,7.08687073 6.79863309,7.08651681 7.17984365,6.73953819 L12.6731298,1.73953819 C13.0815615,1.36778287 13.1112935,0.735316352 12.7395382,0.326884684 C12.3677829,-0.081546984 11.7353164,-0.111279039 11.3268847,0.26047628 L6.50589997,4.64854619 L1.67223062,0.259658861 Z"
              transform="translate(6.500007, 3.500004) rotate(-360.000000) translate(-6.500007, -3.500004) "
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>
