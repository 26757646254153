<template>
  <div class="va-popup">
    <div ref="va-popup-toggler" class="va-popup-toggler">
      <slot name="toggler" />
    </div>
    <transition
      name="dropdown-content"
      @enter="setPopupContentStyle"
      @leave="show = false"
    >
      <div
        v-if="active"
        class="va-popup-overlay"
        data-testid="va-popup--overlay"
        @click.self="onBlur"
      >
        <div
          ref="va-popup-content"
          data-testid="va-popup--content"
          :class="['va-popup-content', { show: show }]"
          :style="cssVariables"
        >
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { popupPosition, calculatePopupHeight } from './vaPopupHelper';

export default {
  props: {
    /**
     *  right - popup alines with the right side of the toggler (default is left)
     *  center - center the dropdown vertical (right overwrites center)
     *  up - defaults popup upwards (default downwards)
     */
    opened: {
      type: Boolean,
      default: false,
    },

    right: {
      type: Boolean,
      default: false,
    },
    up: {
      type: Boolean,
      default: false,
    },
    center: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['blur'],
  data() {
    return {
      cssVariables: { '--height': 'auto' },
      show: false,
    };
  },
  computed: {
    active() {
      return this.opened;
    },
  },
  methods: {
    onBlur() {
      this.$emit('blur');
    },
    setPopupContentStyle() {
      if (!this.$refs['va-popup-content']) {
        return;
      }

      const toggler = this.$refs['va-popup-toggler'].getBoundingClientRect();
      const popup = this.$refs['va-popup-content'].getBoundingClientRect();

      this.cssVariables = {
        '--height': calculatePopupHeight(toggler, popup, this.up),
        '--popup-position': popupPosition(
          toggler,
          popup,
          this.right,
          this.center,
          this.up,
        ),
      };
      this.show = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.va-popup {
  height: 100%;

  &-toggler {
    height: 100%;
  }

  &-overlay {
    z-index: 100000000;
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
  }

  &-content {
    opacity: 0;
    position: fixed;
    inset: var(--popup-position);
    transition: opacity 0.2s ease-in-out;
    height: var(--height);
    overflow: hidden auto;
    padding: 10px; // Padding for the box-shadow in the components that are using VaPopup

    &::-webkit-scrollbar {
      background-color: $color-container-primary;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }

    &.show {
      opacity: 1;
    }
  }

  .dropdown-content {
    &-enter {
      opacity: 0;
      transform: translateY(20px);

      &-active {
        transition: all 0.2s ease-in-out;
      }
    }

    &-leave {
      &-to {
        opacity: 0;
        transform: translateY(20px);
      }

      &-active {
        transition: all 0.2s ease-in-out;
      }
    }
  }
}
</style>
