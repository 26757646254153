const SET_FORM_MODEL = 'SET_FORM_MODEL';
const SET_FORM_DATA = 'SET_FORM_DATA';
const ADD_FORM_FACT = 'ADD_FORM_FACT';
const CLEAR_FORM_FACTS = 'CLEAR_FORM_FACTS';

// state
const state = {
  formData: {},
  formModel: {},
  formFacts: [],
};

// getters
const getters = {
  formData: (state) => state.formData.data,
  formModel: (state) => state.formModel,
  formFacts: (state) => state.formFacts,
};

// actions
const actions = {
  setFormModel({ commit }, formModel) {
    commit(SET_FORM_MODEL, formModel.fields);
  },
  setFormData({ commit }, formData) {
    commit(SET_FORM_DATA, formData);
  },
  addFormFact({ commit }, formFact) {
    commit(ADD_FORM_FACT, formFact);
  },
  clearFormFacts({ commit }) {
    commit(CLEAR_FORM_FACTS);
  },
};

// mutations
const mutations = {
  [SET_FORM_MODEL](state, field) {
    state.formModel['fields'] = field;
  },
  [SET_FORM_DATA](state, formData) {
    state.formData['data'] = formData;
  },
  [ADD_FORM_FACT](state, formFact) {
    if (state.formFacts) {
      if (formFact.factType === 'Item') {
        let items = state.formFacts.find((ff) => ff.type === 'Items');
        if (items) {
          let json = JSON.parse(items.json);
          if (
            json.ItemsInStore.find(
              (item) => item.ItemCode === formFact.factCode,
            )
          ) {
            return;
          }
          json.ItemsInStore.push({
            ItemCode: formFact.factCode,
            Attributes: formFact.factAttributes,
          });
          let index = state.formFacts.findIndex((ff) => ff.type === 'Items');
          state.formFacts.splice(index, 1, {
            json: JSON.stringify(json),
            type: 'Items',
          });
        } else {
          let json = {
            ItemsInStore: [
              {
                ItemCode: formFact.factCode,
                Attributes: formFact.factAttributes,
              },
            ],
          };
          state.formFacts.push({
            json: JSON.stringify(json),
            type: 'Items',
          });
        }
      }
    }
  },
  [CLEAR_FORM_FACTS](state) {
    state.formFacts = [];
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
